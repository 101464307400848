import { isEqual } from "lodash";
import React from "react";

import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import ChallengeCampaignEditorForm from "./ChallengeCampaignEditorForm";
import GenericCampaignEditorForm from "./GenericCampaignEditorForm";
import {
  LoadNectarPriceCampaignDefaults,
  NectarPriceCampaignEditorForm,
} from "./NectarPriceCampaignEditorForm";

export default class CampaignEditorTabView extends React.Component {
  state = {
    activeIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      const { campaignType, accountClientType } = this.props.campaign;
      let selectedTab = 0;
      if (accountClientType === "NECTAR_PRICE") {
        selectedTab = 2;
      } else if (
        campaignType === "INCENTIVE" &&
        accountClientType === "CHALLENGE"
      ) {
        selectedTab = 1;
      }
      this.setState({ activeIndex: selectedTab });
    }
  }

  render() {
    return (
      <GridWrapper>
        <GridItem>
          <Tabs type="pill">
            <TabLink
              active={this.state.activeIndex === 0}
              onClick={(e) => {
                this.setState({ activeIndex: 0 });
              }}
            >
              Generic Campaign
            </TabLink>
            <TabLink
              active={this.state.activeIndex === 1}
              onClick={(e) => {
                this.props.handleChange(null, {
                  name: "campaignType",
                  value: "INCENTIVE",
                });
                this.props.handleChange(null, {
                  name: "accountClientType",
                  value: "CHALLENGE",
                });
                this.setState({ activeIndex: 1 });
              }}
            >
              Challenge
            </TabLink>
            <TabLink
              active={this.state.activeIndex === 2}
              onClick={(e) => {
                this.props.handleChange(null, LoadNectarPriceCampaignDefaults);
                this.setState({ activeIndex: 2 });
              }}
            >
              Nectar Price Campaign
            </TabLink>
          </Tabs>

          <TabPanel>
            {this.state.activeIndex === 0 && (
              <GenericCampaignEditorForm
                allowedFields={this.props.allowedFields}
                campaign={this.props.campaign}
                campaignTags={this.props.campaignTags}
                onAddTag={this.props.onAddTag}
                handleChange={this.props.handleChange}
                handleChangeDate={this.props.handleChangeDate}
                handleSkuChange={this.props.handleSkuChange}
                isNewCampaign={this.props.isNewCampaign}
                fetchAllowedFields={this.props.fetchAllowedFields}
              />
            )}

            {this.state.activeIndex === 1 && (
              <>
                <ChallengeCampaignEditorForm
                  allowedFields={this.props.allowedFields}
                  campaign={this.props.campaign}
                  campaignTags={this.props.campaignTags}
                  onAddTag={this.props.onAddTag}
                  handleChange={this.props.handleChange}
                  handleChangeDate={this.props.handleChangeDate}
                  handleSkuChange={this.props.handleSkuChange}
                  isNewCampaign={this.props.isNewCampaign}
                  fetchAllowedFields={this.props.fetchAllowedFields}
                />
              </>
            )}

            {this.state.activeIndex === 2 && (
              <>
                <NectarPriceCampaignEditorForm
                  allowedFields={this.props.allowedFields}
                  campaign={this.props.campaign}
                  campaignTags={this.props.campaignTags}
                  onAddTag={this.props.onAddTag}
                  handleChange={this.props.handleChange}
                  handleChangeDate={this.props.handleChangeDate}
                />
              </>
            )}
          </TabPanel>
        </GridItem>
      </GridWrapper>
    );
  }
}
