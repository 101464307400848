import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { AccountClientTypes, TransactionSources } from "../campaign";
import { CampaignModes } from "../campaign";

export const AccountClientTypeInput = (props) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <Label>Type</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        readOnly="true"
        value={props.campaign.accountClientType}
        name="accountClientType"
        options={AccountClientTypes}
        placeholder="Please select..."
        onChange={props.handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <Label>Campaign Mode</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={props.campaign.mode}
        name="mode"
        options={CampaignModes}
        onChange={props.handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <Label>Allowed Redemption Channels</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={props.campaign.redeemableTransactionType}
        name="redeemableTransactionType"
        options={TransactionSources}
        onChange={props.handleChange}
      />
    </GridItem>
  </GridWrapper>
);
