import { PropTypes } from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Icon, Message } from "semantic-ui-react";

import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import WelcomeLogo from "../../assets_V2/WelcomeLogo.png";
import { ManagementUserClient } from "../../services/ManagementUserClient";
import { LoadingState } from "../../services/http.js";
import UsersTableView from "./UsersTableView";

const managementUserClient = new ManagementUserClient();

class UsersManagement extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  };
  state = {
    users: [],
    loadingState: LoadingState.PENDING,
  };

  constructor(props, context) {
    super(props, context);
    this.navigateCreateUser = this.navigateCreateUser.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    managementUserClient
      .fetchUsers()
      .then(({ data }) => {
        this.setState({
          users: data.content,
          loadingState: LoadingState.SUCCESS,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  }

  navigateCreateUser = () => {
    this.props.history.push(`/management-user-create`);
  };

  render() {
    return (
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper>
          <GridItem style={{ marginBottom: "1rem" }}>
            <span>
              <Icon circular name="users" />
              &nbsp;&nbsp;<Label htmlFor="">Management Portal Users</Label>
            </span>
          </GridItem>
          <GridItem>
            {this.state.loadingState === LoadingState.PENDING && (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  width: "100%",
                  height: "85vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GridWrapper
                  className="ln-o-grid ln-o-grid--middle"
                  horizontalAlign="center"
                  verticalAlign="middle"
                >
                  <GridItem
                    className="ln-u-text-align-center"
                    size={{ md: "1" }}
                  >
                    <div className="ln-u-soft">
                      <img
                        src={WelcomeLogo}
                        className="logoAnimate"
                        alt=""
                        width="100"
                        height="100"
                      />
                    </div>
                  </GridItem>
                  <GridItem
                    className="welcomeMsgTxt ln-u-text-align-center"
                    size={{ md: "1" }}
                  >
                    <h3>Loading...</h3>
                  </GridItem>
                </GridWrapper>
              </div>
            )}
            {this.state.users && <UsersTableView users={this.state.users} />}
            {this.state.loadingState !== LoadingState.FAILED && (
              <Message
                negative
                hidden={this.state.loadingState !== LoadingState.FAILED}
              >
                <Message.Header>Error loading users</Message.Header>
              </Message>
            )}
          </GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

export default withRouter(UsersManagement);
