import React from "react";
import { DateTimePicker } from "react-widgets";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { CampaignStatuses } from "./campaign";
import { ArtworkInput } from "./campaignforminputs/ArtworkInput";
import { OfferCodeInput } from "./campaignforminputs/OfferCodeInput";
import { TagsInput } from "./campaignforminputs/TagsInput";

export const LoadNectarPriceCampaignDefaults = (campaign) => {
  return {
    defaultNectarPrice: null,
    defaultFromPrice: null,
    artworkUrl: null,
    name: null,
    skus: [],
    campaignStatus: "ACTIVE",
    startDate: new Date().toISOString(),
    endDate: null,
    status: "DRAFT",
    ...campaign, // Order of properties matter, spread operator is here to override values above when loading an existing campaign
    campaignType: "NECTAR_PRICE",
    accountClientType: "NECTAR_PRICE",
    discountAmount: null,
    minimumPoints: null,
    maximumPoints: null,
    qualifyingSpend: null,
    minimumUnits: 1,
    allowOverride: true,
    limitMaxRedemptions: false,
    mode: "TARGETED",
    redeemableTransactionType: "ALL",
    defaultExpiry: null,
    displayType: "NORMAL",
    trackerType: null,
    trackerTheme: null,
    productCategory: null,
    startDateBehaviour: "START_WHEN_ISSUED",
    endDateBehaviour: "END_DATE_OF_CAMPAIGN",
    offerStartDate: null,
    offerEndDate: null,
  };
};

export class NectarPriceCampaignEditorForm extends React.Component {
  render() {
    let campaign = this.props.campaign;
    let handleChange = this.props.handleChange;

    return (
      <div>
        <br></br>
        {campaign.id && (
          <div>
            <h4 className="ln-c-label">ID</h4>
            <br /> {campaign.id} <br />
            <br />
          </div>
        )}
        {campaign.eagleEyeId && (
          <div>
            <h5 className="ln-c-label">EE ID</h5>
            <br /> {campaign.eagleEyeId}
            <br />
            <br />
          </div>
        )}

        <div>
          <GridWrapper>
            <GridItem size={{ xs: "1/3" }}>
              <Label>Campaign Type</Label>
              <Form.Input
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                readOnly
                value={campaign.campaignType}
                name="campaignType"
              />{" "}
            </GridItem>
            <GridItem size={{ xs: "1/3" }}>
              <Label>Type</Label>
              <Form.Input
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                readOnly
                value={campaign.accountClientType}
                name="accountClientType"
              />
            </GridItem>
            <GridItem size={{ xs: "1/3" }}>
              <Label>Status</Label>
              <br />
              <Form.Select
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "16px 8px 16px",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "2px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                defaultValue={campaign.campaignStatus}
                name="campaignStatus"
                options={CampaignStatuses}
                handleChange={handleChange}
              />
            </GridItem>
          </GridWrapper>
        </div>

        <GridWrapper>
          <GridItem style={{ margin: "1rem 0 0 0" }}>
            <Label>Name</Label>
            <Form.Input
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4.571px",
                color: "#737373",
                minHeight: "48px",
              }}
              defaultValue={campaign.name}
              name="name"
              placeholder="Name"
              onChange={handleChange}
            />
          </GridItem>
        </GridWrapper>

        <GridWrapper>
          <GridItem style={{ margin: "1rem 0 0 0" }}>
            <Label>Description</Label>
            <br />
            <Form.TextArea
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4.571px",
                color: "#737373",
                minHeight: "48px",
              }}
              defaultValue={campaign.campaignText1}
              name="campaignText1"
              placeholder="To display in the nectar app..."
              onChange={handleChange}
            />
          </GridItem>
        </GridWrapper>

        <GridWrapper>
          <GridItem>
            <ArtworkInput campaign={campaign} handleChange={handleChange} />
          </GridItem>
        </GridWrapper>

        <GridWrapper>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/4" }}>
            <Label>Default Nectar Price</Label>
            <Form.Input
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4.571px",
                color: "#737373",
                minHeight: "48px",
              }}
              value={campaign.defaultNectarPrice}
              name="defaultNectarPrice"
              placeholder="Default nectar price (in pence)"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/4" }}>
            <Label>Default From Price</Label>
            <Form.Input
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4.571px",
                color: "#737373",
                minHeight: "48px",
              }}
              value={campaign.defaultFromPrice}
              name="defaultFromPrice"
              placeholder="Default from price (in pence)"
              onChange={handleChange}
            />
          </GridItem>
          <GridItem size={{ xs: "1/4" }}>
            <OfferCodeInput
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />
          </GridItem>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/4" }}>
            <TagsInput
              defaultValue={this.props.campaign && this.props.campaign.tags}
              label="Tags"
              handleChange={this.props.handleChange}
              onAddTag={this.props.onAddTag}
              campaignTags={this.props.campaignTags}
            />
          </GridItem>
        </GridWrapper>

        <GridWrapper>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
            <Label>Campaign Start</Label>
            <br />
            <DateTimePicker
              culture="en"
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "2px #737373",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4px",
                color: "#737373",
              }}
              value={campaign && new Date(campaign.startDate)}
              onChange={(e) => this.props.handleChangeDate(e, "startDate")}
            />
          </GridItem>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
            <Label>Campaign End</Label>
            <br />
            <DateTimePicker
              culture="en"
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "2px #737373",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4px",
                color: "#737373",
              }}
              value={(campaign.endDate && new Date(campaign.endDate)) || null}
              onChange={(e) => this.props.handleChangeDate(e, "endDate")}
            />
          </GridItem>
        </GridWrapper>
      </div>
    );
  }
}
