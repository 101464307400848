import { PropTypes } from "prop-types";
import React from "react";

import { GroupRow } from "./GroupRow";

export class GroupTable extends React.Component {
  static propTypes = {
    groups: PropTypes.array.isRequired,
    onRowCheck: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
  };

  handleOnRowCheck = (group) => {
    this.props.onRowCheck(group);
  };

  handleOnRowSelect = (group) => {
    this.props.onRowSelect(group);
  };

  groupSelected = (grp) => {
    for (var x of this.props.selectedGroups) {
      if (x.id === grp.id) return true;
    }
    return false;
  };

  render() {
    const tableBody = this.props.groups.map((group, i) => {
      return (
        <GroupRow
          key={i}
          group={group}
          onCheck={this.handleOnRowCheck}
          onSelect={this.handleOnRowSelect}
          isSelected={this.groupSelected(group)}
        />
      );
    });

    return (
      <table class="ln-c-table">
        <thead class="ln-c-table__header">
          <tr class="ln-c-table__row ln-c-table__header-row">
            <th class="ln-c-table__header-cell customCol">Select</th>
            <th class="ln-c-table__header-cell customCol">Name</th>
            <th class="ln-c-table__header-cell customCol">Status</th>
          </tr>
        </thead>
        <tbody class="ln-c-table__body">{tableBody}</tbody>
      </table>
    );
  }
}

export default GroupTable;
