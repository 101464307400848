export const PlaceholderImage =
  "https://via.placeholder.com/150x150?text=placeholder";

export const InitialState = () => {
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 7);
  return {
    name: undefined,
    status: "DRAFT",
    campaignStatus: "ACTIVE",
    accountClientType: undefined,
    startDate: new Date().toISOString(),
    endDate: endDate.toISOString(),
    discountAmount: undefined,
    minimumPoints: null,
    maximumPoints: null,
    qualifyingSpend: null,
    skus: [],
    unit: null,
    step: null,
    minimumUnits: null,
    maximumUnits: null,
    totalTransactionCount: null,
    totalTransactionSpend: null,
    totalTransactionUnits: null,
    artworkUrl: undefined,
    allowOverride: true,
    redemptionPeriod: "REDEMPTION_CALENDAR_DAY",
    maxRedemptionsPerPeriod: 3,
    limitMaxRedemptions: false,
    barcode: null,
    mode: "TARGETED",
    redeemableTransactionType: "ALL",
    defaultExpiry: 8,
    displayType: "NORMAL",
    trackerType: null,
    trackerTheme: null,
    completionTitle: null,
    campaignText1: null,
    campaignText2: null,
    productCategory: null,
    imageSmallUrl: null,
    imageSmallLockedUrl: null,
    imageMediumUrl: null,
    imageLargeUrl: null,
    imageLargeShadowUrl: null,
    imageMediumLockedUrl: null,
    imageLargeLockedUrl: null,
    imageLargeLockedShadowUrl: null,
    startDateBehaviour: "START_WHEN_ISSUED",
    endDateBehaviour: "END_DATE_OF_CAMPAIGN",
    offerStartDate: null,
    offerEndDate: null,
    imageStoryUrl: null,
    imagePostUrl: null,
    imageWidePostUrl: null,
  };
};

export const AccountClientTypes = [
  { text: "Test", value: "TEST" },
  { text: "Welcome", value: "WELCOME" },
  { text: "Standard", value: "STANDARD" },
  { text: "Manual Assignment", value: "MANUAL_ASSIGNMENT" },
  { text: "Incentive", value: "INCENTIVE" },
  { text: "Trade Driving", value: "TRADE_DRIVING" },
  { text: "Challenge", value: "CHALLENGE" },
  { text: "Nectar Price", value: "NECTAR_PRICE" },
];

export const CampaignStatuses = [
  { text: "Active", value: "ACTIVE" },
  { text: "Suspended", value: "SUSPENDED" },
];

export const CampaignTypes = [
  { text: "Product", value: "PRODUCT" },
  { text: "Basket", value: "BASKET" },
  { text: "Sku-Limited Spend", value: "SKU_LIMITED_SPEND" },
  { text: "Mass Volume", value: "MASS_VOLUME" },
  { text: "Incentive", value: "INCENTIVE" },
  { text: "Money Off", value: "MONEY_OFF" },
  { text: "Points Multiplier", value: "POINTS_MULTIPLIER" },
  { text: "Trigger", value: "TRIGGER" },
  { text: "Nectar Price", value: "NECTAR_PRICE" },
];

export const RedemptionsPeriodTypes = [
  { text: "Life Time", value: "REDEMPTION_LIFETIME" },
  { text: "Per Day", value: "REDEMPTION_CALENDAR_DAY" },
  { text: "Per Week", value: "REDEMPTION_CALENDAR_WEEK" },
  { text: "Per Month", value: "REDEMPTION_CALENDAR_MONTH" },
  { text: "Per Year", value: "REDEMPTION_CALENDAR_YEAR" },
];

export const CampaignUnits = [
  { text: "PENCE", value: "PENCE" },
  { text: "MILLILITRE", value: "MILLILITRE" },
  { text: "GRAMS", value: "GRAMS" },
  { text: "None", value: null },
];

export const CampaignModes = [
  { text: "Open", value: "OPEN" },
  { text: "Targeted", value: "TARGETED" },
];

export const TransactionSources = [
  { text: "All", value: "ALL" },
  { text: "In-Store", value: "IN_STORE" },
  { text: "Groceries Online", value: "GROCERIES_ONLINE" },
];

export const TrackerType = [
  { text: "Target", value: "TARGET" },
  { text: "Badge", value: "BADGE" },
  { text: "Counter", value: "COUNTER" },
  { text: "None", value: null },
];

export const DisplayType = [
  { text: "Normal", value: "NORMAL" },
  { text: "Surprise Delight", value: "SURPRISE_DELIGHT" },
];

export const StartDateBehaviour = [
  { text: "Start when issued", value: "START_WHEN_ISSUED" },
  { text: "Start date of campaign", value: "START_DATE_OF_CAMPAIGN" },
  { text: "Start on specified date", value: "START_ON_SPECIFIED_DATE" },
];

export const EndDateBehaviour = [
  { text: "End date of campaign", value: "END_DATE_OF_CAMPAIGN" },
  { text: "End on specified date", value: "END_ON_SPECIFIED_DATE" },
  { text: "Expire after n days", value: "END_AFTER_N_DAYS" },
];

export const TrackerTheme = [
  { text: "Corepurple", value: "COREPURPLE" },
  { text: "Green", value: "GREEN" },
  { text: "Yellow", value: "YELLOW" },
  { text: "Red", value: "RED" },
  { text: "Darkputty", value: "DARKPUTTY" },
  { text: "None", value: null },
];

export const containsField = (fields, field) => {
  return fields.includes(field);
};
