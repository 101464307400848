import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

const nullOrZero = (val) => (val !== undefined ? val : 0);

export const TotalTransactionCountAndUnitsAndSpendGroup = ({
  campaign,
  handleChange,
}) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <Label>Total transaction count</Label>
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={nullOrZero(campaign.totalTransactionCount)}
        type="number"
        name="totalTransactionCount"
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <Label>Total transaction spend</Label>
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={nullOrZero(campaign.totalTransactionSpend)}
        type="number"
        name="totalTransactionSpend"
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
      <Label>Total transaction units</Label>
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={nullOrZero(campaign.totalTransactionUnits)}
        type="number"
        name="totalTransactionUnits"
        onChange={handleChange}
      />
    </GridItem>
  </GridWrapper>
);
