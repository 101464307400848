import React from "react";
import { TextArea } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const SkusInput = (props) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }}>
      <Label>Skus</Label>
      <TextArea
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        name="skus"
        placeholder="e.g. 123,456,789 (for product based campaigns only)"
        onChange={props.handleChange}
      />
    </GridItem>
  </GridWrapper>
);
