import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

const MinAndMaxPoints = ({ campaign, updatePoints }) => {
  return (
    <GridWrapper>
      <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
        <Label>Minimum Points</Label>
        <Form.Input
          style={{
            width: "100%",
            backgroundColor: "transparent",
            boxShadow: "0",
            fontFamily: "sans-serif",
            border: "1px solid #737373",
            borderRadius: "4.571px",
            color: "#737373",
            minHeight: "48px",
          }}
          value={campaign.minimumPoints}
          type="number"
          name="minimumPoints"
          placeholder="Minimum Points"
          onChange={updatePoints}
        />
      </GridItem>
      <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
        <Label>Maximum Points</Label>
        <Form.Input
          style={{
            width: "100%",
            backgroundColor: "transparent",
            boxShadow: "0",
            fontFamily: "sans-serif",
            border: "1px solid #737373",
            borderRadius: "4.571px",
            color: "#737373",
            minHeight: "48px",
          }}
          value={campaign.maximumPoints}
          type="number"
          name="maximumPoints"
          placeholder="Maximum Points"
          onChange={updatePoints}
        />
      </GridItem>
    </GridWrapper>
  );
};

export default MinAndMaxPoints;
