import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { CampaignTypes } from "../campaign";

export const PianoCampaignTypeInput = ({ campaign, handleChange }) => {
  return (
    <GridWrapper>
      <GridItem>
        <Label>Campaign Type</Label>
        <br />
        <Form.Select
          style={{
            width: "100%",
            backgroundColor: "transparent",
            padding: "16px 8px 16px",
            boxShadow: "0",
            fontFamily: "sans-serif",
            border: "1px solid #737373",
            borderRadius: "2px",
            color: "#737373",
            minHeight: "48px",
          }}
          value={campaign ? campaign.campaignType : null}
          name="campaignType"
          options={CampaignTypes}
          type="text"
          onChange={handleChange}
        />
      </GridItem>
    </GridWrapper>
  );
};
