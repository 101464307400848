import React from "react";

import { Label, TextInput } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const PodTitleInput = ({ pod, handleChange }) => (
  <GridWrapper>
    <GridItem className="ln-u-margin-bottom">
      <Label>ID</Label>
      <TextInput
        value={pod.id}
        name="id"
        placeholder="Pod Id"
        onChange={(e) => {
          handleChange(e, null);
        }}
      />
    </GridItem>
    <GridItem>
      <Label>Title</Label>
      <TextInput
        value={pod.header.data.title}
        name="title"
        placeholder="Pod Title"
        onChange={(e) => {
          handleChange(e, "header");
        }}
      />
    </GridItem>
  </GridWrapper>
);
