import React from "react";
import { Form, Icon, Popup } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { CampaignModes } from "./campaign";
import { TransactionSources } from "./campaign";
import { TrackerType } from "./campaign";
import { TrackerTheme } from "./campaign";
import { AllowPointsOverrideInput } from "./campaignforminputs/AllowPointsOverrideInput";
import { ArtworkInput } from "./campaignforminputs/ArtworkInput";
import { CampaignDetailsTextGroup } from "./campaignforminputs/CampaignDetailsTextGroup";
import { DatesInput } from "./campaignforminputs/DatesInput";
import { DefaultExpiryGroup } from "./campaignforminputs/DefaultExpiryGroup";
import { DescriptionInput } from "./campaignforminputs/DescriptionInput";
import { DisplayTypeInput } from "./campaignforminputs/DisplayTypeInput";
import { ImagesUrlsGroup } from "./campaignforminputs/ImagesUrlsGroup";
import MinAndMaxPoints from "./campaignforminputs/MinAndMaxPoints";
import ProductCategorySelection from "./campaignforminputs/ProductCategorySelection";
import { QualifyingSpendInput } from "./campaignforminputs/QualifyingSpendInput";
import { RedemptionsGroup } from "./campaignforminputs/RedemptionsGroup";
import { SkusInput } from "./campaignforminputs/SkusInput";
import { TagsInput } from "./campaignforminputs/TagsInput";
import { TotalTransactionCountAndUnitsAndSpendGroup } from "./campaignforminputs/TotalTransactionCountAndUnitsAndSpendGroup";
import { TriggerCampaignIdInput } from "./campaignforminputs/TriggerCampaignIdInput";

export default class ChallengeCampaignEditorForm extends React.Component {
  render() {
    return (
      <div>
        <br></br>
        {this.props.campaign.id && (
          <div>
            <h4 className="ln-c-label">ID</h4>
            <br /> {this.props.campaign.id}
            <br />
            <br />
          </div>
        )}
        {this.props.campaign.eagleEyeId && (
          <div>
            <h5 className="ln-c-label">EE ID</h5>
            <br /> {this.props.campaign.eagleEyeId}
            <br />
            <br />
          </div>
        )}

        <Label>Campaign Type</Label>
        <br />
        <Form.Input
          style={{
            width: "100%",
            backgroundColor: "transparent",
            boxShadow: "0",
            fontFamily: "sans-serif",
            border: "1px solid #737373",
            borderRadius: "4.571px",
            color: "#737373",
            minHeight: "48px",
          }}
          type="text"
          readOnly="true"
          value={this.props.campaign.campaignType}
          name="campaignType"
        />
        <GridWrapper>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
            <Label>Type</Label>
            <br />
            <Form.Input
              style={{
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "4.571px",
                color: "#737373",
                minHeight: "48px",
              }}
              name="accountClientType"
              type="text"
              readOnly="true"
              value={this.props.campaign.accountClientType}
            />
          </GridItem>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
            <Label>Campaign Mode</Label>
            <br />
            <Form.Select
              style={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "16px 8px 16px",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              defaultValue={this.props.campaign.mode}
              name="mode"
              options={CampaignModes}
              onChange={this.props.handleChange}
            />
          </GridItem>
          <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
            <Label>Allowed Redemption Channels</Label>
            <br />
            <Form.Select
              style={{
                width: "100%",
                backgroundColor: "transparent",
                padding: "16px 8px 16px",
                boxShadow: "0",
                fontFamily: "sans-serif",
                border: "1px solid #737373",
                borderRadius: "2px",
                color: "#737373",
                minHeight: "48px",
              }}
              defaultValue={this.props.campaign.redeemableTransactionType}
              name="redeemableTransactionType"
              options={TransactionSources}
              onChange={this.props.handleChange}
            />
          </GridItem>
        </GridWrapper>
        <div>
          <DescriptionInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <GridWrapper>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
              <Label>Offer Code *</Label>
              <br />
              <Form.Select
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "16px 8px 16px",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "2px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                defaultValue={this.props.campaign.offerCode}
                name="offerCode"
                options={[
                  { text: "PIANOLWFL", value: "PIANOLWFL" },
                  { text: "PIANOCFC", value: "PIANOCFC" },
                  {
                    text: this.props.campaign.offerCode,
                    value: this.props.campaign.offerCode,
                  },
                ]}
                onChange={this.props.handleChange}
                required={true}
              />
            </GridItem>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
              <TagsInput
                defaultValue={this.props.campaign && this.props.campaign.tags}
                label="Tags"
                handleChange={this.props.handleChange}
                onAddTag={this.props.onAddTag}
                campaignTags={this.props.campaignTags}
              />
            </GridItem>
          </GridWrapper>

          <MinAndMaxPoints
            campaign={this.props.campaign}
            updatePoints={this.props.handleChange}
          />

          <QualifyingSpendInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <RedemptionsGroup
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <ArtworkInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <AllowPointsOverrideInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <TotalTransactionCountAndUnitsAndSpendGroup
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <TriggerCampaignIdInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <GridWrapper>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
              <Label>Tracker Type</Label>
              <br />
              <Form.Select
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "16px 8px 16px",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "2px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                value={this.props.campaign.trackerType}
                name="trackerType"
                options={TrackerType}
                placeholder="Please select..."
                onChange={this.props.handleChange}
              />
            </GridItem>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
              <Label>Tracker Theme</Label>
              <br />
              <Form.Select
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "16px 8px 16px",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "2px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                value={this.props.campaign.trackerTheme}
                name="trackerTheme"
                options={TrackerTheme}
                placeholder="Please select..."
                onChange={this.props.handleChange}
              />
            </GridItem>
          </GridWrapper>

          <ProductCategorySelection
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
            handleSkuChange={this.props.handleSkuChange}
          />
          {(this.props.campaign.productCategory === null ||
            this.props.campaign.productCategory === "") && (
            <SkusInput handleChange={this.props.handleSkuChange} />
          )}

          <ImagesUrlsGroup
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <GridWrapper>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
              <Label>
                Story Image URL &nbsp;( <Icon name="facebook" />
                <Icon name="instagram" />)
                <Popup
                  content="This will be the image the user can share in a Facebook/Instagram Story, on completion of target/badge"
                  trigger={<Icon name="help circle" />}
                />
              </Label>
              <br />
              <Form.Input
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                defaultValue={this.props.campaign.imageStoryUrl}
                name="imageStoryUrl"
                onChange={this.props.handleChange}
              />
            </GridItem>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
              <Label>
                Post Image URL &nbsp;( <Icon name="facebook" />
                <Icon name="instagram" />)
                <Popup
                  content="This will be the image the user can share in a Facebook/Instagram Post, on completion of target/badge"
                  trigger={<Icon name="help circle" />}
                />
              </Label>
              <br />
              <Form.Input
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                defaultValue={this.props.campaign.imagePostUrl}
                name="imagePostUrl"
                onChange={this.props.handleChange}
              />
            </GridItem>
            <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/3" }}>
              <Label>
                Wide Post Image URL &nbsp;( <Icon name="twitter" />)
                <Popup
                  content="This will be the image the user can share in a Twitter Post, on completion of target/badge"
                  trigger={<Icon name="help circle" />}
                />
              </Label>
              <br />
              <Form.Input
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                defaultValue={this.props.campaign.imageWidePostUrl}
                name="imageWidePostUrl"
                onChange={this.props.handleChange}
              />
            </GridItem>
          </GridWrapper>

          <CampaignDetailsTextGroup
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <DatesInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
            handleChangeDate={this.props.handleChangeDate}
          />

          <DefaultExpiryGroup
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />

          <DisplayTypeInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />
        </div>
      </div>
    );
  }
}
