import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { RedemptionsPeriodTypes } from "../campaign";

export const RedemptionsGroup = ({ campaign, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0", height: "" }} size={{ xs: "1/4" }}>
      <Label>Limit Redemptions</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={campaign ? campaign.limitMaxRedemptions : false}
        name="limitMaxRedemptions"
        options={[
          { text: "Yes", value: true },
          { text: "No", value: false },
        ]}
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/4" }}>
      <Label>Max Redemptions Per Period</Label>
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={campaign.maxRedemptionsPerPeriod}
        disabled={!campaign.limitMaxRedemptions}
        type="number"
        name="maxRedemptionsPerPeriod"
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/4" }}>
      <Label>Redemption Period Type</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={campaign.redemptionPeriod}
        disabled={!campaign.limitMaxRedemptions}
        name="redemptionPeriod"
        options={RedemptionsPeriodTypes}
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/4" }}>
      <Label>Total number of redemptions per customer</Label>
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        defaultValue={campaign ? campaign.couponLimit : 0}
        type="number"
        name="couponLimit"
        placeholder="Coupon Limit"
        onChange={handleChange}
      />
    </GridItem>
  </GridWrapper>
);
