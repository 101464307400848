import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const AllowPointsOverrideInput = ({ campaign, handleChange }) => {
  const isIncentive = () => {
    return (
      campaign.accountClientType !== undefined &&
      campaign.accountClientType === "INCENTIVE"
    );
  };

  return (
    <GridWrapper>
      <GridItem style={{ margin: "1rem 0 0 0" }}>
        <Label>Allow Points Override</Label>
        <br />
        <Form.Select
          style={{
            width: "100%",
            backgroundColor: "transparent",
            padding: "16px 8px 16px",
            boxShadow: "0",
            fontFamily: "sans-serif",
            border: "1px solid #737373",
            borderRadius: "2px",
            color: "#737373",
            minHeight: "48px",
          }}
          name="allowOverride"
          options={[
            { text: "Yes", value: true },
            { text: "No", value: false },
          ]}
          placeholder="Allow Override"
          value={isIncentive() ? false : campaign.allowOverride}
          onChange={handleChange}
        />
      </GridItem>
    </GridWrapper>
  );
};
