import { useSessionContext } from "../contexts/SessionContext";
import { getSessionCookieData } from "../services/sessionCookie";

// Define a mapping of menu types to roles
export const roleMapping = {
  customers: "CustomerReader",
  "marketing-events": "MarketingEventEditor",
  campaigns: "CampaignEditor",
  "management-user": "User Management",
  products: "ProductsEditor",
  promotions: "CampaignEditor",
  gs1suppliercodes: "CampaignEditor",
  barcodeblocklists: "CampaignEditor",
  baserate: "BaserateEditor",
  allocateoffer: "AllocateOffer",
  "homepage-pods": "HomepagePodEditor",
  skus: "MarketingEventEditor",
};

export const permissionMapping = {
  customers: "customer:read",
  "marketing-events": "campaign:read",
  campaigns: "campaign:read",
  "management-user": "management_user:read",
  products: "product:read",
  promotions: "campaign:read",
  gs1suppliercodes: "campaign:read",
  barcodeblocklists: "campaign:read",
  baserate: "baserate:write",
  allocateoffer: "developer:write",
  "homepage-pods": "management_pod:read",
  skus: "sku:read",
  offercodes: "offer_code:read",
};

const useUserRoles = () => {
  const { userRoles } = useSessionContext();
  return userRoles;
};

const usePermissions = () => {
  const { permissions } = getSessionCookieData();
  return permissions || [];
};

const useRoleBasedPermissions = () => {
  const { isRoleBasedAccess } = useSessionContext();
  return isRoleBasedAccess;
};

export const HasReadOnlyAccess = () => {
  const userRoles = useUserRoles();
  return userRoles.includes("ReadOnly");
};

export const HasRequiredRoleAccess = (menu) => {
  const userRoles = useUserRoles();
  const permissions = usePermissions();
  const isRoleBasedAccess = useRoleBasedPermissions();
  if (isRoleBasedAccess) {
    const requiredRole = roleMapping[menu];
    return requiredRole ? userRoles.includes(requiredRole) : false;
  } else {
    const requiredPermission = permissionMapping[menu];
    return requiredPermission
      ? permissions.includes(requiredPermission)
      : false;
  }
};

export const ValidatePermission = (menu) => {
  const readOnlyRole = HasReadOnlyAccess();
  const validRole = HasRequiredRoleAccess(menu);
  return readOnlyRole ? true : validRole;
};
