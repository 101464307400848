import { isEmpty, map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Message,
  Modal,
} from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import {
  ArrowLeft,
  ArrowRight,
  Basket,
  Cancel,
  Download,
  Plus,
  Tick,
  Upload,
} from "@jsluna/icons";
import { Modal as LunaModel, ModalHeading } from "@jsluna/modal";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import WelcomeLogo from "../../assets_V2/WelcomeLogo.png";
import { defaultDateTimeFormat, toApiFormat } from "../../common/dates";
import { isProd } from "../../config";
import CampaignClient from "../../services/CampaignClientLegacy";
import CampaignEditor from "./CampaignEditor";
import { PlaceholderImage } from "./campaign";
import { CampaignModes } from "./campaign";
import { DatesInput } from "./campaignforminputs/DatesInput";
import { TagsInput } from "./campaignforminputs/TagsInput";

const campaignClient = new CampaignClient();

const Direction = {
  FORWARD: "FORWARD",
  BACKWARD: "BACKWARD",
};

const viewStates = Object.freeze({
  EDIT: Symbol("Edit"),
  LIST: Symbol("List"),
});

class CampaignsView extends React.Component {
  state = {
    inactive: {
      campaigns: undefined,
      error: undefined,
      totalPages: 0,
      pageNum: 0,
    },
    active: {
      campaigns: [],
      error: undefined,
      totalPages: 0,
      pageNum: 0,
    },
    campaignTags: [],
    commitCampaignId: undefined,
    stagedCampaigns: {},
    committedCampaigns: [],
    viewState: viewStates.LIST,
    activeTabIndex: 0,
    currentCampaignId: "new",
    toggleAll: false,

    modals: {
      commitCampaign: { visible: false },
      updateCampaigns: { visible: false },
    },

    search: {
      pageSize: 18,
      name: "",
      mode: "TARGETED",
      tags: "",
    },
    exportNameSelect: "",
    downloadLoading: false,
  };

  constructor() {
    super();
    this.searchText = "";
    this.mode = "TARGETED";
  }

  componentDidMount() {
    window.onpopstate = this.handleBackClick;
    this.reloadCampaigns();
    this.loadCampaignTags();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  reloadCampaigns = () => {
    this.loadCampaigns(false);
    this.loadCampaigns(true);
  };

  loadCampaignTags = () => {
    campaignClient
      .fetchCampaignTags()
      .then(({ data }) => {
        this.setState({ campaignTags: data });
      })
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({ error: "Failed to load campaign" });
        });
      });
  };

  createCampaignTag = (name) => {
    campaignClient
      .createCampaignTag({ name })
      .then(({ data }) =>
        this.setState({
          campaignTags: this.state.campaignTags.concat({ name: data.name }),
        })
      )
      .catch((err) => {
        alert("Error: " + err.response.data.description);
      });
  };

  handleBackClick = (e) => {
    if (this.state.viewState === viewStates.EDIT) {
      e.preventDefault();
      this.setState({
        viewState: viewStates.LIST,
      });
    } else {
      return true;
    }
  };

  loadCampaigns(active) {
    const page = active
      ? this.state.active.pageNum
      : this.state.inactive.pageNum;

    if (page >= 0) {
      const search = this.state.search;
      campaignClient
        .fetchCampaigns(
          page,
          search.pageSize,
          search.name,
          active,
          search.tags,
          search.mode
        )
        .then(({ data }) => {
          const stateData = {
            campaigns: data,
            totalPages: data.totalPages,
            pageNum: page,
          };
          this.setState({ [active ? "active" : "inactive"]: stateData });
        })
        .catch(() => {
          const error = { error: "Failed to load campaigns" };
          this.setState({ [active ? "active" : "inactive"]: error });
        });
    } else {
      const error = { error: "Invalid page" };
      this.setState({ [active ? "active" : "inactive"]: error });
    }
  }

  deleteCampaign = (id, name) => {
    campaignClient.deleteCampaign(id).then(() => {
      toast.success(`Deleted campaign${name ? ` '${name}'` : ""}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
      });
      this.loadCampaigns(false);
    });
  };

  toggleModal = (modalName) => {
    this.setState({
      modals: {
        ...this.state.modals,
        [modalName]: { visible: !this.state.modals[modalName].visible },
      },
    });
  };

  toggleCommitModal = () => {
    this.toggleModal("commitCampaign");
  };

  toggleUpdateCampaignsModal = () => {
    this.toggleModal("updateCampaigns");
  };

  barcodeExport = () => {
    map(this.state.stagedCampaigns, (c) =>
      this.setState({ exportNameSelect: c.name })
    );
    this.setState({ downloadLoading: true });
    // eslint-disable-next-line
    {
      this.state.exportNameSelect &&
        campaignClient
          .exportCampaignBarcodeByName(this.state.exportNameSelect)
          .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Campaign-export-${this.state.exportNameSelect}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.setState({ downloadLoading: false });
            toast.success("Download Success");
          })
          .catch((err) => {
            this.setState({ downloadLoading: false });
            toast.error("Something went Wrong!");
          });
    }

    // eslint-disable-next-line
    {
      this.state.search.tags &&
        campaignClient
          .exportCampaignBarcodeByTag(this.state.search.tags)
          .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Campaign-export-${this.state.search.tags}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            toast.success("Download Success");
            this.setState({ downloadLoading: false });
          })
          .catch((err) => {
            this.setState({ downloadLoading: false });
            toast.error("Something went Wrong!");
          });
    }
  };
  barcodeAndFinanceExport = () => {
    map(this.state.stagedCampaigns, (c) =>
      this.setState({ exportNameSelect: c.name })
    );
    this.setState({ downloadLoading: true });
    // eslint-disable-next-line
    {
      this.state.exportNameSelect &&
        campaignClient
          .exportCampaignBarcodeAndFinanceByName(this.state.exportNameSelect)
          .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Campaign-export-${this.state.exportNameSelect}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.setState({ downloadLoading: false });
            toast.success("Download Success");
          })
          .catch((err) => {
            this.setState({ downloadLoading: false });
            toast.error("Something went Wrong!");
          });
    }

    // eslint-disable-next-line
    {
      this.state.search.tags &&
        campaignClient
          .exportCampaignBarcodeAndFinanceByTag(this.state.search.tags)
          .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Campaign-export-${this.state.search.tags}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            this.setState({ downloadLoading: false });
            toast.success("Download Success");
          })
          .catch((err) => {
            this.setState({ downloadLoading: false });
            toast.error("Something went Wrong!");
          });
    }
  };

  stageCampaign = (campaign, doStage) => {
    const staged = this.state.stagedCampaigns;
    if (!doStage) {
      delete staged[campaign.id];
    } else {
      staged[campaign.id] = campaign;
    }

    this.setState({ stagedCampaigns: staged });
  };

  toggleAll = (checked) => {
    this.setState({ toggleAll: checked });
    const pool =
      this.state.activeTabIndex === 0 ? this.state.inactive : this.state.active;
    pool.campaigns.content.forEach((c) => this.stageCampaign(c, checked));
  };

  resetToggleAll = () => this.setState({ toggleAll: false });

  clearStagedCampaigns = () => {
    this.setState({ stagedCampaigns: {}, toggleAll: false });
  };

  commitStagedCampaigns = () => {
    this.toggleCommitModal();
    this.setState({ isCommitModalVisible: false });
    const deets = {
      count: 0,
      done: 0,
      failed: 0,
    };

    const entries = Object.entries(this.state.stagedCampaigns);
    for (const x of entries) {
      deets.count++;
      this.commitCampaign(x, entries, deets);
    }
    this.loadCampaigns(true);
  };

  updateStagedCampaigns = (updates) => {
    this.toggleUpdateCampaignsModal();
    const toUpdate = Object.values(this.state.stagedCampaigns).map(
      (campaign) => ({ ...campaign, ...updates })
    );

    this.updateCampaignsBatch(toUpdate)
      .then(() => {
        this.toggleAll(false);
        this.loadCampaigns(this.state.activeTabIndex === 1);
      })
      .catch(() =>
        alert(
          "Error while bulk update. Some of them might have been updated. Please refresh page!"
        )
      );
  };

  updateCampaignsBatch = async (campaigns) => {
    const client = campaignClient;
    const calls = campaigns.map((c) => client.updateCampaign(c));
    await Promise.all(calls);
  };

  commitCampaign = (x, entries, deets) => {
    setTimeout(
      (z) => {
        campaignClient
          .commitCampaign(z[0])
          .then(() => {
            const temp = Object.assign({}, this.state.stagedCampaigns);
            delete temp[z[0]];
            this.setState(
              {
                stagedCampaigns: temp,
                committedCampaigns: this.state.committedCampaigns.concat([
                  z[0],
                ]),
              },
              () => {
                setTimeout(() => {
                  deets.done++;
                  if (deets.done === entries.length) {
                    alert(deets.done + " committed.");
                    this.loadCampaigns(false);
                  } else if (deets.done + deets.failed === entries.length) {
                    alert(deets.failed + " commits failed.");
                    this.loadCampaigns(false);
                  }
                }, 500);
              }
            );
          })
          .catch(() => {
            deets.failed++;
            if (deets.done + deets.failed === entries.length) {
              alert(deets.failed + " commits failed.");
              this.loadCampaigns(false);
            }
          });
      },
      500 * deets.count,
      x
    );
  };

  isSelected = (id) => {
    return this.state.stagedCampaigns[id];
  };

  isCommitted = (id) => {
    return this.state.committedCampaigns.filter((x) => x === id).length > 0;
  };

  onInactivePageChange = (direction) => {
    const delta = direction === Direction.FORWARD ? 1 : -1;
    this.resetToggleAll();
    this.setState(
      {
        inactive: {
          ...this.state.inactive,
          pageNum: this.state.inactive.pageNum + delta,
        },
      },
      () => this.loadCampaigns(false)
    );
  };

  onActivePageChange = (direction) => {
    const delta = direction === Direction.FORWARD ? 1 : -1;
    this.setState(
      {
        active: {
          ...this.state.active,
          pageNum: this.state.active.pageNum + delta,
        },
      },
      () => this.loadCampaigns(true)
    );
  };

  onFilterChange = (name, value) => {
    this.setState(
      { search: { ...this.state.search, [name]: value } },
      this.reloadCampaigns
    );
  };

  currentView = () => {
    switch (this.state.viewState) {
      case viewStates.EDIT:
        return (
          <CampaignEditor
            key={this.state.currentCampaignId}
            campaignId={this.state.currentCampaignId}
            createCampaignTag={this.createCampaignTag}
            campaignTags={this.state.campaignTags}
            close={() => {
              this.setState({ viewState: viewStates.LIST });
              this.reloadCampaigns();
            }}
            isActive={this.state.activeTabIndex === 1}
          />
        );
      case viewStates.LIST:
        return (
          <div>
            <GridWrapper style={{ margin: "1rem 0 0 0" }}>
              <GridWrapper>
                <GridItem size={{ xs: "1/3" }}>
                  <TextInputField
                    placeholder="Search campaigns by name"
                    value={this.state.search.name || ""}
                    onChange={(event) =>
                      this.onFilterChange("name", event.target.value)
                    }
                  />
                </GridItem>
                <GridItem size={{ xs: "1/3" }}>
                  <Form>
                    <Form.Select
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        padding: "16px 8px 16px",
                        boxShadow: "0",
                        fontFamily: "sans-serif",
                        border: "1px solid #737373",
                        borderRadius: "2px",
                        color: "#737373",
                        minHeight: "48px",
                      }}
                      defaultValue={this.state.search.mode}
                      name="mode"
                      options={CampaignModes}
                      onChange={(event, { name, value }) =>
                        this.onFilterChange("mode", value)
                      }
                    />
                  </Form>
                </GridItem>
                <GridItem size={{ xs: "1/3" }}>
                  <TagsInput
                    defaultValue={this.state.search.tags}
                    placeholder="Search campaigns by tags"
                    handleChange={(event, data) =>
                      this.onFilterChange("tags", data.value)
                    }
                    campaignTags={this.state.campaignTags}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper style={{ padding: "1.5rem 0 0 0" }}>
                <GridItem size={{ xs: "3/5" }}>
                  <ButtonGroupSecondary>
                    <Button
                      style={{
                        border: "2px solid #f06c00",
                        borderRadius: "3px",
                        fontSize: "1.190rem",
                        fontFamily:
                          "MaryAnn, Trebuchet MS, Arial, Helvetica, sans-serif",
                        backgroundColor: "transparent",
                        color: "#f06c00",
                      }}
                      secondary
                      hidden
                      as="label"
                      content="Bulk Upload"
                      htmlFor="fileUpload"
                      icon={
                        <>
                          <Upload /> &nbsp;&nbsp;
                        </>
                      }
                    />
                    <input
                      id="fileUpload"
                      type="file"
                      hidden
                      onChange={this.onUploadClick}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        "/CHALLENGE-sample-csv-headers.csv"
                      }
                      download="CHALLENGE-sample-csv-headers.csv"
                    >
                      <FilledButton>
                        <Download />
                        &nbsp;&nbsp;Download sample Challenge
                      </FilledButton>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <CampaignExports
                      count={
                        Object.entries(this.state.stagedCampaigns).length ||
                        Object.entries(this.state.search.tags).length
                      }
                      countName={
                        Object.entries(this.state.stagedCampaigns).length
                      }
                      onbarcodeExport={this.barcodeExport}
                      onbarcodeAndFinanceExport={this.barcodeAndFinanceExport}
                    />
                  </ButtonGroupSecondary>
                </GridItem>
                <GridItem size={{ xs: "2/5" }}>
                  <ButtonGroupPrimary>
                    <CampaignActions
                      activeTabIndex={this.state.activeTabIndex}
                      count={Object.entries(this.state.stagedCampaigns).length}
                      onCommit={this.toggleCommitModal}
                      onUpdate={this.toggleUpdateCampaignsModal}
                      onClear={this.clearStagedCampaigns}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FilledButton onClick={this.onCreateClick}>
                      <Plus />
                      &nbsp;&nbsp;Create campaign
                    </FilledButton>
                  </ButtonGroupPrimary>
                </GridItem>
              </GridWrapper>
            </GridWrapper>
          </div>
        );
      default:
        return <div />;
    }
  };

  onCreateClick = () => {
    this.setState({
      currentCampaignId: "new",
      viewState: viewStates.EDIT,
    });
    this.props.history.push("/legacy_campaigns");
  };

  onUploadClick = (e) => {
    const files = e.target.files;
    const file = files && files[0];
    if (file) {
      campaignClient
        .getFileUploadUrl(file.name)
        .then((url) => this.uploadFile(url, file))
        .catch((err) => alert("File upload error"));
    }
  };

  uploadFile = (url, file) => {
    campaignClient
      .uploadFile(url.data, file)
      .then(() => alert("File progress"))
      .catch((err) => alert("File upload error"));
  };

  onEditClick = (camp) => {
    this.setState({
      currentCampaignId: camp.id,
      viewState: viewStates.EDIT,
    });
    this.props.history.push("/legacy_campaigns");
  };

  render() {
    const { error, campaigns, totalPages } = this.state.inactive;
    if (!campaigns && !error) {
      return (
        <div
          style={{
            position: "absolute",
            display: "flex",
            width: "100%",
            height: "85vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridWrapper
            className="ln-o-grid ln-o-grid--middle"
            horizontalAlign="center"
            verticalAlign="middle"
          >
            <GridItem className="ln-u-text-align-center" size={{ md: "1" }}>
              <div className="ln-u-soft">
                <img
                  src={WelcomeLogo}
                  className="logoAnimate"
                  alt=""
                  width="100"
                  height="100"
                />
              </div>
            </GridItem>
            <GridItem
              className="welcomeMsgTxt ln-u-text-align-center"
              size={{ md: "1" }}
            >
              <h3>Loading...</h3>
            </GridItem>
          </GridWrapper>
        </div>
      );
    }

    const propz = {
      toggleAll: this.toggleAll,
      toggleCommitModal: this.toggleCommitModal,
      deleteCampaign: this.deleteCampaign,
      stageCampaign: this.stageCampaign,
      isSelected: this.isSelected,
      isCommitted: this.isCommitted,
      onEditClick: this.onEditClick,
      activeTabIndex: this.state.activeTabIndex,
      checked: this.state.toggleAll,
    };

    const inactiveCampaigns = (
      <CampaignList campaignData={this.state.inactive} {...propz} />
    );
    const activeCampaigns = (
      <CampaignList campaignData={this.state.active} {...propz} />
    );

    return (
      <>
        <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
          <GridWrapper>
            <GridItem size={{ xs: "1" }}>
              <span>
                <Basket />
                &nbsp;&nbsp;<Label htmlFor="">Campaigns</Label>
              </span>
            </GridItem>
            <GridItem>
              {this.currentView()}
              <br></br>
            </GridItem>
            {this.state.viewState === viewStates.LIST && (
              <GridItem>
                <Tabs type="fill">
                  <TabLink
                    active={this.state.activeTabIndex === 0}
                    onClick={(e, data) => {
                      this.clearStagedCampaigns();
                      this.setState({ activeTabIndex: 0 });
                    }}
                  >
                    Inactive
                  </TabLink>
                  <TabLink
                    panes={activeCampaigns}
                    active={this.state.activeTabIndex === 1}
                    onClick={(e, data) => {
                      this.clearStagedCampaigns();
                      this.setState({ activeTabIndex: 1 });
                    }}
                  >
                    Active
                  </TabLink>
                </Tabs>
                <TabPanel>
                  {this.state.activeTabIndex === 0 && (
                    <>
                      <Card style={{ padding: "1.5rem 0" }}>
                        {inactiveCampaigns}
                      </Card>
                      <Card>
                        <PageControls
                          onPageChange={this.onInactivePageChange}
                          currentPage={this.state.inactive.pageNum + 1}
                          totalPages={totalPages}
                        />
                      </Card>
                    </>
                  )}

                  {this.state.activeTabIndex === 1 && (
                    <>
                      <Card style={{ padding: "1.5rem 0" }}>
                        {activeCampaigns}
                      </Card>
                      <Card>
                        <PageControls
                          onPageChange={this.onActivePageChange}
                          currentPage={this.state.active.pageNum + 1}
                          totalPages={this.state.active.totalPages}
                        />
                      </Card>
                    </>
                  )}
                </TabPanel>
              </GridItem>
            )}
          </GridWrapper>
        </Card>

        <CommitCampaignsModal
          open={this.state.modals.commitCampaign.visible}
          campaigns={this.state.stagedCampaigns}
          onConfirm={this.commitStagedCampaigns}
          onDismiss={this.toggleCommitModal}
        />

        <UpdateCampaignsModal
          open={this.state.modals.updateCampaigns.visible}
          campaigns={this.state.stagedCampaigns}
          onConfirm={this.updateStagedCampaigns}
          onDismiss={this.toggleUpdateCampaignsModal}
        />

        {this.state.downloadLoading && (
          <ProgressIndicator
            page
            style={{ position: "absolute", zIndex: 10 }}
            loading
            preventFocus
          >
            <ProgressBar color="light" />
            Loading...
          </ProgressIndicator>
        )}
      </>
    );
  }
}

const CampaignList = ({
  campaignData,
  toggleAll,
  toggleCommitModal,
  deleteCampaign,
  stageCampaign,
  isSelected,
  isCommitted,
  onEditClick,
  activeTabIndex,
  checked,
}) => {
  if (campaignData.error)
    return <ErrorLoadingCampaigns message={campaignData.error} />;

  return (
    <CampaignTable>
      <CampaignTableHeader checked={checked} toggleAll={toggleAll} />
      <CampaignTableBody>
        {map(campaignData.campaigns.content, (c, idx) => (
          <CampaignRow
            commitCampaign={toggleCommitModal}
            deleteCampaign={deleteCampaign}
            campaign={c}
            key={c.id}
            stage={stageCampaign}
            isSelected={isSelected}
            isPresent={isCommitted}
            onClick={() => {
              onEditClick(c);
            }}
            activeTabIndex={activeTabIndex}
          />
        ))}
      </CampaignTableBody>
    </CampaignTable>
  );
};

const CommitCampaignsModal = ({ open, campaigns, onConfirm, onDismiss }) => {
  const count = Object.keys(campaigns).length;

  return (
    <LunaModel
      open={open}
      fullScreen
      restrictClose
      alert
      headingId="dialog-modal"
    >
      <ModalHeading element="h3">Are you sure?</ModalHeading>
      <p> This will make the {count} selected campaigns live! </p>
      <ButtonGroupPrimary>
        <OutlinedButton className="ln-u-margin-right" onClick={onDismiss}>
          Cancel
        </OutlinedButton>
        <FilledButton onClick={onConfirm}>Confirm</FilledButton>
      </ButtonGroupPrimary>
    </LunaModel>
  );
};

class UpdateCampaignsModal extends React.Component {
  state = {
    newData: {},
    expanded: false,
  };

  handleChange = (field, value) => {
    const prevState = this.state.newData;
    var newState = { ...prevState };
    if (!value) {
      delete newState[field];
    } else {
      newState = { ...prevState, [field]: value };
    }

    this.setState({
      newData: newState,
    });
  };

  handleClick = () => this.setState({ expanded: !this.state.expanded });

  dismiss = () => {
    this.setState({ newData: {} });
    this.props.onDismiss();
  };

  render = () => {
    const { open, campaigns, onConfirm } = this.props;
    const count = Object.keys(campaigns).length;

    return (
      <Modal open={open} onClose={this.dismiss}>
        <Modal.Header>Are you sure? </Modal.Header>
        <Modal.Content>
          <Message warning>
            <Message.Header>Warning!</Message.Header>

            <Accordion>
              <Accordion.Title
                active={this.state.expanded}
                index={0}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" /> This will update {count} campaigns
              </Accordion.Title>
              <Accordion.Content active={this.state.expanded}>
                <Grid>
                  {Object.values(campaigns).map((c) => (
                    <Grid.Column width={8} key={c.id}>
                      {c.name}
                    </Grid.Column>
                  ))}
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Message>

          <Form>
            <Form.Select
              label="Type"
              name="accountClientType"
              options={[
                { text: "Standard", value: "STANDARD" },
                { text: "Manual Assignment", value: "MANUAL_ASSIGNMENT" },
              ]}
              placeholder="Please select..."
              onChange={(e, d) => this.handleChange(d.name, d.value)}
            />

            <DatesInput
              handleChange={(value, field) =>
                this.handleChange(field, value ? toApiFormat(value) : null)
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button size="small" type={"button"} onClick={this.dismiss}>
            Cancel
          </Button>
          <Button
            size="small"
            type={"button"}
            color={"green"}
            disabled={Object.keys(this.state.newData).length === 0}
            onClick={() => onConfirm(this.state.newData)}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

const CampaignTableHeader = ({ toggleAll, checked }) => (
  <thead className="ln-c-table__header">
    <tr className="ln-c-table__row ln-c-table__header-row">
      {[
        <Label>Campaigns</Label>,
        <Label>Type</Label>,
        <Label>Starts</Label>,
        <Label>Ends</Label>,
        <Label>Qualification</Label>,
        <Label>Reward points [min, max]</Label>,
        <Label>Campaign ID</Label>,
      ].map((header, idx) => (
        <th className="ln-c-table__header-cell customCol" key={idx}>
          {header}
        </th>
      ))}
      <th
        className="ln-c-table__header-cell customCol"
        style={{ textAlign: "center" }}
      >
        <Checkbox
          toggle
          onChange={(e, d) => toggleAll(d.checked)}
          checked={checked}
        />
      </th>
    </tr>
  </thead>
);

const CampaignActions = ({
  activeTabIndex,
  count,
  onCommit,
  onUpdate,
  onClear,
}) => {
  const noCampaigns = !count || count < 0;
  const message = noCampaigns
    ? "Select campaigns..."
    : `Actions on ${count} campaigns...`;

  const options = [
    {
      key: "update",
      icon: "edit",
      text: `Update`,
      value: "update",
      onClick: onUpdate,
    },
  ];
  activeTabIndex === 0 &&
    options.push({
      key: "commit",
      icon: "compress",
      text: `Commit`,
      value: "commit",
      onClick: onCommit,
    });
  options.push({
    key: "clear",
    icon: "trash",
    text: `Clear selection`,
    value: "clear",
    onClick: onClear,
  });

  return (
    <OutlinedButton disabled={noCampaigns}>
      {message}
      <Dropdown
        style={{ color: "#f06c00", backgroundColor: "#FDF5F1" }}
        disabled={noCampaigns}
        className="button icon"
        options={options}
        trigger={<div />}
      />
    </OutlinedButton>
  );
};

const CampaignExports = ({
  count,
  countName,
  onbarcodeExport,
  onbarcodeAndFinanceExport,
}) => {
  let noCampaignExport = !count || count < 0;
  if (countName > 1) noCampaignExport = true;

  const options = [
    {
      key: "barcode exports",
      text: `Barcode exports`,
      value: "barcode exports",
      onClick: onbarcodeExport,
    },
    {
      key: "barcode and finance exports",
      text: `Barcode and Finance exports`,
      value: "barcode and finance exports",
      onClick: onbarcodeAndFinanceExport,
    },
  ];
  if (!isProd()) {
    return (
      <OutlinedButton disabled={noCampaignExport}>
        Download campaign exports
        <Dropdown
          style={{ color: "#f06c00", backgroundColor: "#FDF5F1" }}
          disabled={noCampaignExport}
          className="button icon"
          options={options}
          trigger={<div />}
        />
      </OutlinedButton>
    );
  } else {
    return "";
  }
};

const CampaignTable = (props) => (
  <table className="ln-c-table" celled>
    {props.children}
  </table>
);
const CampaignTableBody = (props) => (
  <tbody className="ln-c-table__body">{props.children}</tbody>
);

const PageControls = (props) => {
  const { onPageChange, currentPage, totalPages } = props;
  const hasNextPage = currentPage < totalPages;
  const hasPreviousPage = currentPage > 1;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <OutlinedButton
          name="backward"
          className="hoverCursor"
          onClick={() => hasPreviousPage && onPageChange(Direction.BACKWARD)}
          disabled={!hasPreviousPage}
        >
          <ArrowLeft />
        </OutlinedButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          Page {currentPage} of {totalPages}
        </div>
        <OutlinedButton
          name="forward"
          className="hoverCursor"
          onClick={() => hasNextPage && onPageChange(Direction.FORWARD)}
          disabled={!hasNextPage}
          style={{ marginLeft: "20px" }}
        >
          <ArrowRight />
        </OutlinedButton>
      </div>
    </div>
  );
};

const createQualificationMessage = (qualification) => {
  let qualificationMsg = "";
  if (qualification.unit != null || qualification.unit !== undefined) {
    qualificationMsg += `per ${
      qualification.step
    } ${qualification.unit.toLocaleLowerCase()} for `;
  }
  if (
    qualification.qualifyingSpend != null ||
    qualification.qualifyingSpend !== undefined
  ) {
    qualificationMsg += `${qualification.qualifyingSpend} pence`;
  } else {
    qualificationMsg += `${qualification.skus.length} sku${
      qualification.skus.length > 1 ? "s" : ""
    }`;
  }

  return qualificationMsg;
};

const CampaignRow = (props) => {
  const { campaign, onClick } = props;
  const qualification = createQualificationMessage(campaign);
  const errors = [];

  const isSelected = props.isSelected(campaign.id);
  return (
    <tr
      className="ln-c-table__row ln-c-table__header-row"
      positive={props.isPresent(campaign.id)}
    >
      <td className="ln-c-table__cell">
        <h4 style={{ display: "flex" }}>
          <Image
            src={
              !isEmpty(campaign.artworkUrl)
                ? campaign.artworkUrl
                : PlaceholderImage
            }
            size="mini"
          />
          <Button
            style={{ "min-height": "48px", "border-radius": "2px" }}
            onClick={onClick}
          >
            {campaign.name ? campaign.name : "Undefined"}
            {isEmpty(errors) ? null : (
              <span>
                &nbsp;
                <Icon
                  title="Invalid campaign"
                  name="warning sign"
                  color="orange"
                />
              </span>
            )}
          </Button>
        </h4>
      </td>
      <td className="ln-c-table__cell">{campaign.accountClientType}</td>
      <td className="ln-c-table__cell">
        {defaultDateTimeFormat(campaign.startDate)}
      </td>
      <td className="ln-c-table__cell">
        {defaultDateTimeFormat(campaign.endDate)}
      </td>
      <td className="ln-c-table__cell">{qualification}</td>
      <td className="ln-c-table__cell">
        [{campaign.minimumPoints}, {campaign.maximumPoints}]
      </td>
      <td className="ln-c-table__cell">{campaign.id}</td>
      <td className="ln-c-table__cell" collapsing>
        <div style={{ display: "flex" }}>
          <Button
            style={{ "min-height": "48px", "border-radius": "2px" }}
            onClick={() => props.deleteCampaign(campaign.id, campaign.name)}
            disabled={props.activeTabIndex === 1}
            title="Delete"
          >
            <Cancel />
          </Button>
          <Button
            style={{ "min-height": "48px", "border-radius": "2px" }}
            disabled={!isEmpty(errors)}
            color={isSelected ? "orange" : "grey"}
            icon="check"
            onClick={() => props.stage(campaign, !isSelected)}
            title="Active"
          >
            <Tick />
          </Button>
        </div>
      </td>
    </tr>
  );
};

const ErrorLoadingCampaigns = (props) => (
  <Message negative>
    <p>{props.message}</p>
  </Message>
);

CampaignsView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(CampaignsView);
