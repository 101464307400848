import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Checkbox } from "semantic-ui-react";

export class GroupRow extends Component {
  state = {
    hover: false,
  };

  handleCheck = () => {
    this.props.onCheck(this.props.group);
  };

  handleRowSelect = () => {
    this.props.onSelect(this.props.group);
  };

  handleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    return (
      <tr
        className="ln-c-table__row"
        style={{
          backgroundColor: this.props.isSelected
            ? "silver"
            : this.state.hover
            ? "#FFFAF7"
            : "white",
        }}
        onMouseOver={this.handleHover}
        onMouseOut={this.handleHover}
      >
        <td class="ln-c-table__cell" onClick={this.handleCheck}>
          <Checkbox checked={this.props.isSelected} />
        </td>
        <td class="ln-c-table__cell" onClick={this.handleRowSelect}>
          {this.props.group.name}
        </td>
        <td class="ln-c-table__cell" onClick={this.handleRowSelect}>
          {this.props.group.status}
        </td>
      </tr>
    );
  }
}

export default withRouter(GroupRow);
