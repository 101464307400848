import React from "react";

import { GridItem, GridWrapper } from "@jsluna/grid";

import WelcomeLogo from ".././assets_V2/WelcomeLogo.png";
import { getSessionCookieData } from "../services/sessionCookie";
import "./Home.scss";

const Home = () => {
  const { permissions } = getSessionCookieData();

  const hasPermissions = permissions.length > 0;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "85vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ minWidth: 500, maxWidth: 720 }}>
        <GridWrapper
          className="ln-o-grid ln-o-grid--middle"
          horizontalAlign="center"
          verticalAlign="middle"
        >
          <GridItem className="ln-u-text-align-center" size={{ md: "1" }}>
            <div className="ln-u-soft">
              <img
                src={WelcomeLogo}
                className="logoAnimate"
                alt=""
                width="100"
                height="100"
              />
            </div>
          </GridItem>
          <GridItem
            className="welcomeMsgTxt ln-u-text-align-center"
            size={{ md: "1" }}
          >
            {hasPermissions ? (
              <h4>
                Welcome back.
                <br />
                You have access to the areas listed in the menu above.
              </h4>
            ) : (
              <h4>
                You are registered.
                <br />
                An administrator will grant you access shortly.
              </h4>
            )}
          </GridItem>
        </GridWrapper>
      </div>
    </div>
  );
};

export default Home;
