import React from "react";
import { Form, Message } from "semantic-ui-react";

import { FilledButton, OutlinedButton } from "@jsluna/button";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Basket } from "@jsluna/icons";

import ProductClient from "../../services/ProductClient";

const productClient = new ProductClient();

export default class SkusView extends React.Component {
  state = {
    products: {},
    newSku: "",
    newSkuLookup: [],
    typingTimeout: undefined,
  };

  loadProduct(sku) {
    productClient
      .fetchProductsBySku(sku)
      .then(({ data }) => {
        let loadedSkus = this.state.products;
        loadedSkus[sku] = data;
        this.setState({ products: loadedSkus });
      })
      .catch((err) => console.log(err));
  }

  handleNewSku = (event) => {
    this.setState({ newSku: event.target.value }, () => {
      if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout);
      }

      const self = this;
      this.setState({
        typingTimeout: setTimeout(function () {
          self.lookUpNewSku();
        }, 500),
      });
    });
  };

  lookUpNewSku = async () => {
    if (this.state.newSku !== "") {
      const { data } = await productClient.fetchProductsBySku(
        this.state.newSku
      );
      this.setState({ newSkuLookup: data });
    } else {
      this.setState({ newSkuLookup: [] });
    }
  };

  addNewSku = () => {
    if (this.isValidSku()) {
      this.props.addNewSku(this.state.newSku);
      this.setState({
        newSku: "",
        newSkuLookup: [],
      });
    }
  };

  isValidSku = () => {
    return this.state.newSkuLookup.length > 0;
  };

  render() {
    const skusRows = this.props.skus.map((sku, i) => (
      <tr className="ln-c-table__row ln-c-table__header-row" key={i}>
        <td className="ln-c-table__cell">{sku}</td>
        <td className="ln-c-table__cell">
          {this.state.products[sku] ? (
            <Message>
              {this.state.products[sku].map((p, i) => (
                <pre key={i}>
                  <code>{JSON.stringify(p, null, 4)}</code>
                </pre>
              ))}
            </Message>
          ) : (
            <OutlinedButton
              color={"blue"}
              onClick={() => this.loadProduct(sku)}
            >
              Show details
            </OutlinedButton>
          )}
        </td>
        <td className="ln-c-table__cell">
          <FilledButton color={"red"} onClick={() => this.props.removeSku(sku)}>
            Remove
          </FilledButton>
        </td>
      </tr>
    ));

    return (
      <div>
        <div>
          <span>
            <Basket />
            &nbsp;&nbsp;<Label>Skus</Label>
          </span>
        </div>

        <div style={{ paddingTop: "10px", width: "100%" }}>
          <Form
            onSubmit={this.addNewSku}
            style={{ width: "50%", display: "inline-flex" }}
          >
            <GridWrapper>
              <GridItem size={{ xs: "1/4" }}>
                <Label>Add new SKU</Label>
                <Form.Input
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    boxShadow: "0",
                    fontFamily: "sans-serif",
                    border: "1px solid #737373",
                    borderRadius: "4.571px",
                    color: "#737373",
                    minHeight: "48px",
                  }}
                  name={"newSku"}
                  onChange={this.handleNewSku}
                  value={this.state.newSku}
                />
              </GridItem>
              <FilledButton
                style={{ margin: "28px 0 0 15px", height: "38px" }}
                primary
                size="small"
                type="submit"
                disabled={!this.isValidSku()}
              >
                Add
              </FilledButton>
            </GridWrapper>
          </Form>
          <div style={{ margin: "15px 0" }}>
            {this.isValidSku() && (
              <Message>
                {this.state.newSkuLookup.map((p, i) => (
                  <pre
                    key={i}
                    style={{
                      border: "1px solid #737373",
                      borderRadius: "3px",
                      boxShadow: "none",
                      width: "100%",
                    }}
                  >
                    <code>{JSON.stringify(p, null, 4)}</code>
                  </pre>
                ))}
              </Message>
            )}
          </div>
        </div>

        <div
          style={{
            border: "1px solid #737373",
            borderRadius: "3px",
            boxShadow: "none",
            width: "100%",
          }}
        >
          <table className="ln-c-table" celled striped>
            <thead className="ln-c-table__header">
              <tr className="ln-c-table__row ln-c-table__header-row">
                <th className="ln-c-table__header-cell customCol">
                  <Label>Sku</Label>
                </th>
                <th className="ln-c-table__header-cell customCol">
                  <Label>Sku Info</Label>
                </th>
                <th className="ln-c-table__header-cell customCol">
                  <Label>Remove</Label>
                </th>
              </tr>
            </thead>
            <tbody className="ln-c-table__body">
              {this.props.skus.length >= 0 ? (
                skusRows
              ) : (
                <tr className="ln-c-table__row ln-c-table__header-row">
                  <td className="ln-c-table__cell">No skus to show</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
