import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Alert, AlertIcon } from "@jsluna/alert";
import { Button, IconButton, OutlinedButton } from "@jsluna/button";
import { Form, FormGroup, SearchField } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import { Download, ErrorCircle, GridView, Search, Upload } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";
import { Text } from "@jsluna/typography";

import { InlineGroup } from "../../common/components/InlineGroup";
import NoResultFound from "../../common/components/NoResultFound";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Pagination from "../../common/components/Pagination";
import StatusIndicator from "../../common/components/StatusIndicator";
import { triggerDownload } from "../../common/components/downloadUtils";
import { toDisplayString } from "../../common/dates";
import { useAsyncActionState } from "../../common/hooks/useAsyncActionState";
import { useSearch } from "../../common/hooks/useSearch";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import CampaignClient from "../../services/CampaignClient";
import { LoadingState } from "../../services/http";
import "./CampaignUploads.scss";

// If any significant refactoring is needed, consider combining this with the promotion upload page
// to make a single generic child component, unless there is significant divergence in the meantime

const apiClient = new CampaignClient();

const getDashboardData = async (page, filter) => {
  const response = await apiClient.getBulkUploads(page, filter.filename);
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
};

const uploadFile = async (file) => {
  try {
    const response = await apiClient.bulkUpload(file);
    if (response.status !== 200) {
      throw new Error(response);
    }
    toast.success("Upload Success");
  } catch (err) {
    console.error(err);
    const message = err?.response?.data?.description ?? "";
    toast.error("Failed to upload file. " + message);
    throw err;
  }
};

const downloadFailedRecords = async (id, fileName) => {
  try {
    const downloadName = `${fileName}_failed_records.csv`;
    const response = await apiClient.downloadFailedBulkUploadRecords(id);
    if (response.status !== 200) {
      throw new Error(response);
    }
    triggerDownload(downloadName, response.data);
    toast.success("Download Success");
  } catch (err) {
    console.error(err);
    const message = err?.response?.data?.description ?? "";
    toast.error("Failed to download failed records. " + message);
    throw err;
  }
};

const downloadSuccessfulRecords = async (id, fileName) => {
  try {
    const barcodeResponse =
      await apiClient.downloadSuccessfulBulkUploadBarcodeRecords(id);
    if (barcodeResponse.status !== 200) {
      throw new Error(barcodeResponse);
    }
    triggerDownload(
      `${fileName}_successful_barcode_records.csv`,
      barcodeResponse.data
    );
    const financeResponse =
      await apiClient.downloadSuccessfulBulkUploadFinanceRecords(id);
    if (financeResponse.status !== 200) {
      throw new Error(financeResponse);
    }
    triggerDownload(
      `${fileName}_successful_finance_records.csv`,
      financeResponse.data
    );
    toast.success("Download Success");
  } catch (err) {
    console.error(err);
    const message = err?.response?.data?.description ?? "";
    toast.error("Failed to download successful records. " + message);
    throw err;
  }
};

const UploadSearchField = ({
  onSubmit,
  disabled,
  searchValue,
  onSearchValueChanged,
  valueName,
  hideLabel,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup
        name={"Search_campaigns_" + valueName + "_form"}
        hideLabel
        label={"Search bulk uploads " + valueName}
      >
        <SearchField
          name={valueName}
          label={valueName}
          hideLabel={hideLabel}
          placeholder={"Search bulk uploads by " + valueName}
          hasAction
          onChange={onSearchValueChanged}
          value={searchValue}
          action={
            <Button disabled={disabled} type="submit">
              <span className="ln-u-visually-hidden">Search</span>
              <Search />
            </Button>
          }
        />
      </FormGroup>
    </Form>
  );
};

const CampaignUploads = () => {
  const fileUploadInputRef = useRef(null);
  const {
    requestedPage,
    listResults,
    listState,
    listReady,
    reloadPage,
    changePage,
    newSearch,
  } = useSearch(getDashboardData);

  const searchDisabled = listState === LoadingState.IN_PROGRESS;
  const listError = listState === LoadingState.FAILED;
  const showResults = listReady && listResults.content;

  const [searchFile, setSearchFile] = useState("");

  const [actionState, performAction] = useAsyncActionState();
  const actionInProgress = actionState === LoadingState.IN_PROGRESS;
  const showLoading =
    listState === LoadingState.IN_PROGRESS || actionInProgress;

  const isCmpgnEditor = HasRequiredRoleAccess("campaigns");

  const onUploadFileChange = async (e) => {
    e.preventDefault();
    if (actionInProgress) {
      return;
    }
    await performAction(() => uploadFile(e.target.files[0]));
    await reloadPage(); // Reload results after an upload to display progress
  };

  const onUploadClick = (e) => {
    fileUploadInputRef.current.value = null;
    fileUploadInputRef.current.click();
  };

  const onSearchSubmitted = (e) => {
    e.preventDefault();
    if (searchDisabled) {
      return;
    }
    return newSearch({ filename: searchFile });
  };

  return (
    <>
      <div className="campaign-upload-dashboard">
        <PageContainer>
          <GridItem size={{ md: "1/2" }}>
            <PageHeader>
              <GridView />
              Campaign Bulk Upload Dashboard
            </PageHeader>
          </GridItem>
          <GridItem size={{ md: "1/2" }}>
            <InlineGroup alignEnd>
              <input
                id="bulk-upload-file"
                data-testid="bulk-upload-file"
                type="file"
                hidden
                name="file"
                onChange={onUploadFileChange}
                disabled={!isCmpgnEditor || actionInProgress}
                ref={fileUploadInputRef}
              />
              <IconButton
                onClick={onUploadClick}
                variant="outlined"
                disabled={!isCmpgnEditor || actionInProgress}
                label="Bulk Upload"
              >
                <Upload />
              </IconButton>
              <OutlinedButton to="/campaigns" element={Link}>
                Close
              </OutlinedButton>
            </InlineGroup>
          </GridItem>
          <GridItem size="1/1">
            {listError && (
              <Alert variant="error">
                <AlertIcon>
                  <ErrorCircle aria-label="Error" role="img" />
                </AlertIcon>
                Oops! Something has gone wrong. Please try again, and if this
                continues to happen, please contact Tech Support.
              </Alert>
            )}
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <UploadSearchField
              onSubmit={onSearchSubmitted}
              disabled={searchDisabled}
              searchValue={searchFile}
              onSearchValueChanged={(e) => setSearchFile(e.target.value)}
              valueName="file name"
              hideLabel
            />
          </GridItem>
          {!showResults ? null : listResults.content.length > 0 ? (
            <>
              <GridItem size="1/1">
                <Table
                  className="campaign-upload-dashboard-results"
                  data={listResults.content}
                  rowKey="id"
                  caption="Bulk Uploads"
                  visuallyHiddenCaption
                  columns={[
                    {
                      name: "File Name",
                      accessor: (item) => ({ value: item.file_name }),
                    },
                    {
                      name: "Date Created",
                      accessor: (item) => ({
                        value: toDisplayString(item.created_at),
                      }),
                    },
                    {
                      name: "Status",
                      accessor: (item) => ({
                        value: (
                          <StatusIndicator
                            variant={
                              item.status === "Failed"
                                ? "error"
                                : item.complete
                                ? "alpha"
                                : "disabled"
                            }
                            text={item.status}
                          />
                        ),
                      }),
                    },
                    {
                      name: "Total",
                      accessor: (item) => ({ value: item.total }),
                    },
                    {
                      name: "Successful",
                      accessor: (item) => ({
                        value: item.complete && (
                          <>
                            <Text element="span">{item.succeeded}</Text>
                            {item.succeeded > 0 && (
                              <IconButton
                                onClick={async () =>
                                  await performAction(() =>
                                    downloadSuccessfulRecords(
                                      item.id,
                                      item.file_name
                                    )
                                  )
                                }
                                variant="text"
                                label="Download successful uploads"
                                hideLabel
                              >
                                <Download />
                              </IconButton>
                            )}
                          </>
                        ),
                      }),
                    },
                    {
                      name: "Failed",
                      accessor: (item) => ({
                        value: item.complete && (
                          <>
                            <Text element="span">{item.failed}</Text>
                            {item.failed > 0 && (
                              <IconButton
                                onClick={async () =>
                                  await performAction(() =>
                                    downloadFailedRecords(
                                      item.id,
                                      item.file_name
                                    )
                                  )
                                }
                                variant="text"
                                label="Download failed uploads"
                                hideLabel
                              >
                                <Download />
                              </IconButton>
                            )}
                          </>
                        ),
                      }),
                    },
                  ]}
                />
              </GridItem>
              {listResults.totalPages > 0 && (
                <GridItem size="1/1">
                  <Pagination
                    currentPage={requestedPage}
                    totalPages={listResults.totalPages}
                    onPageChange={changePage}
                  />
                </GridItem>
              )}
            </>
          ) : (
            <NoResultFound />
          )}
        </PageContainer>
      </div>
      {showLoading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};

export default CampaignUploads;
