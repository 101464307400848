import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Checkbox } from "semantic-ui-react";

export class ProductRow extends Component {
  static propTypes = {
    product: PropTypes.shape({
      eanDescription: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      segmentName: PropTypes.string.isRequired,
      subCategoryName: PropTypes.string.isRequired,
      categoryName: PropTypes.string.isRequired,
      superCategoryName: PropTypes.string.isRequired,
    }).isRequired,
    onProductSelected: PropTypes.func.isRequired,
  };

  state = {
    hover: false,
  };

  handleOnCheckboxClick = () => {
    this.props.onProductSelected(this.props.product);
  };

  handleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    return (
      <tr
        className="ln-c-table__row"
        style={{
          backgroundColor: this.props.isSelected
            ? "silver"
            : this.state.hover
            ? "#FFFAF7"
            : "white",
        }}
        onMouseOver={this.handleHover}
        onMouseOut={this.handleHover}
        onClick={this.handleOnCheckboxClick}
      >
        <td class="ln-c-table__cell" collapsing>
          <Checkbox checked={this.props.isSelected} />
        </td>
        <td class="ln-c-table__cell">{this.props.product.eanDescription}</td>
        <td class="ln-c-table__cell">{this.props.product.sku}</td>
        <td class="ln-c-table__cell">{this.props.product.superCategoryName}</td>
        <td class="ln-c-table__cell">{this.props.product.categoryName}</td>
        <td class="ln-c-table__cell">{this.props.product.subCategoryName}</td>
        <td class="ln-c-table__cell">{this.props.product.segmentName}</td>
      </tr>
    );
  }
}

export default withRouter(ProductRow);
