import React from "react";

import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import Lloyds from "./LloydsBaserateView";

class BaserateView extends React.Component {
  state = {
    activeItem: "Lloyds",
  };
  setActiveTab = (val) => {
    this.setState({ activeItem: val });
  };

  render() {
    const activeItem = this.state.activeItem;
    const panes = [{ name: "Lloyds", key: "Lloyds" }];

    return (
      <Card style={{ width: "100%" }}>
        <GridWrapper>
          <GridItem>
            <div>
              <Tabs type="pill">
                {panes.map((tab) => (
                  <TabLink
                    key={tab.key}
                    onClick={() => this.setActiveTab(tab.key)}
                    active={activeItem === tab.key}
                  >
                    {tab.name}
                  </TabLink>
                ))}
              </Tabs>
              <TabPanel>
                <Card
                  style={{
                    border: "1px solid #d8d8d8",
                    borderRadius: "3px",
                    margin: "1rem 0 1rem 0",
                  }}
                >
                  {activeItem === "Lloyds" ? <Lloyds /> : null}
                </Card>
              </TabPanel>
            </div>
          </GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

export default BaserateView;
