import { isEmpty } from "lodash";
import React from "react";
import { Form, Grid, Icon, Image, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Modal, ModalHeading } from "@jsluna/modal";

import { toApiFormat } from "../../common/dates";
import CampaignClient from "../../services/CampaignClientLegacy";
import SkuClient from "../../services/SkuClient";
import { LoadingState } from "../../services/http";
import { getSessionCookieData } from "../../services/sessionCookie";
import CampaignEditorTabView from "./CampaignEditorTabView";
import MobilePreview from "./MobilePreview";
import MobilePreviewCFC from "./MobilePreviewCFC";
import SkusView from "./SkusView";
import { InitialState, PlaceholderImage } from "./campaign";

const campaignClient = new CampaignClient();
const skuClient = new SkuClient();

export default class CampaignEditor extends React.Component {
  state = {
    allowedFields: [],
    loadingState: undefined,
    campaign: {
      name: "",
      tags: [],
    },
    error: undefined,
    campaignUpdateError: undefined,
    campaignUpdateSuccess: undefined,
    isCommitModalVisible: false,
    isRejectModalVisible: false,
    rejectionFeedback: "No feedback given.",
    commitTrigger: undefined,
    productCategories: [],
  };

  componentDidMount() {
    this.loadCampaign();
  }

  getCampaignId = () => {
    return this.props.campaignId;
  };

  isNewCampaign = () => {
    return this.getCampaignId() === "new";
  };

  handleChangeDate = (value, field) => {
    const date = toApiFormat(value);
    this.setState({
      campaign: { ...this.state.campaign, [field]: date },
    });
  };

  handleSkuChange = (e, { name, value }) => {
    let skus = (Array.isArray(value) ? value : value.split(","))
      .map((v) => v.trim())
      .filter((v) => !isEmpty(v));
    skus = skus.map((v) => v.toLowerCase());
    this.setState({
      campaign: {
        ...this.state.campaign,
        [name]: skus,
      },
    });
  };

  handleChange = (e, input) => {
    this.clearMessages(() => {
      this.setState(
        ({ campaign }) => {
          if (typeof input === "function") {
            return { campaign: input(campaign) };
          } else {
            return { campaign: { ...campaign, [input.name]: input.value } };
          }
        },
        () => {
          if (typeof input === "object") {
            const { name, value } = input;
            if (name === "campaignType" || name === "accountClientType") {
              this.fetchAllowedFields();
            }
            if (name === "trackerType") {
              this.refreshProductCategories();
              this.clearProductCategory();
              this.clearSkus();
            }
            if (
              name === "productCategory" &&
              (this.state.campaign.trackerType === "BADGE" ||
                this.state.campaign.trackerType === "COUNTER")
            ) {
              this.refreshSkus(this.state.campaign.trackerType, value);
            }
          }
        }
      );
    });
  };

  refreshProductCategories = () => {
    if (this.state.campaign.trackerType === "BADGE") {
      this.calculateProductCategoriesForBadge();
    }
  };

  loadCampaign = () => {
    if (this.isNewCampaign()) {
      this.setState({ campaign: InitialState() }, this.fetchAllowedFields);
    } else {
      campaignClient
        .fetchCampaign(this.getCampaignId())
        .then(({ data }) => {
          let campaign = this.setDefaultsForNullValues(data);
          this.setState({ campaign: campaign }, this.fetchAllowedFields);
          this.refreshProductCategories();
        })
        .catch((err) => {
          console.log(err);
          this.clearMessages(() => {
            this.setState({ campaignUpdateError: "Failed to load campaign" });
          });
        });
    }
  };

  setDefaultsForNullValues = (campaign) => {
    if (campaign.allowOverride === undefined) {
      campaign.allowOverride = true;
    }
    if (campaign.limitMaxRedemptions === undefined) {
      campaign.limitMaxRedemptions = false;
    }
    if (campaign.redemptionPeriod === undefined) {
      campaign.redemptionPeriod = "REDEMPTION_CALENDAR_DAY";
    }
    if (campaign.maxRedemptionsPerPeriod === undefined) {
      campaign.maxRedemptionsPerPeriod = 3;
    }

    if (campaign.startDateBehaviour === undefined) {
      campaign.startDateBehaviour = "START_WHEN_ISSUED";
    }

    if (campaign.endDateBehaviour === undefined) {
      campaign.endDateBehaviour = "END_DATE_OF_CAMPAIGN";
    }

    return campaign;
  };

  clearProductCategory() {
    this.setState({
      campaign: {
        ...this.state.campaign,
        productCategory: "",
      },
    });
  }

  clearSkus() {
    this.setState({
      campaign: {
        ...this.state.campaign,
        skus: [],
      },
    });
  }

  refreshSkus(trackerType, value) {
    if (trackerType === "COUNTER") {
      value = value === "TOTALFRUIT" ? "fruit" : "vegetable";
    }
    this.setState({
      campaign: {
        ...this.state.campaign,
        skus: [value],
      },
    });
  }

  calculateProductCategoriesForBadge() {
    skuClient
      .fetchSkus()
      .then(({ data }) => {
        let productCategories = data
          .filter((record) => !!record.targetSkuIds)
          .map((eachRecord) => {
            return eachRecord.targetSkuIds;
          })
          .flat()
          .map((category) => {
            return {
              key: category,
              text: category,
              value: category,
            };
          })
          .reduce((categories, current) => {
            const isAlreadyInTheArray = categories.find(
              (item) => item.key === current.key
            );
            if (!isAlreadyInTheArray) {
              return categories.concat([current]);
            } else {
              return categories;
            }
          }, []);

        productCategories.unshift({
          text: "No Category",
          value: "",
        });

        this.setState({
          campaign: {
            ...this.state.campaign,
            productCategories: productCategories,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.clearMessages(() => {
          this.setState({ skuUpdateError: "Failed to load skus" });
        });
      });
  }

  clearMessages(cb) {
    this.setState(
      {
        campaignUpdateError: undefined,
        campaignUpdateSuccess: undefined,
      },
      cb
    );
  }

  fetchAllowedFields() {
    const type = this.state.campaign.campaignType;
    const accountClientType = this.state.campaign.accountClientType;
    if (type) {
      campaignClient
        .fetchCampaignFields(type, accountClientType)
        .then((res) => {
          this.setState({ allowedFields: res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  isInvalidOfferEndDate = (
    offerEndDateBehaviour,
    offerEndDateStr,
    campaignEndDateStr
  ) => {
    if (offerEndDateBehaviour !== "END_ON_SPECIFIED_DATE") {
      return false;
    }
    const offerEndDate = new Date(offerEndDateStr);
    const campaignEndDate = new Date(campaignEndDateStr);
    return offerEndDate && offerEndDate.getTime() > campaignEndDate.getTime();
  };

  isInvalidOfferStartDate = (
    offerStartDateBehaviour,
    offerStartDateStr,
    campaignStartDateStr
  ) => {
    if (offerStartDateBehaviour !== "START_ON_SPECIFIED_DATE") {
      return false;
    }
    const offerStartDate = new Date(offerStartDateStr);
    const campaignStartDate = new Date(campaignStartDateStr);
    return (
      offerStartDate && offerStartDate.getTime() < campaignStartDate.getTime()
    );
  };

  handleSave = () => {
    if (
      this.isInvalidOfferEndDate(
        this.state.campaign.endDateBehaviour,
        this.state.campaign.offerEndDate,
        this.state.campaign.endDate
      )
    ) {
      let msg = "Offer End date cannot be after Campaign End date";
      this.clearMessages(() => {
        this.setState({ campaignUpdateError: msg });
      });
      return;
    }
    if (
      this.isInvalidOfferStartDate(
        this.state.campaign.startDateBehaviour,
        this.state.campaign.offerStartDate,
        this.state.campaign.startDate
      )
    ) {
      let msg = "Offer Start date cannot be before Campaign Start date";
      this.clearMessages(() => {
        this.setState({ campaignUpdateError: msg });
      });
      return;
    }
    if (this.state.commitTrigger) {
      this.setState({ commitTrigger: undefined });
    } else {
      this.clearMessages();
      this.setState({ loadingState: LoadingState.PENDING });

      if (this.isNewCampaign()) {
        this.createCampaign();
      } else {
        this.updateCampaign();
      }
    }
  };

  createCampaign = () => {
    const { username } = getSessionCookieData();
    this.setState(
      {
        campaign: {
          ...this.state.campaign,
          createdBy: username,
        },
      },
      () => {
        campaignClient
          .createCampaign(this.state.campaign)
          .then(() => {
            this.props.close();
          })
          .catch((err) => {
            this.clearMessages(() => {
              this.setState({
                campaignUpdateError: err.response.data.description,
                loadingState: LoadingState.FAILED,
              });
            });
          });
      }
    );
  };

  updateCampaign = () => {
    campaignClient
      .updateCampaign(this.state.campaign)
      .then(() => {
        this.loadCampaign();
        this.setState({
          campaignUpdateSuccess: true,
          loadingState: LoadingState.SUCCESS,
        });
      })
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({
            campaignUpdateError: err.response.data.description,
            loadingState: LoadingState.FAILED,
            isCommitModalVisible: false,
          });
        });
      });
  };

  commitCampaign = () => {
    const id = this.props.campaignId;
    this.setState({ loadingState: LoadingState.PENDING });
    campaignClient
      .updateCampaign(this.state.campaign)
      .then(() => {
        campaignClient
          .commitCampaign(id)
          .then(() => {
            this.props.close();
          })
          .catch((err) => {
            this.clearMessages(() => {
              this.setState({
                campaignUpdateError: err.response.data.description,
                loadingState: LoadingState.FAILED,
              });
            });
            this.setCommitModalVisible(false);
          });
      })
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({
            campaignUpdateError: err.response.data.description,
            loadingState: LoadingState.FAILED,
            isCommitModalVisible: false,
          });
        });
      });
  };

  bindCampaignForm = () => {
    this.setState({ campaignFormRef: this.refs.campaignForm.value });
  };

  deleteCampaign = () => {
    const id = this.state.campaign.id;
    campaignClient
      .deleteCampaign(id)
      .then(() => this.props.close())
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({
            campaignUpdateError: err.response.data.description,
            loadingState: LoadingState.FAILED,
          });
        });
      });
  };

  rejectCampaign = () => {
    const id = this.state.campaign.id;
    const feedback = { feedback: this.state.rejectionFeedback };

    campaignClient
      .rejectCampaign(id, feedback)
      .then(() => {
        this.props.close();
      })
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({
            campaignUpdateError: err.response.data.description,
            loadingState: LoadingState.FAILED,
          });
        });
        this.setRejectModalVisible(false);
      });
  };

  setRejectModalVisible = (isVisible) => {
    this.setState({ isRejectModalVisible: isVisible });
  };

  setCommitModalVisible = (isVisible) => {
    this.setState({ isCommitModalVisible: isVisible });
  };

  shouldRenderSkus(campaign) {
    return (
      this.state.campaign.accountClientType === "NECTAR_PRICE" ||
      (!this.isNewCampaign() && campaign.skus && !isEmpty(campaign.skus))
    );
  }

  static renderValidationErrorMessages(messages) {
    let errors = messages.map((error, idx) => {
      return (
        <div key={idx} style={{ color: "red", width: "100%" }}>
          {error}
        </div>
      );
    });

    return (
      <div
        className="errorMessages"
        style={{ display: "inline-grid", paddingLeft: "50px" }}
      >
        {errors}
      </div>
    );
  }

  static isAnyNonNull(fields) {
    return fields.some((f) => {
      return f != null;
    });
  }

  addNewSku = (sku) => {
    const skus = this.state.campaign.skus;
    const updatedSkus = skus.concat(sku);
    const uniqueUpdatedSkus = [...new Set(updatedSkus)];

    this.setState({
      campaign: { ...this.state.campaign, skus: uniqueUpdatedSkus },
    });
  };

  removeSku = (sku) => {
    const skus = this.state.campaign.skus;
    const updatedSkus = skus.filter((e) => e !== sku);

    this.setState({
      campaign: { ...this.state.campaign, skus: updatedSkus },
    });
  };

  render() {
    const {
      error,
      campaign,
      campaignUpdateError,
      campaignUpdateSuccess,
      loadingState,
    } = this.state;

    if (!campaign && !error) {
      return null;
    }

    return (
      <div>
        <Grid columns={2} divided stackable centered>
          <Grid.Row>
            <Grid.Column width={12}>
              <div>
                <ButtonGroupWrapper>
                  <ButtonGroupSecondary>
                    <OutlinedButton
                      type="button"
                      size="small"
                      onClick={() => this.props.close()}
                    >
                      Close
                    </OutlinedButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </ButtonGroupSecondary>
                  <ButtonGroupPrimary>
                    <OutlinedButton
                      primary
                      size="small"
                      type="submit"
                      disabled={this.props.isActive}
                      loading={loadingState === LoadingState.PENDING}
                      onClick={this.handleSave}
                    >
                      Save
                    </OutlinedButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.isNewCampaign() ? null : (
                      <FilledButton
                        type="submit"
                        size="small"
                        color="green"
                        onClick={() => {
                          this.setState({ commitTrigger: true });
                          this.setCommitModalVisible(true);
                        }}
                      >
                        Commit
                      </FilledButton>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.isNewCampaign() ? null : (
                      <FilledButton
                        type="button"
                        size="small"
                        color="orange"
                        disabled={this.props.isActive}
                        onClick={() => {
                          this.setRejectModalVisible(true);
                        }}
                      >
                        Reject
                      </FilledButton>
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.isNewCampaign() ? null : (
                      <FilledButton
                        type="button"
                        size="small"
                        color="red"
                        onClick={this.deleteCampaign}
                        disabled={this.props.isActive}
                      >
                        Delete
                      </FilledButton>
                    )}
                  </ButtonGroupPrimary>
                </ButtonGroupWrapper>
              </div>
              <CampaignEditorTabView
                allowedFields={this.state.allowedFields}
                campaign={campaign}
                campaignTags={this.props.campaignTags}
                onAddTag={this.props.createCampaignTag}
                handleChange={this.handleChange}
                handleChangeDate={this.handleChangeDate}
                handleSkuChange={this.handleSkuChange}
                isNewCampaign={this.isNewCampaign}
              />

              <br />
            </Grid.Column>

            <Grid.Column verticalAlign="middle" width={4}>
              <Image
                fluid
                src={
                  !isEmpty(campaign.artworkUrl)
                    ? campaign.artworkUrl
                    : PlaceholderImage
                }
              />
              {this.state.campaign.offerCode === "PIANOCFC" ? (
                <MobilePreviewCFC campaign={this.state.campaign} />
              ) : (
                this.state.campaign.campaignType === "INCENTIVE" &&
                this.state.campaign.accountClientType === "CHALLENGE" && (
                  <MobilePreview campaign={this.state.campaign} />
                )
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {campaignUpdateError ? (
          <Message attached="bottom" error>
            <Icon name="warning circle" />
            {campaignUpdateError}
          </Message>
        ) : null}

        {campaignUpdateSuccess ? (
          <Message attached="bottom" success>
            <Icon name="check" />
            Updated the campaign
          </Message>
        ) : null}

        {this.state.isCommitModalVisible ? (
          <Modal
            open={this.state.isCommitModalVisible}
            fullScreen
            restrictClose
            alert
            headingId="dialog-modal"
          >
            <ModalHeading element="h3">
              Are you sure? This will make the campaign live!
            </ModalHeading>
            <ButtonGroupPrimary>
              <FilledButton onClick={this.commitCampaign}>Commit</FilledButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <OutlinedButton
                className="ln-u-margin-right"
                onClick={() => this.setCommitModalVisible(false)}
              >
                Close
              </OutlinedButton>
            </ButtonGroupPrimary>
          </Modal>
        ) : (
          ""
        )}

        {this.state.isRejectModalVisible ? (
          <Modal
            open={this.state.isRejectModalVisible}
            fullScreen
            restrictClose
            alert
            headingId="dialog-modal"
          >
            <ModalHeading element="h3">
              Please provide feedback on why you are rejecting this campaign.
            </ModalHeading>
            <Form>
              <Form.Field>
                <div>Feedback:</div>
                <br />
                <textarea
                  name="feedback"
                  placeholder="Your feedback here..."
                  onChange={(e, { name, value }) => {
                    this.setState({ rejectionFeedback: value });
                  }}
                />
              </Form.Field>
            </Form>
            <br />
            <ButtonGroupPrimary>
              <FilledButton
                type={"button"}
                color={"red"}
                onClick={this.rejectCampaign}
              >
                Reject
              </FilledButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <OutlinedButton
                type={"button"}
                onClick={() => this.setRejectModalVisible(false)}
              >
                Close
              </OutlinedButton>
            </ButtonGroupPrimary>
          </Modal>
        ) : (
          ""
        )}

        {this.shouldRenderSkus(campaign) ? (
          <SkusView
            skus={campaign.skus}
            addNewSku={this.addNewSku}
            removeSku={this.removeSku}
          />
        ) : null}
      </div>
    );
  }
}
