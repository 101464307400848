import React from "react";
import { Helmet } from "react-helmet";
import { FaLock } from "react-icons/fa";
import { Image } from "semantic-ui-react";

import lwflheader from "../../assets/lwfl-header.png";
import "./campaignstyles.scss";

const constthemes = new Map([
  ["COREPURPLE", "corepurpletheme"],
  ["GREEN", "greentheme"],
  ["YELLOW", "yellowtheme"],
  ["RED", "redtheme"],
  ["DARKPUTTY", "corepurpletheme"],
]);

export default class MobilePreview extends React.Component {
  state = {
    themes: constthemes,
  };

  escapedNewLineToLineBreakTag = (string) => {
    if (string !== undefined && string !== null) {
      return string.split("\\n").map((item, index) => {
        return index === 0 ? item : [<br key={index} />, item];
      });
    }
    return "";
  };

  dummyOnClick = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="smartphone floatalways">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div
          className={`content ${this.state.themes.get(
            this.props.campaign.trackerTheme
          )}`}
          id="content"
        >
          <div className="scrollablepage" id="mainpage">
            <Image src={lwflheader} className="homeheaderimage" />
            <div className="campaignhomeheader">
              You purchased 40 portions and collected 100 points
            </div>
            <div className="campaigntrackertile campaigntilecompleted">
              <div className="inprogresstargettitle">5/5</div>
              <div className="campaignhometrackerprogresscircle campaignlocked">
                <div className="collectedpoints">+50</div>
              </div>
            </div>
            <div className="campaigntrackertile">
              <div className="inprogresstargettitle">Target 2</div>
              <div className="campaignhometrackerprogresscircle rotateprogress campaignhomepartialprogresscircle">
                <div className="progress">
                  <div className="hometrackernumerator">25</div>
                  <div className="hometrackerdivider"></div>
                  <div className="hometrackerdenominator">35</div>
                </div>
              </div>
            </div>
            <div className="campaigntrackertile">
              <FaLock className="homelockicon" />
              <div className="campaignhometrackerprogresscircle">
                <div className="unstartedpoints">+200</div>
              </div>
            </div>
            <div className="campaignhomesubtitle">
              <div className="didyouknowtext">Did you know? &nbsp;</div>You buy
              X portions in an average month
            </div>
            <div className="campaignhomeheader">5 badges left to unlock</div>
            <div className="campaignhometitle">
              Unlock badges to get extra points
            </div>
            <div className="campaignbadgetile campaigntilecompleted">
              <div className="badgecollectedpoints">+50</div>
              <div className="campaignhomebadgeprogresscircle campaignhomeprogresscircle campaignhomelocked slightrotate">
                <Image src={this.props.campaign.imageSmallUrl} />
              </div>
            </div>
            <div className="campaignbadgetile">
              <div className="collectedpoints">+50</div>
              <div className="campaignhomebadgeprogresscircle slightrotate">
                <Image src={this.props.campaign.imageSmallUrl} />
              </div>
            </div>
          </div>
          <div className="scrollablepage fillthemecolor" id="lockedbadge">
            <div className="campaigntitle">{this.props.campaign.name}</div>
            <div className="campaignheader">{this.props.campaign.name}</div>
            <br />
            <br />
            <br />
            <div className="campaignsubheader">
              The badge challenge will start when you buy your first portion
            </div>
            <div className="campaignprogresscircle campaignlocked">
              <FaLock className="lockicon" />
            </div>
            <div className="campaignsubtitle">
              Collect 50 points by purchasing 5 portions of the vegetable
            </div>
            <a href onClick={this.dummyOnClick}>
              <div className="roundbutton">Ok, got it</div>
            </a>
          </div>
          <div className="scrollablepage fillthemecolor" id="unlockedbadge">
            <div className="campaigntitle">{this.props.campaign.name}</div>
            <div className="campaignheader">Top Tip</div>
            <div className="campaignsubheader">
              {this.props.campaign.campaignText1}
            </div>
            <div className="campaignpartialprogress">
              <div className="progress">
                <div className="numerator">3</div>
                <div className="divider"></div>
                <div className="denominator">5</div>
              </div>
            </div>
            <div className="campaignsubtitle">
              You are 2 portions away from collecting +50 points
            </div>
            <a href onClick={this.dummyOnClick}>
              <div className="roundbutton">Ok, got it</div>
            </a>
          </div>
          <div className="scrollablepage fillthemecolor" id="completedbadge">
            <div className="campaigntitle">{this.props.campaign.name}</div>
            <div className="campaignheader">
              {this.escapedNewLineToLineBreakTag(
                this.props.campaign.completionTitle
              )}
            </div>
            <div className="campaignsubheader">
              {this.props.campaign.campaignText2}
            </div>
            <div className="campaignprogresscircle slightrotate campaignachievedimageholder">
              <Image src={this.props.campaign.imageSmallUrl} />
            </div>
            <div className="campaignsubtitle">You purchased 5 portions</div>
            <a href onClick={this.dummyOnClick}>
              <div className="roundbutton">Share your achievement</div>
            </a>
            <a href onClick={this.dummyOnClick}>
              <div className="roundbutton">Show other badges</div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
