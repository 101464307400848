import React from "react";
import { Link } from "react-router-dom";

import { Alert, AlertIcon } from "@jsluna/alert";
import { FilledButton, OutlinedButton } from "@jsluna/button";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ErrorCircle, GridView } from "@jsluna/icons";

import { DateTimeField, TextField } from "../../common/components/FormFields";
import { InlineGroup } from "../../common/components/InlineGroup";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import { ProductSelector } from "../../common/components/ProductSelector";
import { toApiFormat } from "../../common/dates";
import Loader from "../../common/helpers/Loader/Loader";
import { useForm } from "../../common/hooks/useForm";

const PromotionEditor = ({
  loading,
  apiErrorText,
  onSave,
  initialValues,
  promotionId,
}) => {
  const isCreateMode = !promotionId;
  const { useField, anyValidationErrors, onSubmit, shouldBlockSubmit } =
    useForm({ immediateValidation: !isCreateMode });

  const name = useField({
    initialValue: initialValues.name ?? "",
    validations: [
      [(v) => v.length <= 70, "This field cannot exceed 70 characters"],
    ],
  });

  const description = useField({
    initialValue: initialValues.description ?? "",
    validations: [
      [(v) => v.length <= 160, "This field cannot exceed 160 characters"],
    ],
  });

  const expiryDate = useField({
    initialValue: initialValues.expiry_date ?? null,
    validations: [
      [
        (v) => v >= toApiFormat(new Date()),
        "Expiry date must not be in the past",
      ],
    ],
  });

  const selectedProducts = useField({
    initialValue: [
      ...(initialValues.skus ?? []).map(({ id, description }) => ({
        id,
        description,
        type: "sku",
      })),
      // We could potentially track the existing subcategories
      // but we expect to be handling those before end users get hands on
      ...(initialValues.sub_categories ?? []).map(({ id, description }) => ({
        id,
        description,
        type: "subcategory",
      })),
    ],
    validations: [[(v) => v.length > 0, "Please select at least one product"]],
  });

  const onSaveSubmit = onSubmit((e) => {
    e.preventDefault();

    // We will need to pass subcategories here when we start handling those in the UI
    return onSave({
      name: name.value,
      description: description.value,
      expiry_date: expiryDate.value,
      skus: selectedProducts.value
        .filter((n) => n.type === "sku")
        .map((rows) => rows.id),
      sub_categories: selectedProducts.value
        .filter((n) => n.type === "subcategory")
        .map((rows) => rows.id),
    });
  });

  if (loading) {
    return <Loader />;
  }

  const headerText = isCreateMode
    ? "Create new promotion"
    : `Promotion ${promotionId}`;

  const validationErrors = anyValidationErrors() && shouldBlockSubmit;

  return (
    <PageContainer>
      <GridItem size={{ md: "1/2" }}>
        <PageHeader>
          <GridView />
          {headerText}
        </PageHeader>
      </GridItem>
      <GridItem size={{ md: "1/2" }}>
        <InlineGroup alignEnd>
          <OutlinedButton to="/promotions" element={Link}>
            Close
          </OutlinedButton>
          <FilledButton onClick={onSaveSubmit} disabled={validationErrors}>
            Save
          </FilledButton>
        </InlineGroup>
      </GridItem>
      {validationErrors && (
        <GridItem size="1/1">
          <Alert variant="error">
            <AlertIcon>
              <ErrorCircle aria-label="Error" role="img" />
            </AlertIcon>
            Please address validation errors before continuing
          </Alert>
        </GridItem>
      )}
      {apiErrorText && (
        <GridItem size="1/1">
          <Alert variant="error">
            <AlertIcon>
              <ErrorCircle aria-label="Error" role="img" />
            </AlertIcon>
            {apiErrorText}
          </Alert>
        </GridItem>
      )}
      <GridItem size="1/1">
        <GridWrapper>
          <GridItem size={{ md: "1/2" }}>
            <TextField
              name="name"
              label="Promotion Name"
              placeholder="Enter promotion name"
              {...name.props}
            />
          </GridItem>
          <GridItem size={{ md: "1/2" }}>
            <TextField
              name="description"
              label="Promotion Description"
              placeholder="Enter promotion description"
              {...description.props}
            />
          </GridItem>
          <GridItem size={{ md: "1/2" }}>
            <DateTimeField
              name="promotion-expiry"
              label="Promotion expiry"
              placeholder="Select promotion expiry date"
              {...expiryDate.props}
            />
          </GridItem>
        </GridWrapper>
        <ProductSelector {...selectedProducts.props} />
      </GridItem>
    </PageContainer>
  );
};

export default PromotionEditor;
