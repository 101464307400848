import React, { Component } from "react";
import { toast } from "react-toastify";
import { Header, Image, Table } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  TextButton,
} from "@jsluna/button";
import { Label } from "@jsluna/form";
import { Delete } from "@jsluna/icons";
import { Modal, ModalHeading } from "@jsluna/react";

import CustomerClient from "../../services/CustomerClient";

const customerClient = new CustomerClient();

class OffersView extends Component {
  state = { toggleModal: false, selectedOffer: {} };

  static formattedDate(dateString) {
    let date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  openConfimationDialog = (offer) => {
    this.setState({
      toggleModal: !this.state.toggleModal,
      selectedOffer: offer,
    });
  };

  cancelOffer = () => {
    const offerId = this.state.selectedOffer && this.state.selectedOffer.id;
    const customerId = this.props.customer && this.props.customer.id;
    customerClient
      .cancelCustomerOffer(customerId, offerId)
      .then((res) => {
        if (res.status === 200) {
          this.props.reloadCustomer();
          this.setState({ toggleModal: false });
          toast.success(" Action completed sucessfully");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  render() {
    return this.props.offers.length > 0 ? (
      <React.Fragment>
        <Table style={{ width: "100%" }} basic="very" celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Offer</Table.HeaderCell>
              <Table.HeaderCell>Points</Table.HeaderCell>
              <Table.HeaderCell>Valid From</Table.HeaderCell>
              <Table.HeaderCell>Expires</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Cancel</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.props.offers.map((offer, key) => {
              return (
                <Table.Row key={key}>
                  <Table.Cell>
                    <Header as="h4">
                      <Image src={offer.imgUrl} shape="rounded" size="mini" />
                      <Header.Content>
                        {offer.title}
                        <Header.Subheader>{offer.description}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{offer.points}</Table.Cell>
                  <Table.Cell>
                    {OffersView.formattedDate(offer.validFrom)}
                  </Table.Cell>
                  <Table.Cell>
                    {OffersView.formattedDate(offer.expires)}
                  </Table.Cell>
                  <Table.Cell>{offer.state}</Table.Cell>
                  <Table.Cell
                    style={{ cursor: "Pointer" }}
                    onClick={() => this.openConfimationDialog(offer)}
                  >
                    <Delete />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Modal
          fullScreen
          restrictClose
          alert
          handleClose={() => this.openConfimationDialog()}
          open={this.state.toggleModal}
          headingId="dialog-modal"
          className="custom-modal"
        >
          <ModalHeading>Confirm Action!</ModalHeading>
          <Label>Are you sure?</Label>
          <ButtonGroupWrapper actionbar>
            <ButtonGroupPrimary>
              <TextButton onClick={() => this.openConfimationDialog()}>
                Cancel
              </TextButton>
              <FilledButton
                className="ln-u-margin-left"
                onClick={() => this.cancelOffer()}
              >
                Delete
              </FilledButton>
            </ButtonGroupPrimary>
          </ButtonGroupWrapper>
        </Modal>
      </React.Fragment>
    ) : (
      <span>No offers.</span>
    );
  }
}

export default OffersView;
