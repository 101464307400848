import { Header, HttpClient, MediaType, Method, managementApi } from "./http";

export class LloydsBaserateClient extends HttpClient {
  uploadFile(file) {
    const data = new FormData();
    data.append("file", file, file.name);
    return this.request(Method.PUT, managementApi("/baserate/lloyds"), data, {
      [Header.CONTENT_TYPE]: MediaType.MultipartFormData,
    });
  }

  queryFreshness() {
    return this.request(Method.GET, managementApi("/baserate/lloyds"));
  }
}

export default LloydsBaserateClient;
