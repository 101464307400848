import { isEmpty, map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Form, Icon, Message } from "semantic-ui-react";

import { FilledButton, OutlinedButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Cancel, Plus, Upload } from "@jsluna/icons";

import SkuClient from "../../services/SkuClient";
import SkuEditor from "./SkuEditor";

const skuClient = new SkuClient();

const viewStates = Object.freeze({
  EDIT: Symbol("Edit"),
  LIST: Symbol("List"),
});

class SkuView extends React.Component {
  state = {
    skus: [],
    error: undefined,
    viewState: viewStates.LIST,
    currentSkuId: null,
    searchSkuId: "",
    searchTargetSkuId: "",
  };

  componentDidMount() {
    this.reloadSkus();
    window.onpopstate = this.handleBackClick;
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handleBackClick = (e) => {
    if (this.state.viewState === viewStates.EDIT) {
      e.preventDefault();
      this.setState({
        viewState: viewStates.LIST,
      });
    } else {
      return true;
    }
  };

  reloadSkus() {
    this.clearSearchFields();
    skuClient
      .fetchSkus()
      .then(({ data }) => {
        this.setState({ skus: data });
      })
      .catch((err) => {
        this.setState({ error: "Failed to load skus list : " + err });
      });
  }

  clearSearchFields() {
    this.setState({ searchSkuId: "" });
    this.setState({ searchTargetSkuId: "" });
  }

  deleteSku = (id) => {
    skuClient.deleteSku(id).then(() => {
      toast.success(`Deleted Sku ${id ? ` '${id}'` : ""}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
      });
      this.reloadSkus();
    });
  };

  onEditClick = (id) => {
    this.setState({
      currentSkuId: id,
      viewState: viewStates.EDIT,
    });
    this.props.history.push("/skus");
  };

  onCreateClick = () => {
    this.clearSearchFields();
    this.setState({
      currentSkuId: null,
      viewState: viewStates.EDIT,
    });
    this.props.history.push("/skus");
  };

  currentView = () => {
    const props = {
      onEditClick: this.onEditClick,
      deleteSku: this.deleteSku,
    };

    switch (this.state.viewState) {
      case viewStates.EDIT:
        return (
          <SkuEditor
            key={this.state.currentSkuId}
            currentSkuId={this.state.currentSkuId}
            close={() => {
              this.setState({ viewState: viewStates.LIST });
              this.reloadSkus();
            }}
          />
        );
      case viewStates.LIST:
        return (
          <div>
            <GridWrapper style={{ padding: "1.5rem 0 0 0" }}>
              <GridItem size={{ xs: "1/2" }}>
                <FilledButton
                  style={{ height: "100%" }}
                  primary
                  content="Create Sku"
                  icon="plus circle"
                  labelPosition="left"
                  size="small"
                  onClick={this.onCreateClick}
                >
                  <Plus />
                  &nbsp;&nbsp;Create Sku
                </FilledButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  style={{
                    border: "2px solid #f06c00",
                    borderRadius: "3px",
                    fontSize: "1.190rem",
                    fontFamily:
                      "MaryAnn, Trebuchet MS, Arial, Helvetica, sans-serif",
                    backgroundColor: "transparent",
                    color: "#f06c00",
                    paddingLeft: "1.5rem !important",
                  }}
                  as="label"
                  content="Bulk Upload"
                  htmlFor="fileUpload"
                  secondary
                  size="small"
                  icon={
                    <>
                      <Upload /> &nbsp;&nbsp;
                    </>
                  }
                />
                <input
                  id="fileUpload"
                  type="file"
                  hidden
                  onChange={this.onUpload}
                />
              </GridItem>
              <GridItem size={{ xs: "1/2" }}>
                <GridItem size={{ xs: "1/2" }}>
                  <Form.Input
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      boxShadow: "0",
                      fontFamily: "sans-serif",
                      border: "1px solid #737373",
                      borderRadius: "4.571px",
                      color: "#737373",
                      minHeight: "48px",
                    }}
                    type="text"
                    icon="search"
                    placeholder="Search by Sku Id"
                    value={this.state.searchSkuId || ""}
                    onChange={(e) =>
                      this.setState({ searchSkuId: e.target.value })
                    }
                  />
                </GridItem>
                <GridItem size={{ xs: "1/2" }}>
                  <Form.Input
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      boxShadow: "0",
                      fontFamily: "sans-serif",
                      border: "1px solid #737373",
                      borderRadius: "4.571px",
                      color: "#737373",
                      minHeight: "48px",
                    }}
                    type="text"
                    icon="search"
                    placeholder="Search by Target Sku Id"
                    value={this.state.searchTargetSkuId || ""}
                    onChange={(e) =>
                      this.setState({ searchTargetSkuId: e.target.value })
                    }
                  />
                </GridItem>
              </GridItem>
            </GridWrapper>

            <SkusList
              skuData={this.state.skus}
              searchSkuId={this.state.searchSkuId}
              searchTargetSkuId={this.state.searchTargetSkuId}
              {...props}
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  onUpload = (e) => {
    const files = e.target.files;
    const file = files && files[0];
    if (file) {
      skuClient
        .getFileUploadUrl(file.name)
        .then((url) => this.uploadFile(url, file))
        .catch((err) => alert("File upload error"));
    }
  };

  uploadFile = (url, file) => {
    skuClient
      .uploadFile(url.data, file)
      .then(() =>
        alert("The Sku file was progress and will be processed shortly.")
      )
      .catch((err) => alert("File upload error"));
  };

  render() {
    const { skus, error } = this.state;
    if (!skus && !error) {
      return null;
    }
    return (
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper>
          <GridItem size={{ xs: "1" }}>
            <span>
              <Icon circular name="microchip" />
              &nbsp;&nbsp;<Label>Skus</Label>
            </span>
          </GridItem>
          <GridItem>{this.currentView()}</GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

const SkusList = ({
  skuData,
  searchSkuId,
  searchTargetSkuId,
  onEditClick,
  deleteSku,
}) => {
  if (skuData.error) return <ErrorLoadingSku message={skuData.error} />;
  skuData.sort(compareSkus);
  let filteredSkus = skuData.filter((s) =>
    s.skuId.toLowerCase().startsWith(searchSkuId.toLowerCase())
  );
  filteredSkus = filteredSkus.filter(
    (s) =>
      searchTargetSkuId === "" ||
      (s.targetSkuIds !== undefined &&
        s.targetSkuIds.find((item) =>
          item.toLowerCase().startsWith(searchTargetSkuId.toLowerCase())
        ))
  );
  return (
    <p>
      <br />
      <i>
        <strong>Number of skus: {filteredSkus.length} </strong>
      </i>
      <SkuTable>
        <SkuTableHeader />
        <SkuTableBody>
          {map(filteredSkus, (p) => (
            <SkuRow
              deleteSku={deleteSku}
              sku={p}
              key={p.skuId}
              onClick={() => {
                onEditClick(p.skuId);
              }}
            />
          ))}
        </SkuTableBody>
      </SkuTable>
    </p>
  );
};

function compareSkus(a, b) {
  if (a.skuId < b.skuId) {
    return -1;
  }
  if (a.skuId > b.skuId) {
    return 1;
  }
  return 0;
}

const SkuTableHeader = () => (
  <thead className="ln-c-table__header">
    <tr className="ln-c-table__row ln-c-table__header-row">
      {[
        "Sku Id",
        "Product Category",
        "Unit Metric",
        "Portions per metric",
        "Targets Sku id",
        "",
      ].map((header, idx) => (
        <th className="ln-c-table__header-cell customCol" key={idx}>
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const SkuTable = (props) => (
  <div style={{ paddingTop: "1.5rem" }}>
    {" "}
    <table className="ln-c-table" celled striped>
      {props.children}
    </table>
  </div>
);
const SkuTableBody = (props) => (
  <tbody className="ln-c-table__body">{props.children}</tbody>
);

const SkuRow = (props) => {
  const { sku, onClick } = props;
  const errors = [];
  return (
    <tr className="ln-c-table__row ln-c-table__header-row">
      <td className="ln-c-table__cell">
        <h4>
          <Button onClick={onClick}>
            {sku.skuId ? sku.skuId : "Undefined"}
            {isEmpty(errors) ? null : (
              <span>
                &nbsp;
                <Icon title="Invalid sku" name="warning sign" color="orange" />
              </span>
            )}
          </Button>
        </h4>
      </td>
      <td className="ln-c-table__cell">{sku.category}</td>
      <td className="ln-c-table__cell">{sku.itemUnitMetric}</td>
      <td className="ln-c-table__cell">{sku.portionsPerMetric}</td>
      <td className="ln-c-table__cell">{sku.targetSkuIds.join(", ")}</td>
      <td className="ln-c-table__cell" collapsing>
        <OutlinedButton
          onClick={() => {
            if (window.confirm("Are you sure you wish to delete this sku?"))
              props.deleteSku(sku.skuId);
          }}
        >
          <Cancel />
        </OutlinedButton>
      </td>
    </tr>
  );
};

const ErrorLoadingSku = (props) => (
  <Message negative>
    <p>{props.message}</p>
  </Message>
);

SkuView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(SkuView);
