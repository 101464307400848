import React from "react";

import { useSessionContext } from "../contexts/SessionContext";

export const userRolesPermissions = (WrappedComp, reqPermission) => {
  return (props) => {
    const { userRoles, isRoleBasedAccess } = useSessionContext();

    if (isRoleBasedAccess) {
      const hasPermission = userRoles.some((role) =>
        reqPermission.includes(role)
      );
      return hasPermission ? (
        <WrappedComp {...props} />
      ) : (
        <div>
          <p>
            <b>No Permission !!!</b>
          </p>
        </div>
      );
    } else {
      return <WrappedComp {...props} />;
    }
  };
};
