import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { SuccessToastNotification } from "../../common/helpers/Notifications/SuccessToastNotification";
import { BarcodeBlocklistsClient } from "../../services/BarcodeBlocklistsClient";
import BarcodeBlocklistsEditor from "./BarcodeBlocklistsEditor";

const apiClient = new BarcodeBlocklistsClient();

const CreateBarcodeBlocklist = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [apiErrorText, setApiErrorText] = useState("");

  const onSave = async (requestBody) => {
    setLoading(true);

    try {
      const response = await apiClient.blockBarcode(requestBody);
      if (response.status !== 200) {
        throw new Error(response);
      }
      SuccessToastNotification(`That barcode is blocked now`);
      setLoading(false);
      history.push("/barcodeblocklists");
    } catch (err) {
      console.error(err);
      setApiErrorText("That barcode already exists in blocklist.");
      setLoading(false);
    }
  };
  return (
    <BarcodeBlocklistsEditor
      loading={loading}
      apiErrorText={apiErrorText}
      onSave={onSave}
      initialValues={{}}
    />
  );
};

export default CreateBarcodeBlocklist;
