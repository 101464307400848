import React from "react";
import { DateTimePicker } from "react-widgets";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { StartDateBehaviour } from "../campaign";
import { EndDateBehaviour } from "../campaign";

export const DatesInput = ({ campaign, handleChange, handleChangeDate }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Campaign Start</Label>
      <br />
      <DateTimePicker
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "2px #737373",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4px",
          color: "#737373",
        }}
        culture="en"
        value={campaign && new Date(campaign.startDate)}
        onChange={(e) => handleChangeDate(e, "startDate")}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Campaign End</Label>
      <br />
      <DateTimePicker
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "2px #737373",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4px",
          color: "#737373",
        }}
        culture="en"
        value={campaign && new Date(campaign.endDate)}
        onChange={(e) => handleChangeDate(e, "endDate")}
      />
    </GridItem>
    <br />
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Offer start date behaviour</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        value={campaign ? campaign.startDateBehaviour : "START_WHEN_ISSUED"}
        name="startDateBehaviour"
        options={StartDateBehaviour}
        type="text"
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Offer start date</Label>
      <br />
      <DateTimePicker
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "2px #737373",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4px",
          color: "#737373",
        }}
        culture="en"
        disabled={campaign.startDateBehaviour !== "START_ON_SPECIFIED_DATE"}
        value={
          campaign.offerStartDate
            ? new Date(campaign.offerStartDate)
            : new Date()
        }
        onChange={(e) => handleChangeDate(e, "offerStartDate")}
      />
    </GridItem>
    <br />
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Offer end date behaviour</Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        value={campaign ? campaign.endDateBehaviour : "END_DATE_OF_CAMPAIGN"}
        name="endDateBehaviour"
        options={EndDateBehaviour}
        type="text"
        onChange={handleChange}
      />
    </GridItem>
    <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
      <Label>Offer start date</Label>
      <br />
      <DateTimePicker
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "2px #737373",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4px",
          color: "#737373",
        }}
        culture="en"
        disabled={campaign.endDateBehaviour !== "END_ON_SPECIFIED_DATE"}
        value={
          campaign.offerEndDate ? new Date(campaign.offerEndDate) : new Date()
        }
        onChange={(e) => handleChangeDate(e, "offerEndDate")}
      />
    </GridItem>
  </GridWrapper>
);
