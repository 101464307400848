import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

import { Alert, AlertIcon } from "@jsluna/alert";
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  IconButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Delete, InfoCircle, Upload } from "@jsluna/icons";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import history from "../../history";
import MarketingEventClient from "../../services/MarketingEventClient";
import { userGroupCreateOptions, userGroupTabs } from "./Constants";
import "./MarketingEvents.scss";

const marketingEventClient = new MarketingEventClient();

const MarketingEventUserGroup = () => {
  const [activeTab, setActiveTab] = React.useState("create");
  const [userGroupName, setUserGroupName] = useState();
  const [eventAudienceVal, setEventAudienceVal] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [userGroupNames, setUserGroupNames] = useState(undefined);
  const uploadInputRef = useRef(null);
  const [alertOpen, setAlertOpen] = useState(true);
  const [isCsvFileUploaded, setIsCsvFileUploaded] = useState(true);
  const [ugApiStatus, setUgApiStatus] = useState(false);
  const [buttonGroupValue, setButtonGroupValue] = useState();
  const [aspireTableName, setAspireTableName] = useState();
  const MAX_COUNT = 3;

  useEffect(() => {
    getUserGroup();
    setActiveTab("create");
  }, []);

  useEffect(() => {
    if (activeTab) {
      setUgApiStatus(false);
      setUserGroupName();
      setEventAudienceVal();
      setUploadedFiles([]);
      setAspireTableName();
    }
  }, [activeTab]);

  useEffect(() => {
    if (buttonGroupValue === "browseCsv") {
      setAspireTableName();
    } else if (buttonGroupValue === "inputTable") {
      setUploadedFiles([]);
    } else {
      setAspireTableName();
      setUploadedFiles([]);
    }
  }, [buttonGroupValue]);

  useEffect(() => {
    if (ugApiStatus) {
      history.push("/marketing-events");
    }
  }, [ugApiStatus]);

  const getUserGroup = async () => {
    await marketingEventClient
      .getUserGroup()
      .then(({ data }) => setUserGroupNames(data))
      .catch((err) => {
        setUserGroupNames(undefined);
      });
  };

  const userGroupArray =
    userGroupNames &&
    userGroupNames.map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });

  const handleEventAudienceChange = (event) => {
    const { value } = event.target;
    setEventAudienceVal(value);
  };

  const handleUsergroupOptions = (event) => {
    const { value } = event.target;
    setButtonGroupValue(value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setUserGroupName(value);
  };

  const handleTextChange = (event) => {
    const { value } = event.target;
    setAspireTableName(value);
  };

  const onOldUploadFileChange = (e) => {
    setIsCsvFileUploaded(true);
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const onUploadClick = (e) => {
    uploadInputRef.current.value = null;
    uploadInputRef.current.click();
  };

  const removeFile = (index) => {
    setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
  };

  const addCsvToUserGrp = () => {
    uploadedFiles &&
      uploadedFiles.map(async (item) => {
        let data = new FormData();
        const fileData = {
          file: item,
          fileName: item.name,
        };
        const { file, fileName } = fileData;
        data.append("file", file, fileName);
        await marketingEventClient
          .addCsvToUserGroup(userGroupName || eventAudienceVal, data)
          .then((res) => {
            if (res.status === 200) {
              setUgApiStatus(true);
              toast.success(
                `Uploaded csv ${fileName} successfully for the user group : ` +
                  (userGroupName ? userGroupName : "") +
                  (eventAudienceVal ? eventAudienceVal : "")
              );
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      });

    setUploadedFiles([]);
    setUserGroupName();
  };

  const addAspireTableToUsrGrp = async () => {
    let payload = {
      name: userGroupName || eventAudienceVal,
      aspireTable: aspireTableName,
    };
    await marketingEventClient
      .createUserGroupWithAspireTable(payload)
      .then((res) => {
        if (res.status === 200) {
          setUgApiStatus(true);
          toast.success(
            `Added aspire table name: ${
              aspireTableName || eventAudienceVal
            } successfully for the user group : ` +
              (userGroupName
                ? userGroupName
                : eventAudienceVal
                ? eventAudienceVal
                : "")
          );
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const createUserGroup = async () => {
    if (activeTab === "create") {
      await marketingEventClient
        .createUserGroup(userGroupName)
        .then((res) => {
          if (res.status === 200) {
            if (uploadedFiles && uploadedFiles.length > 0) {
              addCsvToUserGrp();
            } else if (aspireTableName) {
              addAspireTableToUsrGrp();
            }
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    } else if (activeTab === "edit") {
      if (uploadedFiles && uploadedFiles.length > 0) {
        addCsvToUserGrp();
      } else if (aspireTableName) {
        addAspireTableToUsrGrp();
      }
    }
  };

  const checkFileExtension = (fileName) => {
    if (fileName.match(/\.(csv)$/)) {
      return true;
    }
    return false;
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    // eslint-disable-next-line
    files.some((file) => {
      if (checkFileExtension(file.name)) {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded.length > MAX_COUNT) {
            alert("You can upload max 3");
            limitExceeded = true;
            return true;
          }
        }
        setIsCsvFileUploaded(true);
      } else {
        setIsCsvFileUploaded(false);
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
    setAlertOpen(true);
  };

  const ShowAlert = (props) => {
    return (
      <Alert
        variant={props.variant}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        squared
      >
        <AlertIcon>
          <InfoCircle aria-label="Information" role="img" />
        </AlertIcon>
        {props.message}
      </Alert>
    );
  };

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper matrix className="ln-u-margin-top*3">
        <GridItem>
          <Label
            htmlFor="marketing-events-user-group"
            className="ln-u-margin-left"
          >
            Marketing Event - User Group
          </Label>
          <br />
          <br />
        </GridItem>
        <GridItem size={"4/5"}>
          <GridItem>
            <Tabs type="pill">
              {userGroupTabs.map((tab) => (
                <TabLink
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                  active={activeTab === tab.key}
                >
                  {tab.name}
                </TabLink>
              ))}
            </Tabs>
          </GridItem>
          <TabPanel className="ln-u-margin-top">
            <GridItem>
              <GridItem size="1/2">
                {activeTab === "create" ? (
                  <TextInputField
                    name="id"
                    label="User group name"
                    placeholder="eg:- CFC-2023-HI"
                    value={userGroupName}
                    onChange={handleChange}
                  />
                ) : (
                  activeTab === "edit" && (
                    <SelectField
                      name="UserGroupName"
                      label="User group name"
                      options={userGroupArray ? userGroupArray : []}
                      value={eventAudienceVal}
                      onChange={(e) => handleEventAudienceChange(e)}
                    />
                  )
                )}
              </GridItem>
              <GridItem size="1/2" />
              <GridItem size="1/2">
                <SelectField
                  name="UserGroupOptions"
                  label="User group options"
                  options={userGroupCreateOptions}
                  value={buttonGroupValue}
                  onChange={(e) => handleUsergroupOptions(e)}
                />
              </GridItem>
              <GridItem size="1/2" />
              <GridItem size="1/2">
                {buttonGroupValue === "browseCsv" && (
                  <div className="ln-u-margin-top*3">
                    <input
                      id="bulk-upload-file"
                      type="file"
                      hidden
                      name="file"
                      ref={uploadInputRef}
                      onChange={(e) => onOldUploadFileChange(e)}
                      accept=".csv"
                      multiple
                    />

                    <IconButton
                      onClick={onUploadClick}
                      variant="outlined"
                      label="Browse CSV"
                      disabled={uploadedFiles.length === MAX_COUNT}
                    >
                      <Upload />
                    </IconButton>
                    <Label className="ug-csv-browse-info">
                      Browse multiple files upto {MAX_COUNT} and upload
                    </Label>
                    {uploadedFiles &&
                      uploadedFiles.map((file, index) => (
                        <div>
                          <Label className="ug-csv-file-label">
                            {file.name}
                            <button onClick={() => removeFile(index)}>
                              <Delete />
                            </button>
                          </Label>
                        </div>
                      ))}
                    {uploadedFiles.length && alertOpen && isCsvFileUploaded ? (
                      <GridItem className="padding-left-none">
                        <ShowAlert
                          variant={"info"}
                          message={`${uploadedFiles.length} file${
                            uploadedFiles.length > 1 ? "s are" : " is"
                          } ready for Upload`}
                        />
                      </GridItem>
                    ) : (
                      alertOpen &&
                      !isCsvFileUploaded && (
                        <GridItem className="padding-left-none">
                          <ShowAlert
                            variant={"warning"}
                            message={"Please upload CSV file"}
                          />
                        </GridItem>
                      )
                    )}
                  </div>
                )}
                {buttonGroupValue === "inputTable" && (
                  <TextInputField
                    name="aspireTable"
                    label="Aspire table name"
                    placeholder="Aspire table name"
                    value={aspireTableName}
                    onChange={handleTextChange}
                  />
                )}
              </GridItem>
              <ButtonGroupWrapper actionbar="true">
                <ButtonGroupSecondary>
                  <OutlinedButton
                    type="button"
                    size="small"
                    onClick={() => history.push("/marketing-events")}
                  >
                    Cancel
                  </OutlinedButton>

                  <FilledButton
                    className="ln-u-margin-left"
                    onClick={
                      userGroupName || eventAudienceVal
                        ? () => createUserGroup()
                        : ""
                    }
                    disabled={
                      !(
                        (userGroupName || eventAudienceVal) &&
                        (uploadedFiles.length || aspireTableName)
                      )
                    }
                  >
                    {buttonGroupValue === "browseCsv"
                      ? "Upload & Create"
                      : "Create"}
                  </FilledButton>
                </ButtonGroupSecondary>
              </ButtonGroupWrapper>
            </GridItem>
          </TabPanel>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default withRouter(MarketingEventUserGroup);
