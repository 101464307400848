import React, { useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import history from "../../history";
import MarketingEventClient from "../../services/MarketingEventClient";
import { CoreLogicType, initialUIContents } from "./Constants";

const marketingEventClient = new MarketingEventClient();

const MarketingEventUIContents = () => {
  const [uiContents, setUiContents] = useState(initialUIContents);

  const handleUiContents = (event) => {
    const { name, value } = event.target;
    setUiContents((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreate = async () => {
    await marketingEventClient
      .createUIContents(uiContents)
      .then((res) => {
        if (res.status === 200) {
          history.push("/marketing-events");
          toast.success(
            uiContents.contentId + " UI Content creted sucessfully"
          );
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper matrix className="ln-u-margin-top*3">
        <GridItem>
          <Label htmlFor="marketing-events" className="ln-u-margin-left">
            Marketing Event - UI Contents
          </Label>
          <br />
          <br />
          <GridItem size={"4/5"}>
            <GridItem size="1/2">
              <TextInputField
                name="contentId"
                label="Content Id"
                placeholder="eg:- CFC23"
                value={uiContents.contentId}
                onChange={(e) => handleUiContents(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <TextInputField
                name="contentName"
                label="Content Name"
                placeholder="eg:- CFC"
                value={uiContents.contentName}
                onChange={(e) => handleUiContents(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <TextInputField
                name="contentPath"
                label="Content Path"
                placeholder="eg:- test/F_V_TEST"
                value={uiContents.contentPath}
                onChange={(e) => handleUiContents(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <SelectField
                name="coreLogicType"
                label="Content type"
                options={CoreLogicType}
                value={uiContents.coreLogicType}
                onChange={(e) => handleUiContents(e)}
              />
            </GridItem>

            <GridItem size="1/2">
              <ButtonGroupWrapper>
                <ButtonGroupSecondary>
                  <OutlinedButton
                    type="button"
                    size="small"
                    onClick={() => history.push("/marketing-events")}
                  >
                    Cancel
                  </OutlinedButton>
                  &nbsp;&nbsp;
                  <FilledButton
                    primary
                    size="small"
                    type="submit"
                    onClick={() => handleCreate()}
                  >
                    Create
                  </FilledButton>
                </ButtonGroupSecondary>
              </ButtonGroupWrapper>
            </GridItem>
          </GridItem>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default withRouter(MarketingEventUIContents);
