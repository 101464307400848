import React from "react";

import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import FoodOfferAllocateView from "./FoodOfferAllocateView";
import GolOccOfferAllocateView from "./GolOccOfferAllocateView";
import NectarPricesOfferAllocateView from "./NectarPricesOfferAllocateView";

class AllocateOfferView extends React.Component {
  state = {
    activeItem: "food",
    externalHandle: "",
    walletId: "",
  };

  componentDidMount() {
    if (this.props.history.location && this.props.history.location.state) {
      const EH = this.props.history.location.state.CsData.externalHandle;
      const WI = this.props.history.location.state.CsData.walletId;
      this.setState({
        externalHandle: EH,
        walletId: WI,
      });
    }
  }

  setActiveTab = (val) => {
    this.setState({ activeItem: val });
  };

  render() {
    const activeItem = this.state.activeItem;
    const panes = [
      { name: "Food", key: "food" },
      { name: "Nectar Prices", key: "nectarprices" },
      { name: "GOL OCC", key: "gol-occ" },
    ];

    return (
      <Card style={{ width: "100%" }}>
        <GridWrapper>
          <GridItem>
            <div>
              <Tabs type="pill">
                {panes.map((tab) => (
                  <TabLink
                    key={tab.key}
                    onClick={() => this.setActiveTab(tab.key)}
                    active={activeItem === tab.key}
                  >
                    {tab.name}
                  </TabLink>
                ))}
              </Tabs>
              <TabPanel>
                <Card
                  style={{
                    border: "1px solid #d8d8d8",
                    borderRadius: "3px",
                    margin: "1rem 0 1rem 0",
                  }}
                >
                  {activeItem === "food" ? (
                    <FoodOfferAllocateView
                      eHandle={this.state.externalHandle}
                    />
                  ) : null}
                  {activeItem === "nectarprices" ? (
                    <NectarPricesOfferAllocateView
                      eHandle={this.state.externalHandle}
                    />
                  ) : null}
                  {activeItem === "gol-occ" ? (
                    <GolOccOfferAllocateView />
                  ) : null}
                </Card>
              </TabPanel>
            </div>
          </GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

export default AllocateOfferView;
