import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import WelcomeLogo from "../../assets_V2/WelcomeLogo.png";
import CustomerClient from "../../services/CustomerClient";
import { LoadingState } from "../../services/http";
import CustomerBrief from "./CustomerBrief";
import ExpiredOffersView from "./ExpiredOffersView";
import OffersView from "./OffersView";
import TransactionsView from "./TransactionView";

const customerClient = new CustomerClient();

export class CustomerDetailsView extends Component {
  state = {
    customer: undefined,
    loadingState: LoadingState.PENDING,
    activeItem: "offers",
    transactions: undefined,
  };

  constructor() {
    super();
    this.getCustomer = this.getCustomer.bind(this);
  }

  componentDidMount() {
    this.getCustomer();
  }

  getCustomer() {
    customerClient
      .getCustomer(this.props.match.params.externalHandle)
      .then(({ data }) => {
        this.setState({
          customer: data,
          loadingState: LoadingState.SUCCESS,
          activeItem: "offers",
        });
      })
      .catch(() => {
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  }

  setActiveTab = (val) => {
    this.setState({ activeItem: val });
  };

  render() {
    const activeItem = this.state.activeItem;
    const CsData = this.state.customer;
    const tabs = [
      { name: "Offers", key: "offers" },
      { name: "Points Awarded", key: "transactions" },
      { name: "Expired Offers", key: "expired" },
    ];

    return (
      <>
        {this.state.loadingState === LoadingState.SUCCESS ? (
          <div>
            <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
              <GridWrapper verticalAlign="middle">
                <GridItem>
                  <CustomerBrief customer={CsData} />
                </GridItem>
                <GridItem>
                  <div>
                    <Tabs type="pill">
                      {tabs.map((tab) => (
                        <TabLink
                          key={tab.key}
                          onClick={() => this.setActiveTab(tab.key)}
                          active={activeItem === tab.key}
                        >
                          {tab.name}
                        </TabLink>
                      ))}
                    </Tabs>
                    <TabPanel>
                      <Card
                        style={{
                          border: "1px solid #d8d8d8",
                          borderRadius: "3px",
                          margin: "1rem 0 1rem 0",
                        }}
                      >
                        <h3>
                          {tabs.find((tab) => activeItem === tab.key).name}{" "}
                          Section
                        </h3>
                        {activeItem === "offers" ? (
                          <OffersView
                            offers={this.state.customer.offers}
                            customer={this.state.customer}
                            reloadCustomer={() => this.getCustomer()}
                          />
                        ) : null}
                        {activeItem === "transactions" ? (
                          <TransactionsView
                            cardNumber={this.state.customer.cardNumber}
                          />
                        ) : null}
                        {activeItem === "expired" ? (
                          <ExpiredOffersView
                            customerId={this.state.customer.id}
                          />
                        ) : null}
                      </Card>
                    </TabPanel>
                  </div>
                </GridItem>
              </GridWrapper>
            </Card>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              display: "flex",
              width: "100%",
              height: "85vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridWrapper
              className="ln-o-grid ln-o-grid--middle"
              horizontalAlign="center"
              verticalAlign="middle"
            >
              <GridItem className="ln-u-text-align-center" size={{ md: "1" }}>
                <div className="ln-u-soft">
                  <img
                    src={WelcomeLogo}
                    className="logoAnimate"
                    alt=""
                    width="100"
                    height="100"
                  />
                </div>
              </GridItem>
              <GridItem
                className="welcomeMsgTxt ln-u-text-align-center"
                size={{ md: "1" }}
              >
                <h3>Loading...</h3>
              </GridItem>
            </GridWrapper>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(CustomerDetailsView);
