import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const TriggerCampaignIdInput = ({ campaign, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }}>
      <Label>Trigger Campaign Id</Label>
      <br />
      <Form.Input
        style={{
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "4.571px",
          color: "#737373",
          minHeight: "48px",
        }}
        value={campaign.triggerCampaignId}
        type="text"
        name="triggerCampaignId"
        placeholder="e.g. 12345ABC (only works with digits for EE campaigns)"
        onChange={handleChange}
      />
    </GridItem>
  </GridWrapper>
);
