/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable array-callback-return */
import React, { createRef, useEffect, useMemo, useState } from "react";
import { Stepper } from "react-form-stepper";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
  TextButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Modal, ModalHeading } from "@jsluna/react";

import history from "../../history";
import MarketingEventClient from "../../services/MarketingEventClient";
import {
  eventBuilderInitialState,
  eventOptionsInitialState,
} from "./Constants";
import EventBuilder from "./EventBuilder";
import EventDetails from "./EventDetails";
import EventOptions from "./EventOptions";
import MarketingEventOptionsPreview from "./MarketingEventOptionsPreview";
import MarketingEventPreview from "./MarketingEventPreview";
import "./MarketingEvents.scss";
import UiOptionsContent from "./UiOptionsContent";

// import MegapodPreview from "./MegapodPreview";

const marketingEventClient = new MarketingEventClient();

const MarketingEventEditor = (props) => {
  const [eventDetails, setEventDetails] = useState({});
  const [eventBuilder, setEventBuilder] = useState();
  const [editScreen, setEditScreen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [taskArrVal, setTaskArrVal] = useState([eventBuilderInitialState]);
  const [optionArrVal, setOptionArrVal] = useState([eventOptionsInitialState]);
  const [eventOptions, setEventOptions] = useState();
  const [optionOrder, setOptionOrder] = useState(false);
  // const [eventAudienceVal, setEventAudienceVal] = useState();
  // const [eventUATAudienceVal, setEventUATAudienceVal] = useState();
  const [disableNext, setDisableNext] = useState(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  // const [eventUiOptions, setEventUiOptions] = useState({});
  // const [uiContent, setUiContent] = useState(marketingEventUIContent);
  const trackerLength = taskArrVal ? taskArrVal.length : 0;
  const tranTypeInputRef = useMemo(
    () =>
      Array(trackerLength)
        .fill(0)
        .map((i) => createRef(null)),
    []
  );
  const [taskOrder, setTaskOrder] = useState(false);
  const [taskIdsToDelete, setTaskIdsToDelete] = useState([]);
  const [optionIdsToDelete, setOptionIdsToDelete] = useState([]);

  const currentEventDetails =
    props.location &&
    props.location.editMarketingEvent &&
    props.location.editMarketingEvent.rowData;

  const isDuplicate =
    props.location && props.location.editMarketingEvent
      ? props.location.editMarketingEvent.isDuplicate
      : false;

  const currentEventDetailsKeys =
    currentEventDetails &&
    currentEventDetails[0] &&
    Object.keys(currentEventDetails[0]);

  const trackerDetails =
    currentEventDetails &&
    currentEventDetails[0] &&
    currentEventDetails[0].trackers;

  const redemptionOptionsDetails =
    currentEventDetails &&
    currentEventDetails[0] &&
    currentEventDetails[0].redemptionOptions;

  const selectedCoreLogicType = eventDetails && eventDetails.coreLogicType;
  const currentEventDetails_Priority =
    currentEventDetails &&
    currentEventDetails[0] &&
    currentEventDetails[0].priority;

  useEffect(() => {
    if (
      props.location.editMarketingEvent &&
      props.location.editMarketingEvent.enableEdit === true
    ) {
      setEditScreen(true);
    }
    if (activeStep === 0) {
      if (
        eventDetails.id &&
        eventDetails.coreLogicType &&
        eventDetails.offerCode &&
        eventDetails.minSupportedAppVersion &&
        eventDetails.description &&
        (eventDetails.coreLogicType === "INSTANT_REWARD"
          ? eventDetails.instantRewardPoints
          : true) &&
        (eventDetails.coreLogicType === "REDEMPTION"
          ? eventDetails.maxRedeemablePoints && eventDetails.voucherExpiryInDays
          : true)
      ) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
    }
    if (activeStep === 2) {
      if (editScreen || (eventDetails.homepage && eventDetails.contentId)) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
    }
  }, [eventDetails, activeStep]);

  useEffect(() => {
    if (activeStep === 1 && selectedCoreLogicType === "TRACKER") {
      if (taskArrVal && taskArrVal.length > 0) {
        for (const val of taskArrVal) {
          if (
            val.trackerName &&
            val.trackerPointsReward &&
            (val.totalTransactionCount ||
              val.totalTransactionUnits ||
              val.totalTransactionSpend) &&
            (val.totalTransactionUnits ? val.skus && val.skus.length > 0 : true)
          ) {
            setDisableNext(false);
          } else {
            setDisableNext(true);
            break;
          }
        }
      } else {
        setDisableNext(true);
      }
    }
  }, [taskArrVal, activeStep]);

  useEffect(() => {
    if (activeStep === 1 && selectedCoreLogicType === "REDEMPTION") {
      if (optionArrVal && optionArrVal.length > 0) {
        for (const val of optionArrVal) {
          if (val.optionName && val.redemptionPointsValue && val.pointsValue) {
            setDisableNext(false);
          } else {
            setDisableNext(true);
            break;
          }
        }
      } else {
        setDisableNext(true);
      }
    }
  }, [optionArrVal, activeStep]);

  useEffect(() => {
    if (currentEventDetails && currentEventDetails[0]) {
      const eventDetails = currentEventDetails[0];

      currentEventDetailsKeys.map((key) => {
        setEventDetails((prevState) => ({
          ...prevState,
          [key === "type" ? "coreLogicType" : key]: eventDetails[key],
        }));
      });

      setEventDetails((prevState) => ({
        ...prevState,
        contentId: currentEventDetails[0].marketingEventUIContent?.contentId,
      }));
    }
  }, [currentEventDetails]);

  useEffect(() => {
    if (trackerDetails && trackerDetails.length > 0) {
      let updateTaskArr;
      trackerDetails.sort((a, b) => a.trackerOrder - b.trackerOrder);
      trackerDetails.map((item, index) => {
        const trackerDetailsKeys = item && Object.keys(item);
        trackerDetailsKeys.map((key) => {
          updateTaskArr = {
            ...taskArrVal[index],
            [key]: item[key],
          };
          taskArrVal[index] = updateTaskArr;
          setTaskArrVal([...taskArrVal]);
        });
      });
    }
  }, [trackerDetails]);

  useEffect(() => {
    if (redemptionOptionsDetails && redemptionOptionsDetails.length > 0) {
      let updateOptionArr;
      redemptionOptionsDetails.sort((a, b) => a.optionOrder - b.optionOrder);
      redemptionOptionsDetails.map((item, index) => {
        const redemptionOptionsDetailsKeys = item && Object.keys(item);
        redemptionOptionsDetailsKeys.map((key) => {
          updateOptionArr = {
            ...optionArrVal[index],
            [key]: item[key],
          };
          optionArrVal[index] = updateOptionArr;
          setOptionArrVal([...optionArrVal]);
        });
      });
    }
  }, [redemptionOptionsDetails]);

  useEffect(() => {
    taskArrVal &&
      taskArrVal.length > 0 &&
      taskArrVal.map((val, index) => (val.trackerOrder = index + 1));
    setTaskArrVal([...taskArrVal]);
  }, [taskOrder]);

  useEffect(() => {
    optionArrVal &&
      optionArrVal.length > 0 &&
      optionArrVal.map((val, index) => {
        val.optionOrder = index + 1;
      });
    setOptionArrVal([...optionArrVal]);
  }, [optionOrder]);

  useEffect(() => {
    if (isDuplicate) {
      setEventDetails((prevState) => ({
        ...prevState,
        id: "",
      }));
      const updatedArrayOfObjects = taskArrVal.map((obj) => {
        const { id, ...rest } = obj; // Destructure and exclude the 'id' property
        return rest;
      });
      setTaskArrVal(updatedArrayOfObjects);

      const updatedOptionsObjects = optionArrVal.map((obj) => {
        const { id, ...rest } = obj; // Destructure and exclude the 'id' property
        return rest;
      });
      setOptionArrVal(updatedOptionsObjects);
    }
  }, [isDuplicate]);

  const handleNext = (stepId) => {
    window.scrollTo({ top: 0 });
    if (stepId <= 2) {
      setActiveStep(stepId);
    }
  };

  const handleEventDetailsChange = (event) => {
    const { name, value } = event.target;
    setEventDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name, event) => {
    setEventDetails((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  const assignUIOptionsAndContents = async (id) => {
    if (eventDetails.id && eventDetails.homepage) {
      await marketingEventClient
        .assignUIOptions("HOMEPAGE", {
          optionId: eventDetails.homepage,
          marketingEvent: id,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(id + " Assigned Homepage sucessfully");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    if (eventDetails.id && eventDetails.summary) {
      await marketingEventClient
        .assignUIOptions("SUMMARY", {
          optionId: eventDetails.summary,
          marketingEvent: id,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(id + " Assigned Summary sucessfully");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    if (eventDetails.id && eventDetails.overview) {
      await marketingEventClient
        .assignUIOptions("OVERVIEW", {
          optionId: eventDetails.overview,
          marketingEvent: id,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(id + " Assigned Overview sucessfully");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    if (eventDetails.id && eventDetails.contentId) {
      await marketingEventClient
        .assignUIContent(eventDetails.contentId, id)
        .then((res) => {
          if (res.status === 200) {
            history.push("/marketing-events");
            toast.success(id + " Action completed sucessfully");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const addTrackersToMarketingEvent = async (id) => {
    addMarketingEventNameToTracker();
    if (selectedCoreLogicType === "REDEMPTION") {
      await marketingEventClient
        .addOptionsToMarketingEvent(id, optionArrVal)
        // .then((res) => {
        //   if (res.status === 200) {
        //     validateUserGroup(id);
        //   }
        // })
        .catch((err) => {
          toast.error(err);
        });
    } else if (selectedCoreLogicType === "TRACKER") {
      await marketingEventClient
        .addTrackersToMarketingEvent(id, taskArrVal)
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const deleteTrackersFromMarketingEvent = async () => {
    for (const id of taskIdsToDelete) {
      await marketingEventClient
        .deleteTrackersFromMarketingEvent(id)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Tracker removed sucessfully");
          }
        })
        .catch((err) => {
          toast.error(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.description
          );
        });
    }
  };

  const deleteOptionsFromMarketingEvent = async () => {
    for (const id of optionIdsToDelete) {
      await marketingEventClient
        .deleteOptionsFromMarketingEvent(id)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Option removed sucessfully");
          }
        })
        .catch((err) => {
          toast.error(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.description
          );
        });
    }
  };

  const addPriorityToME = async (priority) => {
    await marketingEventClient
      .addPriorityToME(priority, eventDetails.id)
      .catch((err) => {
        toast.error(err);
      });
  };

  const createMarketingEvent = async () => {
    if (editScreen) {
      await marketingEventClient
        .editMarketingEvent(eventDetails)
        .then((res) => {
          if (res.status === 200) {
            if (taskIdsToDelete && taskIdsToDelete.length > 0) {
              deleteTrackersFromMarketingEvent();
            } else if (optionIdsToDelete && optionIdsToDelete.length > 0) {
              deleteOptionsFromMarketingEvent();
            }
            if (
              eventDetails &&
              eventDetails.priority !== currentEventDetails_Priority &&
              currentEventDetails_Priority !== ""
            ) {
              addPriorityToME(eventDetails.priority);
            }
            addTrackersToMarketingEvent(eventDetails.id);
          }
        })
        .catch((err) => {
          toast.error(err.response.statusText);
        });
    } else {
      await marketingEventClient
        .createMarketingEvent(eventDetails)
        .then((res) => {
          if (res.status === 200) {
            if (eventDetails && eventDetails.priority) {
              addPriorityToME(eventDetails.priority);
            }
            addTrackersToMarketingEvent(eventDetails.id);
            assignUIOptionsAndContents(eventDetails.id);
          }
        })
        .catch((err) => {
          toast.error(err.response.statusText);
        });
    }

    setOpenConfirmationDialog(false);
  };

  const addMarketingEventNameToTracker = () => {
    taskArrVal.map((val) => (val.marketingEvent = eventDetails.id));
    setTaskArrVal([...taskArrVal]);

    optionArrVal.map((val) => (val.marketingEvent = eventDetails.id));
    setOptionArrVal([...optionArrVal]);
  };

  const addTask = () => {
    const updatedEventBuilderState = {
      ...eventBuilderInitialState,
      trackerOrder: taskArrVal.length + 1,
    };
    setTaskArrVal((prevState) => [...prevState, updatedEventBuilderState]);
  };

  const removeTask = async (index) => {
    const trackerId = taskArrVal && taskArrVal[index] && taskArrVal[index].id;
    trackerId && setTaskIdsToDelete((prevIds) => [...prevIds, trackerId]);

    setTaskArrVal(taskArrVal.filter((_, i) => i !== index));
    setTaskOrder(!taskOrder);
  };

  const handleEventBuilderChange = (event) => {
    const { name, value } = event.target;
    setEventBuilder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddTaskVal = (event, index) => {
    const { name, value } = event.target;
    const updateTaskArr = {
      ...taskArrVal[index],
      [name]: value,
    };
    taskArrVal[index] = updateTaskArr;
    setTaskArrVal([...taskArrVal]);
  };

  const handleTransactionTypeValueChange = (event, index) => {
    const { value } = event.target;

    const updateTaskArr = {
      ...taskArrVal[index],
    };
    delete updateTaskArr.totalTransactionUnits;
    delete updateTaskArr.totalTransactionCount;
    delete updateTaskArr.totalTransactionSpend;
    taskArrVal[index] = updateTaskArr;
    setTaskArrVal([...taskArrVal]);

    if (value === "TRANSACTION_UNITS") {
      const updateTaskArr = {
        ...taskArrVal[index],
        // eslint-disable-next-line
        ["totalTransactionUnits"]: "",
      };
      taskArrVal[index] = updateTaskArr;
      setTaskArrVal([...taskArrVal]);
    }

    if (value === "TRANSACTION_COUNT") {
      const updateTaskArr = {
        ...taskArrVal[index],
        // eslint-disable-next-line
        ["totalTransactionCount"]: "",
      };
      taskArrVal[index] = updateTaskArr;
      setTaskArrVal([...taskArrVal]);
      removeSkusFromTask(index);
    }

    if (value === "TRANSACTION_SPEND") {
      const updateTaskArr = {
        ...taskArrVal[index],
        // eslint-disable-next-line
        ["totalTransactionSpend"]: "",
      };
      taskArrVal[index] = updateTaskArr;
      setTaskArrVal([...taskArrVal]);
      removeSkusFromTask(index);
    }

    if (tranTypeInputRef[index]) {
      if (tranTypeInputRef[index].current)
        tranTypeInputRef[index].current.value = null;
    }
  };

  const handleTransactionValueChange = (event, index) => {
    const { name, value } = event.target;
    const updateTaskArr = {
      ...taskArrVal[index],
      [name]: value && parseInt(value),
    };
    taskArrVal[index] = updateTaskArr;
    setTaskArrVal([...taskArrVal]);
  };

  const handleTrackerDateChange = (name, event, index) => {
    const updateTaskArr = {
      ...taskArrVal[index],
      [name]: event,
    };
    taskArrVal[index] = updateTaskArr;
    setTaskArrVal([...taskArrVal]);
  };

  const handleSkuValueChange = (event, index) => {
    const { value } = event.target;
    const skuArray = value ? Array.from(value.split(",")) : [];
    const updateTaskArr = {
      ...taskArrVal[index],
      skus: skuArray,
    };
    taskArrVal[index] = updateTaskArr;
    setTaskArrVal([...taskArrVal]);
  };

  const removeSkusFromTask = (index) => {
    const updateTaskArr = {
      ...taskArrVal[index],
      skus: [],
    };
    taskArrVal[index] = updateTaskArr;
    setTaskArrVal([...taskArrVal]);
  };

  const addOption = () => {
    const updatedEventOptionState = {
      ...eventOptionsInitialState,
      optionOrder: optionArrVal.length + 1,
    };
    setOptionArrVal((prevState) => [...prevState, updatedEventOptionState]);
  };

  const removeOption = (index) => {
    const optionId =
      optionArrVal && optionArrVal[index] && optionArrVal[index].id;
    optionId && setOptionIdsToDelete((prevIds) => [...prevIds, optionId]);

    setOptionArrVal(optionArrVal.filter((_, i) => i !== index));
    setOptionOrder(!optionOrder);
  };

  const handleEventOptionsChange = (event) => {
    const { name, value } = event.target;
    setEventOptions((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddOptionVal = (event, index) => {
    const { name, value } = event.target;
    const updateOptionArr = {
      ...optionArrVal[index],
      [name]: value,
    };
    optionArrVal[index] = updateOptionArr;
    setOptionArrVal([...optionArrVal]);
  };

  return (
    <div className="marketingEvent__container">
      <GridWrapper matrix>
        <Label htmlFor="create-marketing-event" className="ln-u-margin-left*2">
          Create Marketing Event:
        </Label>
        <GridItem style={{ display: "grid", gridAutoFlow: "column" }}>
          <Stepper
            steps={[
              { label: "Event Details" },
              {
                label:
                  selectedCoreLogicType === "TRACKER" ||
                  selectedCoreLogicType === ""
                    ? "Event Builder"
                    : "Event options",
              },
              { label: "UI Options & Content" },
            ]}
            activeStep={activeStep}
            className="custom-stepper"
            stepClassName="custom-step"
          />
        </GridItem>
        <GridItem>
          {activeStep === 0 && (
            <GridWrapper>
              <GridItem>
                <Card>
                  <Label>Event Details:</Label>
                  <div>
                    <EventDetails
                      handleChange={handleEventDetailsChange}
                      eventDetails={eventDetails}
                      handleDateChange={handleDateChange}
                      editScreen={editScreen}
                      selectedCoreLogicType={selectedCoreLogicType}
                    />
                  </div>
                </Card>
              </GridItem>
            </GridWrapper>
          )}
          {activeStep === 1 && selectedCoreLogicType === "TRACKER" && (
            <GridWrapper>
              <GridItem size="3/4">
                <Card className="ln-u-margin-bottom*3">
                  <Label>Event Builder</Label>
                </Card>
                <Card>
                  <Label>Tracker :</Label>
                  <div>
                    <EventBuilder
                      addTask={addTask}
                      removeTask={removeTask}
                      handleChange={handleEventBuilderChange}
                      eventBuilder={eventBuilder}
                      handleAddTaskVal={handleAddTaskVal}
                      handleTransactionValueChange={
                        handleTransactionValueChange
                      }
                      taskArrVal={taskArrVal}
                      handleTrackerDateChange={handleTrackerDateChange}
                      handleTransactionTypeValueChange={
                        handleTransactionTypeValueChange
                      }
                      tranTypeInputRef={tranTypeInputRef}
                      handleSkuValueChange={handleSkuValueChange}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem size="1/4">
                <Card>
                  <Label>Preview:</Label>
                  <div className="ln-u-margin-top*3">
                    <MarketingEventPreview
                      taskArrVal={taskArrVal}
                      eventDetails={eventDetails}
                      selectedCoreLogicType={selectedCoreLogicType}
                    />
                  </div>
                </Card>
              </GridItem>
            </GridWrapper>
          )}
          {activeStep === 1 && selectedCoreLogicType === "REDEMPTION" && (
            <GridWrapper>
              <GridItem size="3/4">
                <Card className="ln-u-margin-bottom*3">
                  <Label>Event Options</Label>
                </Card>
                <Card>
                  <Label>Options :</Label>
                  <div>
                    <EventOptions
                      eventOptions={eventOptions}
                      addOption={addOption}
                      removeOption={removeOption}
                      handleEventOptionsChange={handleEventOptionsChange}
                      handleAddOptionVal={handleAddOptionVal}
                      optionArrVal={optionArrVal}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem size="1/4">
                <Card>
                  <Label>Preview:</Label>
                  <div className="ln-u-margin-top*3">
                    <MarketingEventOptionsPreview
                      optionArrVal={optionArrVal}
                      eventDetails={eventDetails}
                      selectedCoreLogicType={selectedCoreLogicType}
                    />
                  </div>
                </Card>
              </GridItem>
            </GridWrapper>
          )}
          {activeStep === 2 && (
            <GridWrapper>
              <GridItem>
                <Card>
                  <Label>UI Options & Content:</Label>
                  <div>
                    <UiOptionsContent
                      handleChange={handleEventDetailsChange}
                      eventDetails={eventDetails}
                      editScreen={editScreen}
                      selectedCoreLogicType={selectedCoreLogicType}
                    />
                  </div>
                </Card>
              </GridItem>
            </GridWrapper>
          )}
        </GridItem>
        <GridItem>
          <OutlinedButton
            type="button"
            size="small"
            onClick={() => history.push("/marketing-events")}
          >
            Cancel
          </OutlinedButton>
          <FilledButton
            disabled={activeStep === 0}
            primary
            size="small"
            className="ln-u-margin-left*2"
            onClick={() => handleNext(activeStep - 1)}
          >
            Previous
          </FilledButton>
          {(activeStep === 0 || activeStep === 1) && (
            <FilledButton
              disabled={disableNext}
              primary
              size="small"
              className="ln-u-margin-left*2"
              onClick={() => handleNext(activeStep + 1)}
            >
              Next
            </FilledButton>
          )}
          {activeStep === 2 && (
            <FilledButton
              disabled={disableNext}
              primary
              size="small"
              className="ln-u-margin-left*2"
              onClick={() => setOpenConfirmationDialog(true)}
            >
              {editScreen ? "Update" : isDuplicate ? "Clone" : "Create"}
            </FilledButton>
          )}
        </GridItem>
      </GridWrapper>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => setOpenConfirmationDialog(false)}
        open={openConfirmationDialog}
        headingId="dialog-modal"
        className="custom-modal"
      >
        <ModalHeading>Confirm Action!</ModalHeading>
        <Label>Are you sure?</Label>
        <ButtonGroupWrapper actionbar>
          <ButtonGroupPrimary>
            <TextButton onClick={() => setOpenConfirmationDialog(false)}>
              Cancel
            </TextButton>
            <FilledButton
              className="ln-u-margin-left"
              onClick={() => createMarketingEvent()}
            >
              {editScreen ? "Update" : isDuplicate ? "Clone" : "Create"}
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </div>
  );
};

export default withRouter(MarketingEventEditor);
