import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { DisplayType } from "../campaign";

export const DisplayTypeInput = ({ campaign, handleChange }) => (
  <GridWrapper>
    <GridItem style={{ margin: "1rem 0 0 0" }}>
      <Label>
        Display Type (surprise delight will hide the offer in web and apps) '
        name='displayType
      </Label>
      <br />
      <Form.Select
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "16px 8px 16px",
          boxShadow: "0",
          fontFamily: "sans-serif",
          border: "1px solid #737373",
          borderRadius: "2px",
          color: "#737373",
          minHeight: "48px",
        }}
        value={campaign ? campaign.displayType : "NORMAL"}
        name="displayType"
        options={DisplayType}
        type="text"
        onChange={handleChange}
      />
    </GridItem>
  </GridWrapper>
);
