import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Accordion, AccordionItem } from "@jsluna/accordion";
import { Alert, AlertIcon } from "@jsluna/alert";
import { Button, ButtonGroupPrimary, IconButton } from "@jsluna/button";
import { Checkbox } from "@jsluna/form";
import { Form, FormGroup, SearchField, SelectField } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import {
  Basket,
  Download,
  ErrorCircle,
  Plus,
  Search,
  Upload,
} from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";

import {
  ButtonDropdown,
  ButtonDropdownItem,
} from "../../common/components/ButtonDropdown";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import {
  MultiSelectField,
  TagsField,
} from "../../common/components/FormFields";
import { InlineGroup } from "../../common/components/InlineGroup";
import NoResultFound from "../../common/components/NoResultFound";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Pagination from "../../common/components/Pagination";
import StatusIndicator from "../../common/components/StatusIndicator";
import { triggerDownload } from "../../common/components/downloadUtils";
import { toDisplayString } from "../../common/dates";
import { IgnoreExtraCloneProps } from "../../common/helpers/IgnoreExtraCloneProps";
import bulkAsyncOperation from "../../common/helpers/bulkAsyncOperation";
import { useAsyncActionState } from "../../common/hooks/useAsyncActionState";
import { useCampaignsListSelection } from "../../common/hooks/useCampaignsListSelection";
import { useSearch } from "../../common/hooks/useSearch";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import { usePagination } from "../../contexts/PaginationContext";
import CampaignClient from "../../services/CampaignClient";
import { LoadingState } from "../../services/http";
import "./CampaignDashboard.scss";
import { validCampaignTypes } from "./options";

const campaignTypeToLabel = Object.fromEntries(
  validCampaignTypes.map((x) => [x.value, x.label])
);

const normaliseCampaignType = (type) =>
  campaignTypeToLabel[type] ?? "Unknown Type";

const apiClient = new CampaignClient();

const getDashboardData = async (page, filters) => {
  const response = await apiClient.fetchCampaigns(
    page - 1, // The API starts counting pages at 0. So -1 is to match the index.
    25,
    JSON.parse(sessionStorage.getItem("campaignDashboardFilters")) || {
      ...filters,
      excludedCampaignTypes: ["INCENTIVE"],
    }
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
};

const downloadExport = async (campaignIds, tags, format) => {
  if (campaignIds.length) {
    try {
      const downloadName = `Campaigns-export.csv`;
      const response = await apiClient.exportCampaigns(
        campaignIds,
        null,
        format
      );
      if (response.status !== 200) {
        throw new Error(response);
      }
      triggerDownload(downloadName, response.data);
      toast.success("Download Success");
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      if (err?.response?.status === 400) {
        toast.error("The file cannot be downloaded");
      } else {
        toast.error("Failed to download export. " + message);
      }
      throw err;
    }
  } else if (tags.length) {
    try {
      const downloadTags = `Campaign-export-${tags}.csv`;
      const response = await apiClient.exportCampaigns(null, tags, format);
      if (response.status !== 200) {
        throw new Error(response);
      }
      triggerDownload(downloadTags, response.data);
      toast.success("Download Success");
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      if (err?.response?.status === 400) {
        toast.error("The file cannot be downloaded");
      } else {
        toast.error("Failed to download export. " + message);
      }
      throw err;
    }
  }
};

const commitCampaigns = async (campaignIds, clearAllDataSelectedOnAction) => {
  const { total, failed } = await bulkAsyncOperation(
    (id) => apiClient.commitCampaign(id),
    campaignIds
  );

  if (failed) {
    toast.info(failed + " campaigns commit failed.");
  } else {
    toast.success(total + " campaigns committed.");
    clearAllDataSelectedOnAction();
  }
};

const stopCampaigns = async (campaignIds, clearAllDataSelectedOnAction) => {
  const { total, failed } = await bulkAsyncOperation(
    (data) => apiClient.stopCampaign(data),
    campaignIds
  );

  if (failed) {
    toast.info(failed + " campaigns stop failed.");
  } else {
    toast.success(total + " campaigns stopped.");
    clearAllDataSelectedOnAction();
  }
};

const pauseCampaigns = async (campaignIds, clearAllDataSelectedOnAction) => {
  const { total, failed } = await bulkAsyncOperation(
    (data) => apiClient.pauseCampaign(data),
    campaignIds
  );

  if (failed) {
    toast.info(failed + " campaigns pause failed.");
  } else {
    toast.success(total + " campaigns paused.");
    clearAllDataSelectedOnAction();
  }
};

const resumeCampaigns = async (campaignIds, clearAllDataSelectedOnAction) => {
  const { total, failed } = await bulkAsyncOperation(
    (id) => apiClient.resumeCampaign(id),
    campaignIds
  );

  if (failed) {
    toast.info(failed + " campaigns resume failed.");
  } else {
    toast.success(total + " campaigns resumed.");
    clearAllDataSelectedOnAction();
  }
};

const onBulkUpdateAndCommit = async (
  campaignIds,
  failingCampaignIds,
  requestBody,
  clearAllDataSelectedOnAction
) => {
  const { total, failed } = await bulkAsyncOperation(
    (id) => apiClient.updateCampaignDates(id, requestBody),
    failingCampaignIds
  );

  if (total > 0) {
    toast.success(total + " campaigns update successful.");
    await commitCampaigns(campaignIds);
    clearAllDataSelectedOnAction();
  } else if (failed) {
    toast.info(failed + " campaigns update failed.");
  } else {
    toast.info("No campaigns were updated.");
  }
};

const onBulkUpdate = async (
  campaignIds,
  requestBody,
  clearAllDataSelectedOnAction
) => {
  //existing bulk id method will stay until the new endpoint works fine.
  // const { total, failed } = await bulkAsyncOperation(
  //   (id) => apiClient.updateCampaignDates(id, requestBody),
  //   campaignIds
  // );

  // if (total > 0) {
  //   toast.success(total + " campaigns update successful.");
  // } else if (failed) {
  //   toast.info(failed + " campaigns update failed.");
  // } else {
  //   toast.info("No campaigns were updated.");
  // }
  const payload = {
    ...requestBody,
    campaignIds,
  };
  await apiClient
    .bulkUpdateCampaignDates(payload)
    .then((res) => {
      if (res && res.status === 200) {
        clearAllDataSelectedOnAction();
        toast.success(campaignIds.length + " campaigns update successful.");
      }
    })
    .catch((err) => {
      toast.info(err + " campaigns update failed.");
    });
};

const CampaignSearchField = ({
  onSubmit,
  disabled,
  searchValue,
  onSearchValueChanged,
  valueName,
  hideLabel,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup
        name={"Search_campaigns_" + valueName + "_form"}
        hideLabel
        label={"Search campaigns " + valueName}
      >
        <SearchField
          name={valueName}
          label={valueName}
          hideLabel={hideLabel}
          placeholder={"Search campaigns by " + valueName}
          hasAction
          onChange={onSearchValueChanged}
          value={searchValue}
          action={
            <Button disabled={disabled} type="submit">
              <span className="ln-u-visually-hidden">Search</span>
              <Search />
            </Button>
          }
        />
      </FormGroup>
    </Form>
  );
};

const getCampaignStatus = ({ active, campaignStatus }) => {
  if (!active) {
    return "Inactive";
  }
  switch (campaignStatus) {
    case "ACTIVE":
      return "Active";
    case "STOPPED":
      return "Stopped";
    case "SUSPENDED":
      return "Paused";
    default:
      return "Unknown";
  }
};

const CampaignDashboard = () => {
  const { selectedCampaignIds, setData, clearData } = usePagination();
  const {
    requestedPage,
    listResults,
    listState,
    listReady,
    reloadPage,
    changePage,
    newSearch,
  } = useSearch(getDashboardData);

  const searchDisabled = listState === LoadingState.IN_PROGRESS;
  const listError = listState === LoadingState.FAILED;
  const showList = listReady && listResults.content;

  const isCmpgnEditor = HasRequiredRoleAccess("campaigns");

  const {
    selectedItems,
    resetSelection,
    selectAll,
    selectItem,
    itemSelected,
    noneSelected,
    allSelected,
    setSelectedIds,
  } = useCampaignsListSelection(
    listResults?.content ?? [],
    (item) => item.id,
    selectedCampaignIds,
    setData,
    requestedPage,
    clearData
  );

  const canCommit =
    !noneSelected &&
    selectedItems.every((item) => getCampaignStatus(item) === "Inactive");
  const canStop =
    !noneSelected &&
    selectedItems.every(
      (item) =>
        getCampaignStatus(item) === "Active" ||
        getCampaignStatus(item) === "Paused"
    );
  const canPause =
    !noneSelected &&
    selectedItems.every((item) => getCampaignStatus(item) === "Active");
  const canResume =
    !noneSelected &&
    selectedItems.every((item) => getCampaignStatus(item) === "Paused");

  const [actionState, performAction] = useAsyncActionState();

  const failingValidationItems = selectedItems.filter(
    (item) =>
      new Date(item.endDate) <= new Date() ||
      new Date(item.startDate) <= new Date()
  );
  const failingValidationItemsIds = failingValidationItems.map(
    (item) => item.id
  );

  const showLoading =
    listState === LoadingState.IN_PROGRESS ||
    actionState === LoadingState.IN_PROGRESS;
  const actionsDisabled = !(
    selectedCampaignIds && selectedCampaignIds.length > 0
  );

  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [tags, setTags] = useState([]);
  const [active, setActive] = useState("");
  const [mode, setMode] = useState("");
  const [clientType, setClientType] = useState([]);
  const [offerType, setOfferType] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [channel, setChannel] = useState([]);

  const nextSearch = apiClient.createSearchFilters(
    name,
    barcode,
    tags,
    active,
    mode,
    clientType,
    offerType,
    createdBy,
    channel
  );

  const downloadDisabled = !(
    selectedCampaignIds && selectedCampaignIds.length > 0
  );
  const [requestedAction, setRequestedAction] = useState(null);
  const [availableTags, setAvailableTags] = useState([]);

  const initializeFiltersFromStorage = () => {
    const parsedFilters = JSON.parse(
      sessionStorage.getItem("campaignDashboardFilters")
    );
    if (parsedFilters) {
      setName(parsedFilters.name || "");
      setBarcode(parsedFilters.barcode || "");
      setTags(parsedFilters.tags || []);
      setActive(parsedFilters.active || "");
      setMode(parsedFilters.mode || "");
      setClientType(parsedFilters.clientType || []);
      setOfferType(parsedFilters.offerType || []);
      setCreatedBy(parsedFilters.createdBy || "");
      setChannel(parsedFilters.channel || "");
    }
  };

  useEffect(() => {
    initializeFiltersFromStorage();
    const loadPageTags = async () => {
      try {
        const response = await apiClient.fetchCampaignTags();
        if (response.status !== 200) {
          throw new Error(response);
        }
        setAvailableTags(response.data.map((n) => n.name));
      } catch (err) {
        console.error(err);
        const message = err?.response?.data?.description ?? "";
        toast.error("Failed to load campaign tags. " + message);
        setAvailableTags(["No tags found"]);
      }
    };
    loadPageTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  useEffect(() => {
    clearAllDataSelectedOnAction();
    // eslint-disable-next-line
  }, [location.pathname]);

  const clearAllDataSelectedOnAction = () => {
    clearData();
    setSelectedIds([]);
  };

  const onDismiss = () => setRequestedAction(null);

  const updateSessionStorageWithFilters = (filters) => {
    sessionStorage.setItem(
      "campaignDashboardFilters",
      JSON.stringify({ ...filters, excludedCampaignTypes: ["INCENTIVE"] })
    );
  };

  const onSearchSubmitted = (e) => {
    e.preventDefault();
    if (searchDisabled) {
      return;
    }
    resetSelection();
    updateSessionStorageWithFilters(nextSearch);
    return newSearch(nextSearch);
  };

  const onTagsChange = (tags) => {
    resetSelection();
    setTags(tags);
    updateSessionStorageWithFilters({ ...nextSearch, tags });
    newSearch({ ...nextSearch, tags });
  };

  const onActiveChange = (active) => {
    resetSelection();
    setActive(active);
    updateSessionStorageWithFilters({ ...nextSearch, active });
    newSearch({ ...nextSearch, active });
  };

  const onModeChange = (mode) => {
    resetSelection();
    setMode(mode);
    updateSessionStorageWithFilters({ ...nextSearch, mode });
    newSearch({ ...nextSearch, mode });
  };

  const onClientTypeChange = (clientType) => {
    resetSelection();
    setClientType(clientType);
    updateSessionStorageWithFilters({ ...nextSearch, clientType });
    newSearch({ ...nextSearch, clientType });
  };

  const onOfferTypeChange = (offerType) => {
    resetSelection();
    setOfferType(offerType);
    updateSessionStorageWithFilters({ ...nextSearch, offerType });
    newSearch({ ...nextSearch, offerType });
  };

  const onChannelChange = (channel) => {
    resetSelection();
    setChannel(channel);
    updateSessionStorageWithFilters({ ...nextSearch, channel });
    newSearch({ ...nextSearch, channel });
  };

  const onClearFilter = () => {
    sessionStorage.removeItem("campaignDashboardFilters"); // Remove the stored filters from session storage
    resetSelection();
    setName("");
    setBarcode("");
    setTags([]);
    setActive("");
    setMode("");
    setClientType([]);
    setOfferType([]);
    setCreatedBy("");
    setChannel([]);
    newSearch(nextSearch);
  };

  return (
    <>
      <div className="campaignsContainer">
        <PageContainer>
          <GridItem size="1/1">
            <PageHeader>
              <Basket />
              Campaigns
            </PageHeader>
          </GridItem>
          <GridItem size={{ md: "2/3" }}>
            <InlineGroup>
              <IconButton
                variant="filled"
                label="Create campaign"
                to="/campaigns/create"
                element={Link}
                disabled={!isCmpgnEditor}
              >
                <Plus />
              </IconButton>
              <IconButton
                variant="outlined"
                label="Bulk Uploads"
                to="/campaigns/upload"
                element={Link}
              >
                <Upload />
              </IconButton>
              <ButtonDropdown
                disabled={!isCmpgnEditor || downloadDisabled}
                label={`Download campaigns exports ${
                  !isCmpgnEditor
                    ? ""
                    : !downloadDisabled
                    ? `(${selectedCampaignIds.length})`
                    : ""
                }`}
                icon={<Download />}
              >
                <ButtonDropdownItem
                  onClick={() =>
                    performAction(() =>
                      downloadExport(
                        selectedCampaignIds,
                        tags,
                        "BARCODE_EXPORT"
                      )
                    )
                  }
                >
                  Download barcode
                </ButtonDropdownItem>
                <ButtonDropdownItem
                  onClick={() =>
                    performAction(() =>
                      downloadExport(
                        selectedCampaignIds,
                        tags,
                        "FINANCE_EXPORT"
                      )
                    )
                  }
                >
                  Download barcode and finance
                </ButtonDropdownItem>
              </ButtonDropdown>
            </InlineGroup>
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <InlineGroup alignEnd>
              <ButtonDropdown
                disabled={!isCmpgnEditor || actionsDisabled}
                label={
                  !isCmpgnEditor || actionsDisabled
                    ? "Campaigns actions"
                    : `Action on ${selectedCampaignIds.length} campaigns`
                }
                variant="outlined"
              >
                {canCommit && (
                  <ButtonDropdownItem
                    onClick={() => setRequestedAction("commit")}
                  >
                    Commit
                  </ButtonDropdownItem>
                )}
                {canPause && (
                  <ButtonDropdownItem
                    onClick={() => setRequestedAction("pause")}
                  >
                    Pause
                  </ButtonDropdownItem>
                )}
                {canResume && (
                  <ButtonDropdownItem
                    onClick={() => setRequestedAction("resume")}
                  >
                    Resume
                  </ButtonDropdownItem>
                )}
                {canStop && (
                  <ButtonDropdownItem
                    onClick={() => setRequestedAction("stop")}
                  >
                    Stop
                  </ButtonDropdownItem>
                )}
                <ButtonDropdownItem
                  onClick={() => setRequestedAction("bulkEdit")}
                >
                  Bulk edit
                </ButtonDropdownItem>
                <ButtonDropdownItem onClick={() => resetSelection()}>
                  Clear selection
                </ButtonDropdownItem>
              </ButtonDropdown>
            </InlineGroup>
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <CampaignSearchField
              onSubmit={onSearchSubmitted}
              disabled={searchDisabled}
              searchValue={name}
              onSearchValueChanged={(e) => setName(e.target.value)}
              valueName="name"
              hideLabel
            />
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <CampaignSearchField
              onSubmit={onSearchSubmitted}
              disabled={searchDisabled}
              searchValue={barcode}
              onSearchValueChanged={(e) => setBarcode(e.target.value)}
              valueName="barcode"
              hideLabel
            />
          </GridItem>
          <GridItem size={{ md: "1/3" }}>
            <Form onSubmit={onSearchSubmitted}>
              <TagsField
                name="Tags"
                label="Tags"
                hideLabel
                existingTags={availableTags}
                value={tags}
                onChange={onTagsChange}
                placeholder="Search campaigns by tag"
                disabled={searchDisabled}
              />
            </Form>
          </GridItem>
          <GridItem className="filter-container" size="1/1">
            <Accordion>
              {/* The accordion expects only AccordionItem elements, clones them and passes more props, that cause errors */}
              <IgnoreExtraCloneProps>
                <ButtonGroupPrimary>
                  <Button
                    onClick={onClearFilter}
                    variant="text"
                    className="clear-filter-button"
                  >
                    Clear all filters
                  </Button>
                </ButtonGroupPrimary>
              </IgnoreExtraCloneProps>
              <AccordionItem id="filters" title="Filter your search">
                <GridItem size={{ md: "1/3" }}>
                  <MultiSelectField
                    name="campaignType"
                    label="Campaign Type"
                    options={validCampaignTypes}
                    value={offerType}
                    onChange={onOfferTypeChange}
                    placeholder="Search by campaign type"
                    disabled={searchDisabled}
                  />
                </GridItem>
                <GridItem size={{ md: "1/3" }}>
                  <MultiSelectField
                    name="type"
                    label="Type"
                    options={[
                      { label: "Welcome", value: "WELCOME" },
                      { label: "Standard", value: "STANDARD" },
                      {
                        label: "Manual Assignment",
                        value: "MANUAL_ASSIGNMENT",
                      },
                      { label: "Trade Driving", value: "TRADE_DRIVING" },
                      { label: "Challenge", value: "CHALLENGE" },
                      { label: "Nectar Price", value: "NECTAR_PRICE" },
                    ]}
                    value={clientType}
                    onChange={onClientTypeChange}
                    placeholder="Search by type"
                    disabled={searchDisabled}
                  />
                </GridItem>
                <GridItem size={{ md: "1/3" }}>
                  <MultiSelectField
                    name="channel"
                    label="Allowed Redemption Channels"
                    options={[
                      { label: "All", value: "ALL" },
                      { label: "In-Store", value: "IN_STORE" },
                      { label: "Groceries Online", value: "GROCERIES_ONLINE" },
                    ]}
                    value={channel}
                    onChange={onChannelChange}
                    placeholder="Search by allowed redemption channels"
                    disabled={searchDisabled}
                  />
                </GridItem>
                <GridItem size={{ md: "1/3" }}>
                  <SelectField
                    label="Mode"
                    name="mode"
                    value={mode}
                    onChange={(e) => onModeChange(e.target.value)}
                    options={[
                      { label: "Open", value: "OPEN" },
                      { label: "Targeted", value: "TARGETED" },
                    ]}
                    disabled={searchDisabled}
                  />
                </GridItem>
                <GridItem size={{ md: "1/3" }}>
                  <SelectField
                    label="Status"
                    name="active"
                    value={active}
                    onChange={(e) => onActiveChange(e.target.value)}
                    options={[
                      { label: "Active", value: "true" },
                      { label: "Inactive", value: "false" },
                    ]}
                    disabled={searchDisabled}
                  />
                </GridItem>
                <GridItem size={{ md: "1/3" }}>
                  <CampaignSearchField
                    onSubmit={onSearchSubmitted}
                    disabled={searchDisabled}
                    searchValue={createdBy}
                    onSearchValueChanged={(e) => setCreatedBy(e.target.value)}
                    valueName="User"
                  />
                </GridItem>
              </AccordionItem>
            </Accordion>
          </GridItem>
          {listError && (
            <GridItem size="1/1">
              <Alert variant="error">
                <AlertIcon>
                  <ErrorCircle aria-label="Error" role="img" />
                </AlertIcon>
                Oops! Something has gone wrong. Please try again, and if this
                continues to happen, please contact Tech Support.
              </Alert>
            </GridItem>
          )}
          {!showList ? null : listResults.content.length > 0 ? (
            <>
              <GridItem size="1/1">
                <Table
                  data={listResults.content}
                  rowKey="id"
                  caption="Campaigns results"
                  visuallyHiddenCaption
                  columns={[
                    {
                      name: "Select",
                      renderHead: (
                        <Checkbox
                          data-testid="select-all-checkbox"
                          name="SelectAll"
                          label="Select"
                          onChange={selectAll}
                          checked={allSelected}
                          disabled={!isCmpgnEditor}
                        />
                      ),
                      accessor: (item) => ({
                        value: (
                          <Checkbox
                            className="campaigns-select-container"
                            name={`checkbox-${item.id}`}
                            label="Select"
                            hideLabel
                            checked={itemSelected(item)}
                            onChange={() => selectItem(item)}
                            disabled={!isCmpgnEditor}
                          />
                        ),
                      }),
                    },
                    {
                      name: "Campaign name",
                      accessor: (item) => ({
                        value: (
                          <Button
                            variant="text"
                            color="dark"
                            to={`/campaigns/edit/${item.id}`}
                            element={Link}
                            disabled={!isCmpgnEditor}
                          >
                            {item.name ? item.name : "Undefined"}
                          </Button>
                        ),
                      }),
                    },
                    {
                      name: "Campaign type",
                      accessor: (item) => ({
                        value: normaliseCampaignType(item.campaignType),
                      }),
                    },
                    {
                      name: "Start date",
                      accessor: (item) => ({
                        value: toDisplayString(item.startDate),
                      }),
                    },
                    {
                      name: "Ends date",
                      accessor: (item) => ({
                        value: toDisplayString(item.endDate),
                      }),
                    },
                    {
                      name: "Status",
                      accessor: (item) => ({
                        value: (
                          <StatusIndicator
                            variant={
                              getCampaignStatus(item) === "Active"
                                ? "success"
                                : getCampaignStatus(item) === "Stopped"
                                ? "error"
                                : getCampaignStatus(item) === "Paused"
                                ? "warning"
                                : "disabled"
                            }
                            text={getCampaignStatus(item)}
                          />
                        ),
                      }),
                    },
                  ]}
                />
              </GridItem>
              {listResults.totalPages > 0 && (
                <GridItem size="1/1">
                  <Pagination
                    currentPage={requestedPage}
                    totalPages={listResults.totalPages}
                    onPageChange={(page) => {
                      changePage(page);
                    }}
                  />
                </GridItem>
              )}
            </>
          ) : (
            <NoResultFound />
          )}
        </PageContainer>
      </div>
      {showLoading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
      <ConfirmationModal
        showModel={requestedAction === "bulkEdit"}
        requestedAction={requestedAction}
        onDismiss={onDismiss}
        onConfirm={async (requestBody) => {
          onDismiss();
          await performAction(() =>
            onBulkUpdate(
              selectedCampaignIds,
              requestBody,
              clearAllDataSelectedOnAction
            )
          );
          await reloadPage();
        }}
        heading="Bulk edit campaigns"
        message={`The following ${selectedCampaignIds.length} campaigns will be edited. Please check the selections before continuing`}
        selectedItems={selectedCampaignIds}
      />
      <ConfirmationModal
        showModel={requestedAction === "commit"}
        requestedAction={requestedAction}
        onDismiss={onDismiss}
        onConfirm={async (requestBody) => {
          onDismiss();
          failingValidationItems && failingValidationItems.length
            ? await performAction(() =>
                onBulkUpdateAndCommit(
                  selectedCampaignIds,
                  failingValidationItemsIds,
                  requestBody,
                  clearAllDataSelectedOnAction
                )
              )
            : await performAction(() =>
                commitCampaigns(
                  selectedCampaignIds,
                  clearAllDataSelectedOnAction
                )
              );
          await reloadPage();
        }}
        heading="Commit campaigns"
        message={`This action will commit the ${selectedCampaignIds.length} selected campaigns!`}
        selectedItems={selectedItems}
        failingValidationItems={failingValidationItems}
      />
      <ConfirmationModal
        showModel={requestedAction === "stop"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await performAction(() =>
            stopCampaigns(selectedCampaignIds, clearAllDataSelectedOnAction)
          );
          await reloadPage();
        }}
        heading="Stop campaigns"
        message={`This action will stop the ${selectedCampaignIds.length} selected campaigns!`}
      />
      <ConfirmationModal
        showModel={requestedAction === "pause"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await performAction(() =>
            pauseCampaigns(selectedCampaignIds, clearAllDataSelectedOnAction)
          );
          await reloadPage();
        }}
        heading="Pause campaigns"
        message={`This action will pause the ${selectedCampaignIds.length} selected campaigns!`}
      />
      <ConfirmationModal
        showModel={requestedAction === "resume"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await performAction(() =>
            resumeCampaigns(selectedCampaignIds, clearAllDataSelectedOnAction)
          );
          await reloadPage();
        }}
        heading="Resume campaigns"
        message={`This action will resume the ${selectedCampaignIds.length} selected campaigns!`}
      />
    </>
  );
};

export default CampaignDashboard;
