import React from "react";
import { Dropdown, Form, Icon } from "semantic-ui-react";

import { Label } from "@jsluna/form";

export const TagsInput = ({
  defaultValue,
  handleChange,
  label,
  placeholder,
  campaignTags,
  onAddTag,
}) => (
  <div>
    {label ? (
      <>
        <Label>{label}</Label>
        <br />
      </>
    ) : (
      ""
    )}
    <Form.Select
      defaultValue={defaultValue || []}
      style={{
        width: "100%",
        backgroundColor: "transparent",
        padding: "8px 0 0 0",
        boxShadow: "0",
        fontFamily: "sans-serif",
        border: "1px solid #737373",
        borderRadius: "2px",
        color: "#737373",
        minHeight: "48px",
      }}
      name="tags"
      multiple
      placeholder={placeholder || "Select tags or type to add one..."}
      allowAdditions
      options={
        campaignTags
          ? campaignTags.map((tag) => ({ value: tag.name, text: tag.name }))
          : []
      }
      search
      header={<Dropdown.Header icon="tags" content="Select tags" />}
      additionLabel={
        <span>
          <Icon name="add" /> Add new tag:{" "}
        </span>
      }
      onAddItem={(event, data) => onAddTag(data.value)}
      onChange={handleChange}
    />
  </div>
);
