import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { containsField } from "./campaign";
import { AccountClientTypeInput } from "./campaignforminputs/AccountClientTypeInput";
import { AllowPointsOverrideInput } from "./campaignforminputs/AllowPointsOverrideInput";
import { ArtworkInput } from "./campaignforminputs/ArtworkInput";
import { DatesInput } from "./campaignforminputs/DatesInput";
import { DefaultExpiryGroup } from "./campaignforminputs/DefaultExpiryGroup";
import { DescriptionInput } from "./campaignforminputs/DescriptionInput";
import { DisplayTypeInput } from "./campaignforminputs/DisplayTypeInput";
import MinAndMaxPoints from "./campaignforminputs/MinAndMaxPoints";
import { MoneyOffGroup } from "./campaignforminputs/MoneyOffGroup";
import { MoneyOffOnlineGroup } from "./campaignforminputs/MoneyOffOnlineGroup";
import { OfferCodeInput } from "./campaignforminputs/OfferCodeInput";
import { PianoCampaignTypeInput } from "./campaignforminputs/PianoCampaignTypeInput";
import { PointsMultiplierInput } from "./campaignforminputs/PointsMultiplierInput";
import { QualifyingSpendInput } from "./campaignforminputs/QualifyingSpendInput";
import { RedemptionsGroup } from "./campaignforminputs/RedemptionsGroup";
import { SkusInput } from "./campaignforminputs/SkusInput";
import { TagsInput } from "./campaignforminputs/TagsInput";
import { TotalTransactionCountAndUnitsAndSpendGroup } from "./campaignforminputs/TotalTransactionCountAndUnitsAndSpendGroup";
import { TriggerCampaignIdInput } from "./campaignforminputs/TriggerCampaignIdInput";
import { UnitsGroup } from "./campaignforminputs/UnitsGroup";

export default class GenericCampaignEditorForm extends React.Component {
  isAllowedField = (field) => {
    return containsField(this.props.allowedFields, field);
  };

  render() {
    return (
      <div>
        <br></br>
        {this.props.campaign.id && (
          <div>
            <h4 className="ln-c-label">ID</h4>
            <br /> {this.props.campaign.id}
            <br />
            <br />
          </div>
        )}
        {this.props.campaign.eagleEyeId && (
          <div>
            <h5 className="ln-c-label">EE ID</h5>
            <br /> {this.props.campaign.eagleEyeId}
            <br />
            <br />
          </div>
        )}
        <PianoCampaignTypeInput
          campaign={this.props.campaign}
          handleChange={this.props.handleChange}
        />

        {this.props.campaign.campaignType && (
          <AccountClientTypeInput
            campaign={this.props.campaign}
            handleChange={this.props.handleChange}
          />
        )}
        {this.props.campaign.accountClientType && (
          <div>
            <DescriptionInput
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />

            <GridWrapper>
              <GridItem size={{ xs: "1/2" }}>
                <OfferCodeInput
                  campaign={this.props.campaign}
                  handleChange={this.props.handleChange}
                />
              </GridItem>
              <GridItem style={{ margin: "1rem 0 0 0" }} size={{ xs: "1/2" }}>
                <TagsInput
                  defaultValue={this.props.campaign && this.props.campaign.tags}
                  label="Tags"
                  handleChange={this.props.handleChange}
                  onAddTag={this.props.onAddTag}
                  campaignTags={this.props.campaignTags}
                />
              </GridItem>
            </GridWrapper>

            {(this.isAllowedField("minimumPoints") ||
              this.isAllowedField("maximumPoints")) && (
              <MinAndMaxPoints
                campaign={this.props.campaign}
                updatePoints={this.props.handleChange}
              />
            )}

            {this.isAllowedField("qualifyingSpend") && (
              <QualifyingSpendInput
                campaign={this.props.campaign}
                handleChange={this.props.handleChange}
              />
            )}

            {this.isAllowedField("discountAmount") &&
              containsField(this.props.allowedFields, "barcode") &&
              this.props.campaign.redeemableTransactionType !==
                "GROCERIES_ONLINE" && (
                <MoneyOffGroup
                  campaign={this.props.campaign}
                  handleChange={this.props.handleChange}
                />
              )}

            {this.props.campaign.redeemableTransactionType ===
              "GROCERIES_ONLINE" && (
              <MoneyOffOnlineGroup
                campaign={this.props.campaign}
                handleChange={this.props.handleChange}
              />
            )}

            <RedemptionsGroup
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />

            {(this.isAllowedField("unit") ||
              this.isAllowedField("step") ||
              this.isAllowedField("minimumUnits") ||
              this.isAllowedField("maximumUnits")) && (
              <UnitsGroup
                campaign={this.props.campaign}
                handleChange={this.props.handleChange}
              />
            )}

            <ArtworkInput
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />

            <AllowPointsOverrideInput
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />

            {this.isAllowedField("pointsMultiplierValue") && (
              <PointsMultiplierInput
                campaign={this.props.campaign}
                handleChange={this.props.handleChange}
              />
            )}

            {(this.isAllowedField("totalTransactionCount") ||
              this.isAllowedField("totalTransactionSpend") ||
              this.isAllowedField("totalTransactionUnits")) && (
              <TotalTransactionCountAndUnitsAndSpendGroup
                campaign={this.props.campaign}
                handleChange={this.props.handleChange}
              />
            )}

            {this.isAllowedField("triggerCampaignId") && (
              <TriggerCampaignIdInput
                campaign={this.props.campaign}
                handleChange={this.props.handleChange}
              />
            )}

            {this.isAllowedField("skus") && (
              <SkusInput handleChange={this.props.handleSkuChange} />
            )}

            <GridWrapper>
              <GridItem style={{ margin: "1rem 0 0 0" }}>
                <Label>Custom campaign text 1 (eg: OCC offer code, etc)</Label>
                <Form.Input
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    boxShadow: "0",
                    fontFamily: "sans-serif",
                    border: "1px solid #737373",
                    borderRadius: "4.571px",
                    color: "#737373",
                    minHeight: "48px",
                  }}
                  defaultValue={this.props.campaign.campaignText1}
                  name="campaignText1"
                  onChange={this.props.handleChange}
                />
              </GridItem>
            </GridWrapper>

            <DatesInput
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
              handleChangeDate={this.props.handleChangeDate}
            />

            <DefaultExpiryGroup
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />

            <DisplayTypeInput
              campaign={this.props.campaign}
              handleChange={this.props.handleChange}
            />
          </div>
        )}
      </div>
    );
  }
}
