import React from "react";

import { Checkbox } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Link, Table } from "@jsluna/react";
import { Pagination } from "@jsluna/react";
import { Display2 } from "@jsluna/typography";

import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import history from "../../history";

const DashboardTable = ({
  marketingEventsList,
  selectedRowMap,
  handleSelectRow,
  onPaginationChange,
  currentPage,
}) => {
  const isSelected = (id) => {
    const selectedRow = selectedRowMap && selectedRowMap.get(id);
    return selectedRow
      ? selectedRowMap && selectedRowMap.get(id).selected
      : false;
  };

  const isMEEditor = HasRequiredRoleAccess("marketing-events");

  return (
    <React.Fragment>
      {marketingEventsList &&
      marketingEventsList.content &&
      marketingEventsList.content.length > 0 ? (
        <GridWrapper>
          <GridItem>
            <Table
              sortable
              responsive
              data={marketingEventsList.content}
              visuallyHiddenCaption
              columns={[
                {
                  name: "Select",
                  sortable: false,
                  accessor: (rowData) => ({ value: rowData }),
                  hideLabel: true,
                  render: ({ value }) => (
                    <Checkbox
                      className="custom-checkbox"
                      name={value && value.id}
                      onChange={(e) => handleSelectRow(e.target.checked, value)}
                      checked={isSelected(value.id)}
                      disabled={!isMEEditor}
                    />
                  ),
                },
                {
                  name: "Marketing event id",
                  accessor: (rowData) => ({
                    value: rowData,
                    displayName: rowData.id,
                  }),
                  sortable: false,
                  hideLabel: true,
                  className: "customCol",
                  render: ({ value }) => (
                    <React.Fragment>
                      <Link
                        style={
                          isMEEditor
                            ? { cursor: "pointer" }
                            : { pointerEvents: "none" }
                        }
                        color="alpha"
                        bare
                        onClick={() =>
                          history.push({
                            pathname: `marketing-events/edit/${value.id}`,
                            editMarketingEvent: {
                              enableEdit: true,
                              isDuplicate: false,
                              rowData: marketingEventsList.content.filter(
                                (data) => data.id.includes(value.id)
                              ),
                            },
                          })
                        }
                      >
                        {value.id}
                      </Link>
                    </React.Fragment>
                  ),
                },
                {
                  name: "Type",
                  accessor: (rowData) => ({ value: rowData.type }),
                  sortable: false,
                },
                {
                  name: "Offer Code",
                  accessor: (rowData) => ({
                    value: rowData.offerCode,
                  }),
                  sortable: false,
                },
              ]}
            />
          </GridItem>
          <GridItem size={{ xs: "1/1" }} className="ln-u-margin-top*3">
            <div className="ln-u-text-align-center">
              <Pagination
                className="marketing-pagination"
                showFirstAndLast
                showPages
                current={currentPage}
                total={
                  marketingEventsList && marketingEventsList.totalPages
                    ? marketingEventsList.totalPages
                    : 1
                }
                onChange={(page, e) => {
                  onPaginationChange(page, e);
                }}
              />
            </div>
          </GridItem>
        </GridWrapper>
      ) : (
        <Display2>No Marketing Events to display</Display2>
      )}
    </React.Fragment>
  );
};

export default DashboardTable;
