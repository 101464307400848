import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Alert, AlertIcon } from "@jsluna/alert";
import { Button, ButtonGroupPrimary, IconButton } from "@jsluna/button";
import { Checkbox } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import { ErrorCircle, Measurement, Plus } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";

import {
  ButtonDropdown,
  ButtonDropdownItem,
} from "../../common/components/ButtonDropdown";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import NoResultFound from "../../common/components/NoResultFound";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Pagination from "../../common/components/Pagination";
import StatusIndicator from "../../common/components/StatusIndicator";
import bulkAsyncOperation from "../../common/helpers/bulkAsyncOperation";
import { useAsyncActionState } from "../../common/hooks/useAsyncActionState";
import { useListSelection } from "../../common/hooks/useListSelection";
import { useSearch } from "../../common/hooks/useSearch";
import { GS1SupplierCodesClient } from "../../services/GS1SupplierCodesClient";
import { LoadingState } from "../../services/http";
import "./GS1SupplierCodeDashboard.scss";

const apiClient = new GS1SupplierCodesClient();

const getDashboardData = async (page, filters) => {
  const response = await apiClient.fetchCodes(
    page - 1, // The API starts counting pages at 0. So -1 is to match the index.
    25
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
};

const toggleStatus = async (ids) => {
  const { total, failed } = await bulkAsyncOperation(
    (id) => apiClient.toggleStatus(id),
    ids
  );

  if (failed) {
    toast.info(failed + " GS1 codes status toggle failed");
  } else {
    toast.success(total + " GS1 codes status updated");
  }
};

const GS1SupplierCodeDashboard = () => {
  const {
    requestedPage,
    listResults,
    listState,
    listReady,
    reloadPage,
    changePage,
  } = useSearch(getDashboardData);

  const listError = listState === LoadingState.FAILED;
  const showList = listReady && listResults.content;

  const {
    selectedIds,
    selectedItems,
    resetSelection,
    selectAll,
    selectItem,
    itemSelected,
    noneSelected,
    allSelected,
  } = useListSelection(listResults?.content ?? [], (item) => item.gs1);

  const actionsDisabled = !showList || noneSelected;
  const canDeactivate =
    !noneSelected && selectedItems.every((item) => item.active === true);
  const canActivate =
    !noneSelected && selectedItems.every((item) => item.active === false);

  const [actionState, performAction] = useAsyncActionState();

  const showLoading =
    listState === LoadingState.IN_PROGRESS ||
    actionState === LoadingState.IN_PROGRESS;

  const [requestedAction, setRequestedAction] = useState(null);

  const onDismiss = () => setRequestedAction(null);

  return (
    <>
      <div className="gs1SupplierCodesContainer">
        <PageContainer>
          <GridItem size="1/1">
            <PageHeader>
              <Measurement />
              GS1 supplier codes
            </PageHeader>
          </GridItem>
          <GridItem size="1/1">
            <IconButton
              variant="filled"
              label="Add new GS1 code"
              element={Link}
              to="/gs1suppliercodes/create"
            >
              <Plus />
            </IconButton>
            <ButtonGroupPrimary>
              <ButtonDropdown
                disabled={actionsDisabled}
                label={
                  actionsDisabled
                    ? "GS1 codes actions"
                    : `Action on ${selectedIds.length} GS1 codes`
                }
                variant="outlined"
              >
                {canActivate && (
                  <ButtonDropdownItem
                    onClick={() => setRequestedAction("activate")}
                  >
                    Activate
                  </ButtonDropdownItem>
                )}
                {canDeactivate && (
                  <ButtonDropdownItem
                    onClick={() => setRequestedAction("deactivate")}
                  >
                    Deactivate
                  </ButtonDropdownItem>
                )}
                <ButtonDropdownItem onClick={resetSelection}>
                  Clear selection
                </ButtonDropdownItem>
              </ButtonDropdown>
            </ButtonGroupPrimary>
          </GridItem>
          {listError && (
            <GridItem size="1/1">
              <Alert variant="error">
                <AlertIcon>
                  <ErrorCircle aria-label="Error" role="img" />
                </AlertIcon>
                Oops! Something has gone wrong. Please try again, and if this
                continues to happen, please contact Tech Support.
              </Alert>
            </GridItem>
          )}

          {!showList ? null : listResults.content.length > 0 ? (
            <>
              <GridItem size="1/1">
                <Table
                  data={listResults.content}
                  rowKey="id"
                  caption="GS1 Code results"
                  visuallyHiddenCaption
                  columns={[
                    {
                      name: "Select",
                      renderHead: (
                        <Checkbox
                          data-testid="select-all-checkbox"
                          name="SelectAll"
                          label="Select"
                          onChange={selectAll}
                          checked={allSelected}
                        />
                      ),
                      accessor: (item) => ({
                        value: (
                          <Checkbox
                            className="gs1Code-select-container"
                            name={`checkbox-${item.gs1}`}
                            label="Select"
                            hideLabel
                            checked={itemSelected(item)}
                            onChange={() => selectItem(item)}
                          />
                        ),
                      }),
                    },
                    {
                      name: "GS1 Code",
                      accessor: (item) => ({
                        value: (
                          <Button
                            variant="text"
                            color="dark"
                            to={`/gs1suppliercodes/view/${item.gs1}`}
                            element={Link}
                          >
                            {item.gs1}
                          </Button>
                        ),
                      }),
                    },
                    {
                      name: "GS1 Description",
                      accessor: (item) => ({ value: item.description }),
                    },
                    {
                      name: "GS1 Category",
                      accessor: (item) => ({ value: item.category }),
                    },
                    {
                      name: "Status",
                      accessor: (item) => {
                        return {
                          value: (
                            <StatusIndicator
                              variant={item.active ? "success" : "disabled"}
                              text={item.active ? "Active" : "Inactive"}
                            />
                          ),
                        };
                      },
                    },
                  ]}
                />
              </GridItem>
              {listResults.totalPages > 0 && (
                <GridItem size="1/1">
                  <Pagination
                    currentPage={requestedPage}
                    totalPages={listResults.totalPages}
                    onPageChange={(page) => {
                      resetSelection();
                      changePage(page);
                    }}
                  />
                </GridItem>
              )}
            </>
          ) : (
            <NoResultFound />
          )}
        </PageContainer>
      </div>
      {showLoading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
      <ConfirmationModal
        showModel={requestedAction === "activate"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await performAction(() => toggleStatus(selectedIds));
          await reloadPage();
        }}
        heading="Activate GS1 codes"
        message={`This action will activate the ${selectedIds.length} selected GS1 codes!`}
      />
      <ConfirmationModal
        showModel={requestedAction === "deactivate"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await performAction(() => toggleStatus(selectedIds));
          await reloadPage();
        }}
        heading="Deactivate GS1 codes"
        message={`This action will deactivate the ${selectedIds.length} selected GS1 codes!`}
      />
    </>
  );
};

export default GS1SupplierCodeDashboard;
