import React from "react";
import { Form, Grid, Icon, Message } from "semantic-ui-react";

import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";

import OfferCodeClient from "../../services/OfferCodeClient";
import { LoadingState } from "../../services/http";
import { InitialState } from "./OfferCode";
import { OfferCodeEditorForm } from "./OfferCodeEditorForm";

const offerCodeClient = new OfferCodeClient();

export default class OfferCodeEditor extends React.Component {
  state = {
    loadingState: undefined,
    offerCode: null,
    error: undefined,
    offerCodeUpdateError: undefined,
    offerCodeUpdateSuccess: undefined,
    commitTrigger: undefined,
    offerCodeForm: null,
  };

  componentDidMount() {
    this.reloadOfferCode();
  }

  getCurrentOfferCodeId = () => {
    return this.props.currentOfferCodeId;
  };

  isNewOfferCode = () => {
    return this.props.currentOfferCodeId === null;
  };

  reloadOfferCode = () => {
    if (this.isNewOfferCode()) {
      this.setState({ offerCode: InitialState() });
    } else {
      offerCodeClient
        .fetchOfferCode(this.getCurrentOfferCodeId())
        .then(({ data }) => {
          this.setState({ offerCode: data });
        })
        .catch((err) => {
          console.log(err);
          this.clearMessages(() => {
            this.setState({
              offerCodeUpdateError: "Failed to load offer code",
            });
          });
        });
    }
  };

  clearMessages(cb) {
    this.setState(
      {
        offerCodeUpdateError: undefined,
        offerCodeUpdateSuccess: undefined,
      },
      cb
    );
  }

  handleSave = () => {
    this.clearMessages();
    this.createOrUpdateOfferCode();
  };

  createOrUpdateOfferCode = () => {
    this.setState(
      {
        offerCode: { ...this.state.offerCode },
      },
      () => {
        offerCodeClient
          .createOrUpdateOfferCode(this.state.offerCode)
          .then(() => {
            this.props.close();
          })
          .catch((err) => {
            this.clearMessages(() => {
              this.setState({
                offerCodeUpdateError: err.response.data.description,
                loadingState: LoadingState.FAILED,
              });
            });
          });
      }
    );
  };

  handleChange = (e, { name, value }) => {
    this.clearMessages(() => {
      this.setState({
        offerCode: { ...this.state.offerCode, [name]: value },
      });
    });
  };

  deleteOfferCode = () => {
    const id = this.state.offerCode.id;
    offerCodeClient
      .deleteOfferCode(id)
      .then(() => this.props.close())
      .catch((err) => {
        this.clearMessages(() => {
          this.setState({
            offerCodeUpdateError: err.response.data.description,
            loadingState: LoadingState.FAILED,
          });
        });
      });
  };

  render() {
    const {
      error,
      offerCode,
      offerCodeUpdateError,
      offerCodeUpdateSuccess,
      loadingState,
    } = this.state;

    if (!offerCode && !error) {
      return null;
    }

    return (
      <div>
        <Form onSubmit={this.handleSave} widths="equal">
          <ButtonGroupWrapper>
            <ButtonGroupSecondary>
              <OutlinedButton
                type="button"
                size="small"
                onClick={() => this.props.close()}
              >
                Close
              </OutlinedButton>
              &nbsp;&nbsp;&nbsp;
              <FilledButton
                primary
                size="small"
                type="submit"
                disabled={this.props.isActive}
                loading={loadingState === LoadingState.PENDING}
              >
                Save
              </FilledButton>
              &nbsp;&nbsp;&nbsp;
              {this.isNewOfferCode() ? null : (
                <FilledButton
                  type="button"
                  size="small"
                  color="red"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to delete this offer code?"
                      )
                    )
                      this.deleteOfferCode();
                  }}
                >
                  Delete
                </FilledButton>
              )}
            </ButtonGroupSecondary>
          </ButtonGroupWrapper>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <OfferCodeEditorForm
                  offerCode={offerCode}
                  handleChange={this.handleChange}
                  isNewOfferCode={this.getCurrentOfferCodeId() === null}
                />

                <br />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        {offerCodeUpdateError ? (
          <Message attached="bottom" error>
            <Icon name="warning circle" />
            {offerCodeUpdateError}
          </Message>
        ) : null}

        {offerCodeUpdateSuccess ? (
          <Message attached="bottom" success>
            <Icon name="check" />
            Updated the Offer code
          </Message>
        ) : null}
      </div>
    );
  }
}
