import React from "react";
import { Form } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export const MoneyOffOnlineGroup = (props) => {
  return (
    <GridWrapper>
      <GridItem style={{ margin: "1rem 0 0 0" }}>
        <Label>Money Off in pence</Label>
        <Form.Input
          style={{
            width: "100%",
            backgroundColor: "transparent",
            boxShadow: "0",
            fontFamily: "sans-serif",
            border: "1px solid #737373",
            borderRadius: "4.571px",
            color: "#737373",
            minHeight: "48px",
          }}
          defaultValue={props.campaign.discountAmount}
          name="discountAmount"
          type="number"
          placeholder="In PENCE, value of discount"
          onChange={props.handleChange}
        />
      </GridItem>
    </GridWrapper>
  );
};
