import { PropTypes } from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

export class UsersTableView extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
  };

  render() {
    const usrs = this.props.users.sort((a, b) =>
      a.username.toLowerCase() > b.username.toLowerCase()
        ? 1
        : b.username.toLowerCase() > a.username.toLowerCase()
        ? -1
        : 0
    );
    const tableBody = usrs.map((user, i) => {
      return <ManagementUserSummaryRowWithRouter key={i} user={user} />;
    });

    return (
      <div class="ln-c-table-container">
        <table class="ln-c-table">
          <caption class="ln-c-table__caption ln-u-visually-hidden">
            List of Users.
          </caption>
          <thead class="ln-c-table__header">
            <tr class="ln-c-table__row ln-c-table__header-row">
              <th class="ln-c-table__header-cell customCol">Role</th>
              <th class="ln-c-table__header-cell customCol">Username</th>
              <th class="ln-c-table__header-cell customCol">Email</th>
              <th class="ln-c-table__header-cell customHead">Last Updated</th>
            </tr>
          </thead>
          <tbody class="ln-c-table__body">{tableBody}</tbody>
        </table>
      </div>
    );
  }
}

class ManagementUserSummaryRow extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      updated: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.viewUser = this.viewUser.bind(this);
  }

  viewUser(id) {
    this.props.history.push(`/management-user/${id}`);
  }

  getTagColor() {
    if (this.props.user.userType === "God") return "red";
    else if (this.props.user.userType === "Developer") return "green";
    else if (this.props.user.userType === "Careline") return "violet";
    else if (this.props.user.userType === "Campaign Editor") return "olive";
    else if (this.props.user.userType === "Administrator") return "orange";
    else return "purple";
  }

  render() {
    return (
      <tr
        class="ln-c-table__row"
        onClick={(e) => this.viewUser(this.props.user.id)}
        style={{ cursor: "pointer" }}
      >
        <td class="ln-c-table__cell">
          {this.props.user.userType && (
            <span
              style={{
                backgroundColor: `${this.getTagColor()}`,
                borderRadius: "3px",
                color: "#fff",
                padding: "5px",
              }}
            >
              {this.props.user.userType}
            </span>
          )}
        </td>
        <td class="ln-c-table__cell">{this.props.user.username}</td>
        <td class="ln-c-table__cell">{this.props.user.email}</td>
        <td class="ln-c-table__cell">{this.props.user.updated}</td>
      </tr>
    );
  }
}

const ManagementUserSummaryRowWithRouter = withRouter(ManagementUserSummaryRow);

export default UsersTableView;
