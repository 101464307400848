import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Alert, AlertIcon } from "@jsluna/alert";
import { Button, IconButton } from "@jsluna/button";
import { Form, FormGroup, SearchField } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import { ErrorCircle, Plus, Scan, Search } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";
import { Table } from "@jsluna/table";

import NoResultFound from "../../common/components/NoResultFound";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Pagination from "../../common/components/Pagination";
import { toApiFormat, toDisplayString } from "../../common/dates";
import { useSearch } from "../../common/hooks/useSearch";
import { BarcodeBlocklistsClient } from "../../services/BarcodeBlocklistsClient";
import { LoadingState } from "../../services/http";

const apiClient = new BarcodeBlocklistsClient();

const getDashboardData = async (page, filters) => {
  const response = await apiClient.fetchBarcodes(
    page - 1, // The API starts counting pages at 0. So -1 is to match the index.
    25,
    filters.name,
    filters.barcode
  );
  if (response.status !== 200) {
    throw new Error(response);
  }

  //Updating response with expiry date
  response.data.content = response.data.content.map((item) => {
    if (!item.created) {
      return { ...item, expiry: "" };
    }
    let expiry = new Date(item.created);
    expiry.setFullYear(expiry.getFullYear() + 2);
    expiry = toApiFormat(expiry);
    return { ...item, expiry };
  });

  return response.data;
};

const BarcodeSearchField = ({
  onSubmit,
  disabled,
  searchValue,
  onSearchValueChanged,
  valueName,
  hideLabel,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup
        name={"Search_blocklist_" + valueName + "_form"}
        hideLabel
        label={"Search blocklist " + valueName}
      >
        <SearchField
          name={valueName}
          label={valueName}
          hideLabel={hideLabel}
          placeholder={"Search blocked barcode by " + valueName}
          hasAction
          onChange={onSearchValueChanged}
          value={searchValue}
          action={
            <Button disabled={disabled} type="submit">
              <span className="ln-u-visually-hidden">Search</span>
              <Search />
            </Button>
          }
        />
      </FormGroup>
    </Form>
  );
};

export const BarcodeBlocklistDashboard = () => {
  const {
    requestedPage,
    listResults,
    listState,
    listReady,
    changePage,
    newSearch,
  } = useSearch(getDashboardData);

  const searchDisabled = listState === LoadingState.IN_PROGRESS;
  const listError = listState === LoadingState.FAILED;
  const showList = listReady && listResults.content;
  const showLoading = listState === LoadingState.IN_PROGRESS;

  const [name, setName] = useState("");
  const [barcode, setBarcode] = useState("");

  const onNameChanged = (e) => {
    setName(e.target.value);
    setBarcode("");
  };
  const onBarcodeChanged = (e) => {
    setName("");
    setBarcode(e.target.value);
  };

  const onSearchSubmitted = (e) => {
    e.preventDefault();
    if (searchDisabled) {
      return;
    }
    return newSearch({ name: name, barcode: barcode });
  };

  return (
    <>
      <PageContainer>
        <GridItem size="1/1">
          <PageHeader>
            <Scan />
            Barcode blocklist
          </PageHeader>
        </GridItem>
        <GridItem size="1/1">
          <IconButton
            variant="filled"
            label="Block new barcode"
            element={Link}
            to="/barcodeblocklists/create"
          >
            <Plus />
          </IconButton>
        </GridItem>
        {listError && (
          <GridItem size="1/1">
            <Alert variant="error">
              <AlertIcon>
                <ErrorCircle aria-label="Error" role="img" />
              </AlertIcon>
              Oops! Something has gone wrong. Please try again, and if this
              continues to happen, please contact Tech Support.
            </Alert>
          </GridItem>
        )}

        <GridItem size={{ md: "1/2" }}>
          <BarcodeSearchField
            onSubmit={onSearchSubmitted}
            disabled={searchDisabled}
            searchValue={name}
            onSearchValueChanged={onNameChanged}
            valueName="name"
            hideLabel
          />
        </GridItem>
        <GridItem size={{ md: "1/2" }}>
          <BarcodeSearchField
            onSubmit={onSearchSubmitted}
            disabled={searchDisabled}
            searchValue={barcode}
            onSearchValueChanged={onBarcodeChanged}
            valueName="barcode number"
            hideLabel
          />
        </GridItem>

        {!showList ? null : listResults.content.length > 0 ? (
          <>
            <GridItem size="1/1">
              <Table
                data={listResults.content}
                rowKey="id"
                caption="Barcode blocklist results"
                visuallyHiddenCaption
                columns={[
                  {
                    name: "Barcode number",
                    accessor: (item) => ({
                      value: (
                        <Button
                          variant="text"
                          color="dark"
                          to={`/barcodeblocklists/edit/${item.barcode}`}
                          element={Link}
                        >
                          {item.barcode}
                        </Button>
                      ),
                    }),
                  },
                  {
                    name: "Barcode name",
                    accessor: (item) => ({ value: item.name }),
                  },
                  {
                    name: "Barcode description",
                    accessor: (item) => ({ value: item.description }),
                  },
                  {
                    name: "Date created",
                    accessor: (item) => ({
                      value: toDisplayString(item.created),
                    }),
                  },
                  {
                    name: "Expiry date",
                    accessor: (item) => ({
                      value: toDisplayString(item.expiry),
                    }),
                  },
                ]}
              />
            </GridItem>
            {listResults.totalPages > 0 && (
              <GridItem size="1/1">
                <Pagination
                  currentPage={requestedPage}
                  totalPages={listResults.totalPages}
                  onPageChange={(page) => changePage(page)}
                />
              </GridItem>
            )}
          </>
        ) : (
          <NoResultFound />
        )}
      </PageContainer>
      {showLoading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};

export default BarcodeBlocklistDashboard;
