import { isEmpty, map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Button, Icon, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Cancel, Plus } from "@jsluna/icons";

import OfferCodeClient from "../../services/OfferCodeClient";
import OfferCodeEditor from "./OfferCodeEditor";

const offerCodeClient = new OfferCodeClient();

const viewStates = Object.freeze({
  EDIT: Symbol("Edit"),
  LIST: Symbol("List"),
});

class OfferCodeView extends React.Component {
  state = {
    offerCodes: [],
    error: undefined,
    viewState: viewStates.LIST,
    currentOfferCodeId: null,
  };

  constructor() {
    super();
    this.searchText = "";
  }

  componentDidMount() {
    this.reloadOfferCodes();
    window.onpopstate = this.handleBackClick;
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handleBackClick = (e) => {
    if (this.state.viewState === viewStates.EDIT) {
      e.preventDefault();
      this.setState({
        viewState: viewStates.LIST,
      });
    } else {
      return true;
    }
  };

  reloadOfferCodes() {
    offerCodeClient
      .fetchOfferCodes()
      .then(({ data }) => {
        this.setState({ offerCodes: data });
      })
      .catch((err) => {
        this.setState({ error: "Failed to load offer codes list : " + err });
      });
  }

  deleteOfferCode = (id) => {
    offerCodeClient.deleteOfferCode(id).then(() => {
      toast.success(`Deleted offer code ${id ? ` '${id}'` : ""}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
      });
      this.reloadOfferCodes();
    });
  };

  onEditClick = (id) => {
    this.setState({
      currentOfferCodeId: id,
      viewState: viewStates.EDIT,
    });
    this.props.history.push("/offercodes");
  };

  onCreateClick = () => {
    this.setState({
      currentOfferCodeId: null,
      viewState: viewStates.EDIT,
    });
    this.props.history.push("/offercodes");
  };

  currentView = () => {
    const props = {
      onEditClick: this.onEditClick,
      deleteOfferCode: this.deleteOfferCode,
    };

    switch (this.state.viewState) {
      case viewStates.EDIT:
        return (
          <OfferCodeEditor
            key={this.state.currentOfferCodeId}
            currentOfferCodeId={this.state.currentOfferCodeId}
            close={() => {
              this.setState({ viewState: viewStates.LIST });
              this.reloadOfferCodes();
            }}
          />
        );
      case viewStates.LIST:
        return (
          <div>
            <ButtonGroupPrimary>
              <FilledButton
                style={{ height: "100%" }}
                onClick={this.onCreateClick}
              >
                <Plus />
                &nbsp;&nbsp;Create Offer Code
              </FilledButton>
            </ButtonGroupPrimary>
            <OfferCodesList offerCodeData={this.state.offerCodes} {...props} />
          </div>
        );
      default:
        return <div />;
    }
  };

  render() {
    const { offerCodes, error } = this.state;
    if (!offerCodes && !error) {
      return null;
    }
    return (
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper>
          <GridItem>
            <span>
              <Icon circular name="microchip" />
              &nbsp;&nbsp;<Label>Offer Codes</Label>
            </span>
          </GridItem>
          <GridItem>{this.currentView()}</GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

const OfferCodesList = ({ offerCodeData, onEditClick, deleteOfferCode }) => {
  if (offerCodeData.error)
    return <ErrorLoadingOfferCode message={offerCodeData.error} />;

  offerCodeData.sort(compareOfferCodes);

  return (
    <OfferCodeTable>
      <OfferCodeTableHeader />
      <OfferCodeTableBody>
        {map(offerCodeData, (p) => (
          <OfferCodeRow
            deleteOfferCode={deleteOfferCode}
            offerCode={p}
            key={p.id}
            onClick={() => {
              onEditClick(p.id);
            }}
          />
        ))}
      </OfferCodeTableBody>
    </OfferCodeTable>
  );
};

function compareOfferCodes(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

const OfferCodeTableHeader = () => (
  <thead class="ln-c-table__header">
    <tr class="ln-c-table__row ln-c-table__header-row">
      {[
        "Offer Code Id",
        "Display type",
        "Details",
        "Description",
        "Remove",
      ].map((header, idx) => (
        <th class="ln-c-table__header-cell customCol" key={idx}>
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const OfferCodeTable = (props) => (
  <div style={{ paddingTop: "4.5rem" }}>
    <table class="ln-c-table" celled striped>
      {props.children}
    </table>
  </div>
);
const OfferCodeTableBody = (props) => (
  <tbody class="ln-c-table__body">{props.children}</tbody>
);

const OfferCodeRow = (props) => {
  const { offerCode, onClick } = props;
  const errors = [];
  return (
    <tr class="ln-c-table__row">
      <td class="ln-c-table__cell">
        <h4>
          <Button onClick={onClick}>
            {offerCode.id ? offerCode.id : "Undefined"}
            {isEmpty(errors) ? null : (
              <span>
                &nbsp;
                <Icon
                  title="Invalid offer code"
                  name="warning sign"
                  color="orange"
                />
              </span>
            )}
          </Button>
        </h4>
      </td>
      <td class="ln-c-table__cell">{offerCode.displayType}</td>
      <td class="ln-c-table__cell">{offerCode.details}</td>
      <td class="ln-c-table__cell">{offerCode.description}</td>
      <td class="ln-c-table__cell" collapsing>
        <OutlinedButton
          onClick={() => {
            if (
              window.confirm("Are you sure you wish to delete this offer code?")
            )
              props.deleteOfferCode(offerCode.id);
          }}
        >
          <Cancel />
        </OutlinedButton>
      </td>
    </tr>
  );
};

const ErrorLoadingOfferCode = (props) => (
  <Message negative>
    <p>{props.message}</p>
  </Message>
);

OfferCodeView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(OfferCodeView);
