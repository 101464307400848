import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Checkbox, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { SelectField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Modal, ModalHeading } from "@jsluna/modal";

import "../../main.scss";
import ManagementUserClient from "../../services/ManagementUserClient";
import { LoadingState } from "../../services/http";
import { getSessionCookieData } from "../../services/sessionCookie";

const managementUserClient = new ManagementUserClient();

// This wrapper can go if the component is converted to a functional component
const WithSessionContext = (props) => {
  const sessionData = getSessionCookieData();
  const history = useHistory();
  const { id } = useParams();
  return (
    <UserManagementView
      {...props}
      history={history}
      id={id}
      sessionData={sessionData}
    />
  );
};

export default WithSessionContext;

class UserManagementView extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };
  state = {
    user: undefined,
    loadingState: LoadingState.PENDING,
    isEmailBeingEdited: false,
  };

  constructor(props, context) {
    super(props, context);
    this.switchPermission = this.switchPermission.bind(this);
  }

  componentDidMount() {
    this.initialState();
  }

  initialState() {
    const id = this.props.id ? this.props.id : "";
    managementUserClient
      .fetchUser(id)
      .then(({ data }) => {
        this.setState({
          user: data,
          loadingState: LoadingState.SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  }

  toggleEmailEditing = () => {
    this.setState({ isEmailBeingEdited: !this.state.isEmailBeingEdited });
  };

  onEmailChanged = (newEmail) => {
    this.setState({ user: { ...this.state.user, email: newEmail } });
  };

  switchPermission = (permissionId) => {
    managementUserClient
      .switchPermission(this.state.user.id, permissionId)
      .then(() => {
        this.initialState();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  };

  tagUser = (tag) => {
    managementUserClient.updateRole(
      this.state.user.id,
      this.state.roleSelected.toLowerCase().replace(" ", "")
    );

    managementUserClient
      .tagUser(this.state.user.id, tag)
      .then(() => {
        this.initialState();
        this.setState({
          SetRoleClicked: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  };

  deactivateUser = () => {
    managementUserClient
      .deactivate(this.state.user.id)
      .then(() => this.props.history.push(`/management-user`))
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingState: LoadingState.FAILED,
        });
      });
  };

  dropdownChanged = (e, t) => {
    this.tagUser(t.value);
  };

  tagChanged = (e) => {
    this.tagUser(e.target.value);
  };

  toggleResetModal = () => {
    this.setState({
      ResetClicked: true,
    });
  };

  toggleSetRoleModal = () => {
    this.setState({
      SetRoleClicked: true,
    });
  };

  toggleDeactivateRoleModal = () => {
    this.setState({
      SetDeactivateClicked: true,
    });
  };

  toggleSetLevelModal = () => {
    this.setState({
      SetLevelClicked: true,
    });
  };

  updateEmail = (e) => {
    this.setState({
      emailValue: e.target.value,
    });
  };

  saveNewEmailAndStopEditing = () => {
    managementUserClient.updateDetails(
      this.state.user.id,
      this.state.emailValue
    );
    this.onEmailChanged(this.state.emailValue);
    this.setState({ ResetClicked: false });
  };

  dropDownRender = () => {
    const options = [
      { label: "Developer", value: "Developer" },
      { label: "Careline", value: "Careline" },
      { label: "Campaign Editor", value: "Campaign Editor" },
      { label: "Administrator", value: "Administrator" },
    ];
    return (
      <SelectField
        name="select-field-1"
        label="Choose Role"
        options={options}
        onChange={(e) => this.selectRole(e)}
      />
    );
  };

  selectRole = (e) => {
    this.setState({ roleSelected: e.value ? e.value : e.target.value });
  };

  ResetModal = () => {
    return (
      <Modal
        open={this.state.ResetClicked}
        fullScreen
        restrictClose
        alert
        headingId="dialog-modal"
      >
        <ModalHeading element="h3">E-mail reset</ModalHeading>
        <div class="ln-c-form-group">
          <div class="ln-c-input-group">
            <input
              id="form-group-1"
              name="form-group-1"
              type="text"
              class="ln-c-text-input ln-c-input-group__control"
              placeholder="Enter a valid role"
              required=""
              aria-invalid="false"
              value={this.state.emailValue}
              onChange={(e) => this.updateEmail(e)}
            />
          </div>
        </div>
        <ButtonGroupPrimary>
          <FilledButton onClick={() => this.saveNewEmailAndStopEditing()}>
            Confirm
          </FilledButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <OutlinedButton
            onClick={() => this.setState({ ResetClicked: false })}
          >
            Close
          </OutlinedButton>
        </ButtonGroupPrimary>
      </Modal>
    );
  };

  SetRoleModal = () => {
    return (
      <Modal
        open={this.state.SetRoleClicked}
        fullScreen
        restrictClose
        alert
        headingId="dialog-modal"
        className="modal-overflow"
      >
        <ModalHeading element="h3">Set Role</ModalHeading>
        {this.dropDownRender()}

        <ButtonGroupPrimary>
          <FilledButton onClick={() => this.tagUser(this.state.roleSelected)}>
            Confirm
          </FilledButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <OutlinedButton
            onClick={() => this.setState({ SetRoleClicked: false })}
          >
            Close
          </OutlinedButton>
        </ButtonGroupPrimary>
      </Modal>
    );
  };

  DeactivateModal = () => {
    return (
      <Modal
        open={this.state.SetDeactivateClicked}
        fullScreen
        restrictClose
        alert
        headingId="dialog-modal"
        className="modal-overflow"
      >
        <p>
          <b>Are you Sure Deactivate Account ?</b>
        </p>
        <ButtonGroupPrimary>
          <FilledButton onClick={this.deactivateUser}>Confirm</FilledButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <OutlinedButton
            onClick={() => this.setState({ SetDeactivateClicked: false })}
          >
            Close
          </OutlinedButton>
        </ButtonGroupPrimary>
      </Modal>
    );
  };

  render() {
    return (
      <>
        {this.state.loadingState === LoadingState.SUCCESS ? (
          <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
            {this.ResetModal()}
            {this.SetRoleModal()}
            {this.DeactivateModal()}
            <GridWrapper>
              <GridItem size="1/2">
                <p style={{ fontSize: "1.8rem", margin: "0" }}>
                  <b>User: {this.state.user.username}</b>
                </p>
                <p style={{ fontSize: "1.8rem", margin: "0" }}>
                  <b>
                    Email:{" "}
                    {this.state.user.email
                      ? this.state.user.email
                      : "Email ID not available"}{" "}
                  </b>
                </p>
                <p style={{ fontSize: "1.8rem", margin: "0" }}></p>
                <p>
                  <b>Last Updated:</b>&nbsp;&nbsp;
                  {this.state.user.updated}
                </p>
              </GridItem>
              <GridItem size="1/2">
                <ButtonGroupPrimary>
                  {this.props.sessionData.permissions.includes(
                    "management_user:write"
                  ) ? (
                    <FilledButton onClick={() => this.toggleSetRoleModal()}>
                      Set Role
                    </FilledButton>
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FilledButton onClick={() => this.toggleResetModal()}>
                    Change e-mail
                  </FilledButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FilledButton
                    onClick={() => this.toggleDeactivateRoleModal()}
                  >
                    Deactivate User
                  </FilledButton>
                </ButtonGroupPrimary>
              </GridItem>
              <GridItem style={{ marginTop: "1rem" }}>
                <div class="ln-c-table-container">
                  <table class="ln-c-table">
                    <caption class="ln-c-table__caption ln-u-visually-hidden">
                      Access Details.
                    </caption>
                    <thead class="ln-c-table__header">
                      <tr class="ln-c-table__row ln-c-table__header-row">
                        <th class="ln-c-table__header-cell customCol">
                          Permissions
                        </th>
                        <th class="ln-c-table__header-cell customHead">
                          Active Status
                        </th>
                      </tr>
                    </thead>
                    <tbody class="ln-c-table__body">
                      {this.state.loadingState === LoadingState.SUCCESS
                        ? this.state.user.permissions
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((permission, i) => {
                              return (
                                <PermissionSummaryRow
                                  key={i}
                                  permission={permission}
                                  switchPermission={this.switchPermission}
                                />
                              );
                            })
                        : null}
                    </tbody>
                  </table>
                </div>
              </GridItem>
            </GridWrapper>
          </Card>
        ) : null}

        <Message
          negative
          hidden={this.state.loadingState !== LoadingState.FAILED}
        >
          <Message.Header>Error loading user</Message.Header>
        </Message>
      </>
    );
  }
}

class PermissionSummaryRow extends React.Component {
  static propTypes = {
    switchPermission: PropTypes.func.isRequired,
    permission: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasPermission: PropTypes.bool.isRequired,
    }),
  };

  render() {
    return (
      <tr class="ln-c-table__row">
        <td class="ln-c-table__cell">{this.props.permission.name}</td>
        <td class="ln-c-table__cell">
          <Checkbox
            checked={this.props.permission.hasPermission}
            onClick={() =>
              this.props.switchPermission(this.props.permission.id)
            }
          />
        </td>
      </tr>
    );
  }
}
