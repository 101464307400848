import React from "react";
import { Route, Switch } from "react-router";
import "react-toastify/dist/ReactToastify.css";

import { userRolesPermissions } from "../hoc/userRolesPermissions";
import Home from "./Home";
import Layout from "./Layout";
import AllocateOfferView from "./allocateoffer/AllocateOfferView";
import BarcodeBlocklistDashboard from "./barcodeblocklist/BarcodeBlocklistDashboard";
import CreateBarcodeBlocklist from "./barcodeblocklist/CreateBarcodeBlocklist";
import EditBarcodeBlocklist from "./barcodeblocklist/EditBarcodeBlocklist";
import BaserateView from "./baserate/BaserateView";
import CampaignDashboard from "./campaign/CampaignDashboard";
import CampaignUploads from "./campaign/CampaignUploads";
import CreateCampaign from "./campaign/CreateCampaign";
import EditCampaign from "./campaign/EditCampaign";
import CampaignsView from "./campaign_legacy/CampaignsView";
import { CustomerDetailsView } from "./customer/CustomerDetailsView";
import CustomersView from "./customer/CustomersView";
import NotFoundView from "./error/NotFoundView";
import GS1SupplierCodeDashboard from "./gs1suppliercodes/GS1SupplierCodeDashboard";
import GS1SupplierCodesEditor from "./gs1suppliercodes/GS1SupplierCodesEditor";
import GS1SupplierCodesUsage from "./gs1suppliercodes/GS1SupplierCodesUsage";
import MarketingEventEditor from "./marketingEvents/MarketingEventEditor";
import MarketingEventIterations from "./marketingEvents/MarketingEventIterations";
import MarketingEventUIContents from "./marketingEvents/MarketingEventUIContents";
import MarketingEventUIOptions from "./marketingEvents/MarketingEventUIOptions";
import MarketingEventUserGroup from "./marketingEvents/MarketingEventUserGroup";
import MarketingEventsDashboard from "./marketingEvents/MarketingEventsDashboard";
import OfferCodeView from "./offercode/OfferCodeView";
import HomepagePodsAssetManagement from "./podmanagement/HomepagePodsAssetManagement";
import HomepagePodsManagementView from "./podmanagement/HomepagePodsManagementView";
import PodEditor from "./podmanagement/PodEditor";
import ProductsView from "./product/ProductsView";
import CreatePromotion from "./promotions/CreatePromotion";
import EditPromotion from "./promotions/EditPromotion";
import PromotionDashboard from "./promotions/PromotionDashboard";
import PromotionUploads from "./promotions/PromotionUploads";
import SkuView from "./sku/SkuView";
import UsersManagementView from "./usermanagement/UserManagementView";
import UsersManagement from "./usermanagement/UsersManagement";

const LayoutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
);

const CmpgnView = ["CampaignEditor", "ReadOnly"];
const MEView = ["MarketingEventEditor", "ReadOnly"];

const Routes = () => (
  <Switch>
    <LayoutRoute exact path="/" component={Home} />
    <LayoutRoute
      path="/customers"
      component={userRolesPermissions(CustomersView, "Customer")}
    />
    <LayoutRoute
      path="/legacy_campaigns"
      component={userRolesPermissions(CampaignsView, CmpgnView)}
    />

    <LayoutRoute
      exact
      path="/campaigns"
      component={userRolesPermissions(CampaignDashboard, CmpgnView)}
    />

    <LayoutRoute
      path="/campaigns/create"
      component={userRolesPermissions(CreateCampaign, "CampaignEditor")}
    />
    <LayoutRoute
      path="/campaigns/edit/:campaignId"
      component={userRolesPermissions(EditCampaign, "CampaignEditor")}
    />
    <LayoutRoute
      path="/campaigns/upload"
      component={userRolesPermissions(CampaignUploads, CmpgnView)}
    />
    <LayoutRoute
      exact
      path="/marketing-events"
      component={userRolesPermissions(MarketingEventsDashboard, MEView)}
    />
    <LayoutRoute
      path="/marketing-events/edit/:id"
      component={userRolesPermissions(
        MarketingEventEditor,
        "MarketingEventEditor"
      )}
    />
    <LayoutRoute
      exact
      path="/marketing-events/user-group"
      component={userRolesPermissions(
        MarketingEventUserGroup,
        "MarketingEventEditor"
      )}
    />
    <LayoutRoute
      exact
      path="/marketing-events/ui-options"
      component={userRolesPermissions(
        MarketingEventUIOptions,
        "MarketingEventEditor"
      )}
    />
    <LayoutRoute
      exact
      path="/marketing-events/ui-contents"
      component={userRolesPermissions(
        MarketingEventUIContents,
        "MarketingEventEditor"
      )}
    />
    <LayoutRoute
      exact
      path="/marketing-events/iterations"
      component={userRolesPermissions(
        MarketingEventIterations,
        "MarketingEventEditor"
      )}
    />
    <LayoutRoute
      path="/customer/:externalHandle"
      component={userRolesPermissions(CustomerDetailsView, "Customer")}
    />
    <LayoutRoute
      path="/management-user/:id"
      component={userRolesPermissions(UsersManagementView, "ManageUser")}
    />
    <LayoutRoute
      path="/management-user"
      component={userRolesPermissions(UsersManagement, "ManageUser")}
    />
    <LayoutRoute
      exact
      path="/homepage-pods"
      component={userRolesPermissions(
        HomepagePodsManagementView,
        "HomepagePodEditor"
      )}
    />
    <LayoutRoute
      path="/homepage-pods/edit/:id"
      component={userRolesPermissions(PodEditor, "HomepagePodEditor")}
    />
    <LayoutRoute
      exact
      path="/homepage-pods/hp-assets"
      component={userRolesPermissions(
        HomepagePodsAssetManagement,
        "HomepagePodEditor"
      )}
    />
    <LayoutRoute
      path="/products"
      component={userRolesPermissions(ProductsView, "ProductsEditor")}
    />
    <LayoutRoute
      exact
      path="/promotions"
      component={userRolesPermissions(PromotionDashboard, "CampaignEditor")}
    />
    <LayoutRoute
      path="/promotions/create"
      component={userRolesPermissions(CreatePromotion, "CampaignEditor")}
    />
    <LayoutRoute
      path="/promotions/edit/:promotionId"
      component={userRolesPermissions(EditPromotion, "CampaignEditor")}
    />
    <LayoutRoute
      path="/promotions/upload"
      component={userRolesPermissions(PromotionUploads, CmpgnView)}
    />
    <LayoutRoute
      exact
      path="/gs1suppliercodes"
      component={userRolesPermissions(GS1SupplierCodeDashboard, CmpgnView)}
    />
    <LayoutRoute
      path="/gs1suppliercodes/create"
      component={userRolesPermissions(GS1SupplierCodesEditor, "CampaignEditor")}
    />
    <LayoutRoute
      path="/gs1suppliercodes/view/:gs1Code"
      component={userRolesPermissions(GS1SupplierCodesUsage, CmpgnView)}
    />
    <LayoutRoute
      exact
      path="/barcodeblocklists"
      component={userRolesPermissions(
        BarcodeBlocklistDashboard,
        "CampaignEditor"
      )}
    />
    <LayoutRoute
      path="/barcodeblocklists/create"
      component={userRolesPermissions(CreateBarcodeBlocklist, "CampaignEditor")}
    />
    <LayoutRoute
      path="/barcodeblocklists/edit/:barcode"
      component={userRolesPermissions(EditBarcodeBlocklist, "CampaignEditor")}
    />
    <LayoutRoute
      path="/baserate"
      component={userRolesPermissions(BaserateView, "BaserateEditor")}
    />
    <LayoutRoute
      path="/allocateoffer"
      component={userRolesPermissions(
        AllocateOfferView,
        "AllocateOfferViewEditor"
      )}
    />
    <LayoutRoute
      path="/offercodes"
      component={userRolesPermissions(OfferCodeView, "OfferCodeEditor")}
    />
    <LayoutRoute
      path="/skus"
      component={userRolesPermissions(SkuView, MEView)}
    />
    <Route path="/404" component={NotFoundView} />
    <Route component={NotFoundView} />
  </Switch>
);

export default Routes;
