import React from "react";
import { Button } from "semantic-ui-react";

import { FilledButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Upload } from "@jsluna/icons";

import LloydsBaserateClient from "../../services/LloydsBaserateClient";

const baserateClient = new LloydsBaserateClient();

class LloydsBaserateView extends React.Component {
  state = { freshness: null };

  handleFileUpload = (e) => {
    baserateClient.uploadFile(this.state.file);
    this.setState({ freshness: null });
    e.preventDefault();
  };

  handleFileSelect = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  componentDidMount() {
    const handle = setInterval(() => {
      baserateClient
        .queryFreshness()
        .then(({ data }) => this.setState({ freshness: data }));
    }, 4000);
    this.setState({ handle: handle });
  }

  componentWillUnmount() {
    if (this.state.handle) clearInterval(this.state.handle);
  }

  render() {
    return (
      <Card>
        <GridWrapper>
          <GridItem>
            <p>
              <b>File Upload:</b>
            </p>
            <Button
              style={{
                border: "2px solid #f06c00",
                borderRadius: "3px",
                fontSize: "1.190rem",
                fontFamily:
                  "MaryAnn, Trebuchet MS, Arial, Helvetica, sans-serif",
                backgroundColor: "transparent",
                color: "#f06c00",
              }}
              secondary
              hidden
              as="label"
              content="File Upload"
              htmlFor="fileUpload"
              icon={
                <>
                  <Upload /> &nbsp;&nbsp;
                </>
              }
            />

            <input
              id="fileUpload"
              type="file"
              hidden
              onChange={this.handleFileSelect}
            />

            <FilledButton onClick={this.handleFileUpload}>Submit</FilledButton>
          </GridItem>
          <GridItem>
            <p>
              <b>Current file updated:</b>{" "}
              {this.state.freshness || "Checking..."}
            </p>
          </GridItem>
        </GridWrapper>
      </Card>
    );
  }
}

export default LloydsBaserateView;
