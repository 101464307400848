import { HttpClient, Method, encodeQuery, managementApi } from "./http";

export class CampaignClient extends HttpClient {
  createCampaign(campaignDetails) {
    return this.request(
      Method.POST,
      managementApi("/campaign"),
      campaignDetails
    );
  }

  createCampaignTag(campaignTag) {
    return this.request(
      Method.POST,
      managementApi("/campaign/tags"),
      campaignTag
    );
  }

  updateCampaign(campaignDetails) {
    return this.request(
      Method.PUT,
      managementApi(`/campaign/${campaignDetails.id}`),
      campaignDetails
    );
  }

  fetchCampaigns(page, size, name, isActive, tags, mode) {
    return this.request(
      Method.GET,
      managementApi(
        `/campaign${encodeQuery({
          page: page,
          size: size,
          name: name,
          active: isActive,
          tags: tags,
          mode: mode,
        })}`
      )
    );
  }

  fetchCampaign(id) {
    return this.request(Method.GET, managementApi(`/campaign/${id}`));
  }

  fetchCampaignTags() {
    return this.request(Method.GET, managementApi(`/campaign/tags`));
  }

  commitCampaign(id) {
    return this.request(Method.POST, managementApi(`/campaign/${id}/commit`));
  }

  deleteCampaign(id) {
    return this.request(Method.DELETE, managementApi(`/campaign/${id}`));
  }

  rejectCampaign(id, feedback) {
    return this.request(
      Method.POST,
      managementApi(`/campaign/${id}/reject`),
      feedback
    );
  }

  fetchCampaignFields(campaignType, accountClientType) {
    if (accountClientType) {
      return this.request(
        Method.GET,
        managementApi(
          `/campaign/fields?campaignType=${campaignType}&accountClientType=${accountClientType}`
        )
      );
    }
    return this.request(
      Method.GET,
      managementApi(`/campaign/fields?campaignType=${campaignType}`)
    );
  }

  getFileUploadUrl(fileName) {
    return this.request(Method.POST, managementApi("/campaign/bulk"), {
      fileName,
    });
  }

  uploadFile(url, file) {
    return this.request(Method.PUT, url, file);
  }

  exportCampaignBarcodeByName(name) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/search/csv?name=${name}&format=BARCODE_EXPORT`)
    );
  }

  exportCampaignBarcodeAndFinanceByName(name) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/search/csv?name=${name}&format=FINANCE_EXPORT`)
    );
  }

  exportCampaignBarcodeByTag(tag) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/search/csv?tags=${tag}&format=BARCODE_EXPORT`)
    );
  }

  exportCampaignBarcodeAndFinanceByTag(tag) {
    return this.request(
      Method.GET,
      managementApi(`/campaign/search/csv?tags=${tag}&format=FINANCE_EXPORT`)
    );
  }
}

export default CampaignClient;
