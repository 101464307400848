import { PropTypes } from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { Form, Message } from "semantic-ui-react";

import { CancelButton, FilledButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Basket } from "@jsluna/icons";

import { CustomerClient } from "../../services/CustomerClient";
import { LoadingState } from "../../services/http.js";

const customerClient = new CustomerClient();
const cardPrefix = "98263000";
let searchDataArr = [];

class CustomersView extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  };
  state = {
    error: undefined,
    search: "",
    emailSearchRequestState: LoadingState.SUCCESS,
    isSearchRequestState: LoadingState.SUCCESS,
    data: {
      search: undefined,
    },
    searchData: [],
  };

  componentDidMount() {
    const sData = localStorage.getItem("searchHistory");
    const parsedSdata = JSON.parse(sData);
    if (parsedSdata) {
      searchDataArr = parsedSdata;
      this.setState({
        searchData: searchDataArr,
      });
    }
  }

  validateSearchFieldForEH = () => {
    let proceedSearch = true;

    if (
      this.state.data.search === undefined ||
      this.state.data.search.length < 3
    ) {
      this.setState({
        error: "Please enter valid external handler",
        idSearchRequestState: LoadingState.FAILED,
      });
      proceedSearch = false;
    } else {
      const regexp = /^(?=.*[a-zA-Z])(?=.*\d)[0-9a-zA-Z-)]+$/; //this will admit only aphanumeric
      const searchKey = this.state.data.search;
      if (!regexp.test(searchKey)) {
        this.setState({
          error: "Please enter alpha numeric value",
          idSearchRequestState: LoadingState.FAILED,
        });
        proceedSearch = false;
      }
    }
    return proceedSearch;
  };

  updateSearchDataArr = (data) => {
    const existingData =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    const isDuplicate = existingData.some(
      (item) => JSON.stringify(item) === JSON.stringify(data)
    );

    if (!isDuplicate) {
      searchDataArr.push(data);
      localStorage.setItem("searchHistory", JSON.stringify(searchDataArr));
    }
  };

  deleteSearchDataArr = (dataToDelete) => {
    const existingData =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    const updatedData = existingData.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(dataToDelete)
    );

    localStorage.setItem("searchHistory", JSON.stringify(updatedData));
    searchDataArr = updatedData;
    this.setState({
      searchData: updatedData,
    });
  };

  handleChange = (e) => {
    this.setState({
      data: Object.assign(this.state.data, { search: e }),
    });
  };

  showSearchDetails = (e) => {
    const regExpEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */

    if (e.length === 11) {
      this.searchHistoryEmailNectarId(e);
    } else if (regExpEmail.test(e)) {
      this.searchHistoryEmail(e);
    } else {
      customerClient
        .getCustomerByEH(e)
        .then(({ data }) => {
          this.setState({ ehSearchRequestState: LoadingState.SUCCESS });
          this.viewCustomer(data.id);
        })
        .catch((e) => {
          this.setState({
            error: e.response.data.description,
            ehSearchRequestState: LoadingState.FAILED,
          });
        });
    }
  };

  searchEmail = () => {
    const regExpEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
    if (
      this.state.data.search === undefined ||
      !regExpEmail.test(this.state.data.search)
    ) {
      this.setState({
        error: "Please enter valid email id",
        idSearchRequestState: LoadingState.FAILED,
      });
      return;
    }

    this.setState({
      error: undefined,
      emailSearchRequestState: LoadingState.PENDING,
    });
    customerClient
      .findByEmail(this.state.data.search)
      .then(({ data }) => {
        this.setState({ emailSearchRequestState: LoadingState.SUCCESS });
        this.viewCustomer(data.id);
        this.updateSearchDataArr(this.state.data.search);
      })
      .catch((e) => {
        this.setState({
          error: e.response.data.description,
          emailSearchRequestState: LoadingState.FAILED,
        });
      });
  };

  searchHistoryEmail = (e) => {
    this.setState({
      error: undefined,
      emailSearchRequestState: LoadingState.PENDING,
    });
    customerClient
      .findByEmail(e)
      .then(({ data }) => {
        this.setState({ emailSearchRequestState: LoadingState.SUCCESS });
        this.viewCustomer(data.id);
      })
      .catch((e) => {
        this.setState({
          error: e.response.data.description,
          emailSearchRequestState: LoadingState.FAILED,
        });
      });
  };

  searchNectarId = () => {
    if (this.state.data.search === undefined) {
      this.setState({
        error: "Please enter valid CollectorId",
        idSearchRequestState: LoadingState.FAILED,
      });
      return;
    }

    if (
      this.state.data.search.length < 11 ||
      this.state.data.search.length > 20
    ) {
      this.setState({
        error: "CollectorId is last 11 digits of full card number.",
        idSearchRequestState: LoadingState.FAILED,
      });
    } else {
      let trimmedDigits = 0;
      if (this.state.data.search.length > 11) {
        trimmedDigits = this.state.data.search.slice(-11);
      } else {
        trimmedDigits = this.state.data.search;
      }

      this.setState({
        error: undefined,
        idSearchRequestState: LoadingState.PENDING,
      });
      customerClient
        .findByNectarId(cardPrefix + trimmedDigits)
        .then(({ data }) => {
          this.setState({ idSearchRequestState: LoadingState.SUCCESS });
          this.viewCustomer(data.id);
          this.updateSearchDataArr(trimmedDigits);
        })
        .catch((e) => {
          this.setState({
            error: e.response.data.description,
            idSearchRequestState: LoadingState.FAILED,
          });
        });
    }
  };

  searchHistoryEmailNectarId = (e) => {
    this.setState({
      error: undefined,
      idSearchRequestState: LoadingState.PENDING,
    });
    customerClient
      .findByNectarId(cardPrefix + e)
      .then(({ data }) => {
        this.setState({ idSearchRequestState: LoadingState.SUCCESS });
        this.viewCustomer(data.id);
      })
      .catch((e) => {
        this.setState({
          error: e.response.data.description,
          idSearchRequestState: LoadingState.FAILED,
        });
      });
  };

  viewCustomer = (id) => {
    this.props.history.push(`/customer/${id}`);
  };

  searchEH = () => {
    if (!this.validateSearchFieldForEH()) {
      return;
    }

    this.setState({
      error: undefined,
      ehSearchRequestState: LoadingState.PENDING,
    });
    customerClient
      .getCustomerByEH(this.state.data.search)
      .then(({ data }) => {
        this.setState({ ehSearchRequestState: LoadingState.SUCCESS });
        this.viewCustomer(data.id);
        this.updateSearchDataArr(this.state.data.search);
      })
      .catch((e) => {
        this.setState({
          error: e.response.data.description,
          ehSearchRequestState: LoadingState.FAILED,
        });
      });
  };

  render() {
    return (
      <>
        <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
          <GridWrapper>
            <GridItem size={{ xs: "1" }}>
              <span>
                <Basket />
                &nbsp;&nbsp;<Label htmlFor="">Customer</Label>
              </span>
            </GridItem>
            <GridItem>
              <Form error={!!this.state.error}>
                {this.state.error && (
                  <Message error content={this.state.error} />
                )}
                <Form.Input
                  style={{
                    margin: "1rem 0 2rem 0",
                    width: "100%",
                    backgroundColor: "transparent",
                    boxShadow: "0",
                    fontFamily: "sans-serif",
                    border: "1px solid #737373",
                    borderRadius: "4.571px",
                    color: "#737373",
                    minHeight: "48px",
                  }}
                  placeholder="Search Customer by email/collector id/external handle"
                  value={this.state.data.search || ""}
                  onChange={(event) => this.handleChange(event.target.value)}
                />
                <FilledButton
                  disabled={
                    this.state.emailSearchRequestState === LoadingState.PENDING
                  }
                  onClick={this.searchEmail}
                >
                  Search Email
                </FilledButton>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <FilledButton
                  disabled={
                    this.state.idSearchRequestState === LoadingState.PENDING
                  }
                  onClick={this.searchNectarId}
                >
                  Search Collector Id
                </FilledButton>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <FilledButton
                  disabled={
                    this.state.ehSearchRequestState === LoadingState.PENDING
                  }
                  onClick={this.searchEH}
                >
                  Search External Handler
                </FilledButton>
              </Form>
            </GridItem>
          </GridWrapper>
        </Card>
        <Card>
          <GridWrapper>
            <GridItem>
              <Label htmlFor="">Customer Search History:</Label>
            </GridItem>
            <GridItem>
              {searchDataArr.length > 0 ? (
                <div class="ln-c-table-container">
                  <table class="ln-c-table">
                    <thead class="ln-c-table__header">
                      <tr class="ln-c-table__row ln-c-table__header-row">
                        <th class="ln-c-table__header-cell customCol">
                          Customer Id
                        </th>
                        <th class="ln-c-table__header-cell customCol">Links</th>
                        <th class="ln-c-table__header-cell customCol">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody class="ln-c-table__body">
                      {searchDataArr.map((sData) => (
                        <tr class="ln-c-table__row">
                          <td class="ln-c-table__cell">{sData}</td>
                          <td class="ln-c-table__cell">
                            <a
                              href
                              style={{ cursor: "Pointer" }}
                              class="ln-c-link ln-c-link--alpha"
                              onClick={() => this.showSearchDetails(sData)}
                            >
                              See Details
                            </a>
                          </td>
                          <td class="ln-c-table__cell">
                            <CancelButton
                              onClick={() => this.deleteSearchDataArr(sData)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Label htmlFor="">
                  No Customer Search History Available in the current session
                </Label>
              )}
            </GridItem>
          </GridWrapper>
        </Card>
      </>
    );
  }
}

export default withRouter(CustomersView);
