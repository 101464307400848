import React from "react";
import { Helmet } from "react-helmet";
import { Image } from "semantic-ui-react";

import Decembertracker from "../../assets/Decembertracker.png";
import Header from "../../assets/Header.png";
import TrackerTarget4 from "../../assets/TrackerTarget4.png";
import cfcheader from "../../assets/cfc-header.png";
import completeiconTarget1 from "../../assets/completeiconTarget1.png";
import completeiconTarget2 from "../../assets/completeiconTarget2.png";
import novembertracker from "../../assets/novembertracker.png";
import progressdigits from "../../assets/progressdigits.png";
import pumpkin from "../../assets/pumpkin.png";
import "./campaignstyles.scss";

const constthemes = new Map([
  ["COREPURPLE", "corepurpletheme"],
  ["GREEN", "greentheme"],
  ["YELLOW", "yellowtheme"],
  ["RED", "redtheme"],
  ["DARKPUTTY", "corepurpletheme"],
]);

export default class MobilePreviewCFC extends React.Component {
  state = {
    themes: constthemes,
  };

  escapedNewLineToLineBreakTag = (string) => {
    if (string !== undefined && string !== null) {
      return string.split("\\n").map((item, index) => {
        return index === 0 ? item : [<br key={index} />, item];
      });
    }
    return "";
  };

  dummyOnClick = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="smartphone floatalways">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div
          className={`content ${this.state.themes.get(
            this.props.campaign.trackerTheme
          )}`}
          id="content"
        >
          <div className="scrollablepage" id="mainpage">
            <Image src={Header} className="headerImage " />
            <Image src={cfcheader} className="cfcHomeheaderImage " />
            <div className="campaignhomeheader">You're on your way!</div>
            <div className="campaignhometitle">
              Collect 500 points we'll give you another +250 points
            </div>
            <div className="cfcTrackerTileTraget1">
              <div className="cfcProgressTitleTarget1">500/500</div>
              <div className="cfcProgressCircleTarget1 campaignlocked ">
                <img
                  className="cfcCompleteIcon"
                  src={completeiconTarget1}
                  alt=""
                />
                <div className="collectedpointsTarget1">+100</div>
              </div>
            </div>

            <div className="cfcTrackerTileTragets ">
              <div className="cfcProgressTitleTargets">1000/1000</div>
              <div className="cfcProgressCircleTargets campaignlocked">
                <img
                  className="cfcCompleteIcon"
                  src={completeiconTarget2}
                  alt=""
                />
                <div className="collectedPointsTarget">+150</div>
              </div>
            </div>

            <div className="cfcTrackerTileTragets ">
              <div className="cfcProgressTitleTargets">1500/1500</div>
              <div className="cfcProgressCircleTargets campaignlocked">
                <img
                  className="cfcCompleteIcon"
                  src={completeiconTarget2}
                  alt=""
                />
                <div className="collectedPointsTarget">+200</div>
              </div>
            </div>

            <div className="cfcTrackerTileTragets">
              <div className="cfcProgressTitleTargets">Target 4</div>
              <div className="cfcProgressCircleTargets ">
                <img className="cfcCompleteIcon" src={TrackerTarget4} alt="" />
                <img className="progressTarget4" src={progressdigits} alt="" />
              </div>
            </div>

            <div className="campaignhomesubtitle">
              <div className="campaignhomeheader">Boost your points</div>
              <div className="campaignhometitle">
                Take part in our monthly boost to collect more points
              </div>
            </div>

            <div className="cfcOctoberBardge">
              <div className="octoberText1">
                October boost
                <div className="octoberText3">5/5</div>
              </div>
              <div className="octoberText2">Ends 31 October</div>
              <div className="octoberProgressBase">
                <img
                  className="cfcCompleteIcon"
                  src={completeiconTarget1}
                  alt=""
                />
                <img className="cfcPumkinImage" src={pumpkin} alt="" />
              </div>
            </div>

            <div className="cfcrbardge">
              <div className="novemberText1">
                November boost
                <div className="novemberText3">5/5</div>
              </div>
              <div className="novemberText2">Ends 30 November</div>
              <img className="cfcTrackertImage" src={novembertracker} alt="" />
            </div>

            <div className="cfcrbardge">
              <div className="decemberText1">
                December boost
                <div className="decemberText3">0/5</div>
              </div>
              <div className="decemberText2">Ends 31 December</div>
              <img className="cfcTrackertImage" src={Decembertracker} alt="" />
            </div>

            <a href onClick={this.dummyOnClick}>
              <div className="roundbutton campaignhomeheader">How it works</div>
            </a>
            <a href onClick={this.dummyOnClick}>
              <div className="campaignhomeheader">Terms and Condition</div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
