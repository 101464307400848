import React from "react";
import { Form, TextArea } from "semantic-ui-react";

import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

export default class ProductCategorySelection extends React.Component {
  render() {
    if (this.props.campaign.trackerType === "BADGE") {
      return (
        <GridWrapper>
          <GridItem style={{ margin: "1rem 0 0 0" }}>
            <Label>Product Category</Label>
            <Form.Select
              style={{
                height: "48px",
                border: "1px solid rgba(34,36,38,.15)",
                borderRadius: "4px",
                boxShadow: "0 0 0 0 transparent inset",
                padding: "1em",
                color: "rgba(0,0,0,.87)",
              }}
              name="productCategory"
              options={this.props.campaign.productCategories}
              placeholder="Product category - Changing this value also updates the campaign SKUs"
              value={this.props.campaign.productCategory}
              onChange={this.props.handleChange}
            />
          </GridItem>
        </GridWrapper>
      );
    } else if (this.props.campaign.trackerType === "COUNTER") {
      return (
        <div>
          <GridWrapper>
            <GridItem>
              <Label>Product Category</Label>
              <br />
              <Form.Select
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "16px 8px 16px",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "2px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                name="productCategory"
                options={[
                  { text: "Fruit", value: "TOTALFRUIT" },
                  { text: "Vegetable", value: "TOTALVEG" },
                ]}
                placeholder="Please select Product Category for this campaign"
                value={this.props.campaign.productCategory}
                onChange={this.props.handleChange}
                required={this.props.campaign.trackerType === "COUNTER"}
              />
              {this.props.campaign.skus && (
                <div>
                  <h5>Skus</h5> {this.props.campaign.skus}
                </div>
              )}
            </GridItem>
          </GridWrapper>
        </div>
      );
    } else {
      return (
        <div>
          <GridWrapper>
            <GridItem style={{ margin: "1rem 0 0 0" }}>
              <Label>Target number</Label>
              <br />
              <Form.Select
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  padding: "16px 8px 16px",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "2px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                name="productCategory"
                options={[
                  { text: "Target 1", value: "TARGET1" },
                  { text: "Target 2", value: "TARGET2" },
                  { text: "Target 3", value: "TARGET3" },
                  { text: "Target 4", value: "TARGET4" },
                ]}
                placeholder="Please select target"
                value={this.props.campaign.productCategory}
                onChange={this.props.handleChange}
                required={this.props.campaign.trackerType === "TARGET"}
              />
            </GridItem>
          </GridWrapper>
          <GridWrapper>
            <GridItem style={{ margin: "1rem 0 0 0" }}>
              <Label>SKUs</Label>
              <TextArea
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                name="skus"
                placeholder="eg. fruit,vegetable"
                onChange={this.props.handleSkuChange}
              ></TextArea>
            </GridItem>
          </GridWrapper>
        </div>
      );
    }
  }
}
