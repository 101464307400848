import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Alert, AlertIcon } from "@jsluna/alert";
import { FilledButton, OutlinedButton } from "@jsluna/button";
import { GridItem } from "@jsluna/grid";
import { ErrorCircle, Measurement } from "@jsluna/icons";

import {
  SingleSelectField,
  TextField,
} from "../../common/components/FormFields";
import { InlineGroup } from "../../common/components/InlineGroup";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import Loader from "../../common/helpers/Loader/Loader";
import { SuccessToastNotification } from "../../common/helpers/Notifications/SuccessToastNotification";
import { useForm } from "../../common/hooks/useForm";
import { GS1SupplierCodesClient } from "../../services/GS1SupplierCodesClient";

const apiClient = new GS1SupplierCodesClient();

const GS1SupplierCodesEditor = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [apiErrorText, setApiErrorText] = useState(null);
  const { useField, anyValidationErrors, onSubmit, shouldBlockSubmit } =
    useForm();

  const gs1 = useField({
    initialValue: "",
    validations: [
      [(v) => v.length === 4 && /^\d+$/.test(v), "Please enter 4 digit"],
    ],
  });

  const description = useField({
    initialValue: "",
    validations: [
      [(v) => v.length <= 70, "This field cannot exceed 70 characters"],
    ],
  });

  const category = useField({
    initialValue: "",
    options: [
      { label: "OCC", value: "OCC" },
      { label: "Digital Nectar", value: "DIGITAL_NECTAR" },
    ],
  });

  const onSaveSubmit = onSubmit(async (e) => {
    e.preventDefault();
    setApiErrorText(null);
    setLoading(true);
    const details = {
      gs1: gs1.value,
      description: description.value,
      category: category.value,
      status: "ACTIVE",
    };

    try {
      const response = await apiClient.addCode(details);
      if (response.status !== 200) {
        throw new Error(response);
      }
      SuccessToastNotification(`Added GS1 Supplier code ${details.gs1}`);
      setLoading(false);
      history.push("/gs1SupplierCodes");
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("An error occurred when saving the GS1 code. " + message);
      setLoading(false);
    }
  });

  const validationErrors = anyValidationErrors();

  if (loading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <GridItem size={{ md: "1/2" }}>
        <PageHeader>
          <Measurement />
          Add new GS1 supplier codes
        </PageHeader>
      </GridItem>
      <GridItem size={{ md: "1/2" }}>
        <InlineGroup alignEnd>
          <OutlinedButton to="/gs1suppliercodes" element={Link}>
            Close
          </OutlinedButton>
          <FilledButton
            onClick={onSaveSubmit}
            disabled={validationErrors && shouldBlockSubmit}
          >
            Save
          </FilledButton>
        </InlineGroup>
      </GridItem>
      {validationErrors && shouldBlockSubmit && (
        <GridItem size="1/1">
          <Alert variant="error">
            <AlertIcon>
              <ErrorCircle aria-label="Error" role="img" />
            </AlertIcon>
            Please address validation errors before continuing
          </Alert>
        </GridItem>
      )}
      {apiErrorText && (
        <GridItem size="1/1">
          <Alert variant="error">
            <AlertIcon>
              <ErrorCircle aria-label="Error" role="img" />
            </AlertIcon>
            {apiErrorText}
          </Alert>
        </GridItem>
      )}
      <GridItem size="1/1">
        <TextField
          name="gs1"
          label="GS1 Supplier code number"
          placeholder="Enter GS1 Supplier code number"
          {...gs1.props}
        />
        <TextField
          name="description"
          label="GS1 Supplier description"
          placeholder="Enter GS1 Supplier description"
          {...description.props}
        />
        <SingleSelectField
          name="category"
          label="GS1 Category"
          {...category.props}
        />
      </GridItem>
    </PageContainer>
  );
};

export default GS1SupplierCodesEditor;
